import React, { useState, useEffect } from 'react';
import styles from './ProfileDropdown.module.css';
import { useNavigate } from 'react-router-dom';
import { useUser } from '../../contexts/UserContext';
import LogoutComponent from '../authentication/LogoutComponent';
import MushroomBronzeIcon from '../common/MushroomBronzeIcon';
import MushroomSilverIcon from '../common/MushroomSilverIcon';
import MushroomGoldIcon from '../common/MushroomGoldIcon';

function ProfileDropdown() {
  const [showDropdown, setShowDropdown] = useState(false);
  const navigate = useNavigate();
  const user = useUser();

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (!event.target.closest(`.${styles.profileMenu}`)) {
        setShowDropdown(false);
      }
    };
  
    if (showDropdown) {
      document.addEventListener('click', handleOutsideClick);
    }
  
    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, [showDropdown]);

  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  }

  const goTo = (path) => {
    navigate(path);
    setShowDropdown(false);
  };

  if (!user) {
    return null;
  }

  return (
    <div className={styles.profileMenu}>
      <button className={styles.profileButton} onClick={toggleDropdown}>
        My Account
      </button>
      <div className={`${styles.dropdownContent} ${showDropdown && styles.show}`}>
        <div className={styles.welcomeMessage}>
          Hi {user.user.name},
          <div className={styles.mushroom}>
            Mushrooms:
          </div>
          <div className={styles.mushroomIcons}>
            <span><MushroomBronzeIcon variant="extra small" /></span> <span className={styles.mushroomCount}>{user.user.bronze_mushrooms}</span>
            <span><MushroomSilverIcon variant="extra small" /></span> <span className={styles.mushroomCount}>{user.user.silver_mushrooms}</span>
            <span><MushroomGoldIcon variant="extra small" /></span> <span className={styles.mushroomCount}>{user.user.gold_mushrooms}</span>
          </div>

        </div>
        <button className={styles.styledButton} onClick={() => goTo('/collectibles')}>
          My Collectibles
        </button>
        <button className={styles.styledButton} onClick={() => goTo('/dashboard')}>
          Dashboard
        </button>
        <button className={styles.styledButton} onClick={() => goTo('/mushroom-stats')}>
          Game Stats
        </button>
        <button className={styles.styledButton} onClick={() => goTo('/leader-board')}>
          Leaderboard
        </button>
        <button className={styles.styledButton} onClick={() => goTo('/view-profile')}>
          View Profile
        </button>

        {user && user.user.is_admin && <button className={styles.styledButton} onClick={() => goTo('/admin/list-blogs')}> Blog Admin </button>}
        {user && user.user.is_admin && <button className={styles.styledButton} onClick={() => goTo('/admin/list-comments')}> Comments Admin </button>}
        {user && user.user.is_admin && <button className={styles.styledButton} onClick={() => goTo('/admin/list-orders')}> Orders Admin </button>}
        {user && user.user.is_admin && <button className={styles.styledButton} onClick={() => goTo('/admin/list-users')}> Users Admin </button>}
        {user && user.user.is_admin && <button className={styles.styledButton} onClick={() => goTo('/admin/tools')}> Admin Tools </button>}
        {user && user.user.is_admin && <button className={styles.styledButton} onClick={() => goTo('/admin/test')}> Test Page </button>}

        <LogoutComponent />

      </div>
    </div>
  );
}

export default ProfileDropdown;
