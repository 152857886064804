import React, { useState } from 'react';
import styles from './ChatBot.module.css';
import ChatBotContent from './ChatBotContent';

export default function Chatbot() {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className={styles.container}>


      <div className={`${styles.chatBotWindow} ${isOpen ? styles.open : ''}`}>
        <ChatBotContent />
      </div>
      <button
        className={styles.chatbotLink}
        onClick={() => setIsOpen(!isOpen)}
      >
        Start a Chat
      </button>
    </div>
  );
}
