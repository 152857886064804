import React, { useContext } from 'react';
import axios from 'axios';
import { QuestionsContext } from '../../contexts/QuestionsContext';
import { UserContext } from '../../contexts/UserContext';
import styles from './Questions.module.css';
import { useSnackbar } from 'notistack';
import { sendAuditLog } from '../../helpers/HelperFunctions';
import { ACTION_TYPE_CLICK_BUTTON, ENTITY_TYPE_JOURNEY, ENTITY_ID_GET_STARTED } from '../../helpers/Constants';

function Questions() {
    const { questionsFetched, setQuestionsData, setQuestionsFetched } = useContext(QuestionsContext);
    const { user } = useContext(UserContext);
    const { enqueueSnackbar } = useSnackbar();
    const BACKEND_URL = process.env.REACT_APP_BACKEND_URL || 'http://localhost:8080';

    const fetchQuestions = () => {
        const userID = user ? user.id : null;
        sendAuditLog(userID, ACTION_TYPE_CLICK_BUTTON, ENTITY_TYPE_JOURNEY, ENTITY_ID_GET_STARTED, null);

        axios.get(`${BACKEND_URL}/report`, { withCredentials: true })
            .then(response => {
                enqueueSnackbar('Questions Loaded!', { variant: 'success' });
                setQuestionsData(response.data);
                setQuestionsFetched(true);  // Mark as fetched in the global context
            })
            .catch(error => {
                console.error('Error fetching questions:', error);
                enqueueSnackbar(`Error fetching questions: ${error}`, { variant: 'error' });
                setQuestionsFetched(false);
            });
    };

    return (
        <div className={styles.questionsContainer}>
            <button className={styles.fetchButton} disabled={!user || questionsFetched} onClick={fetchQuestions}>Get Started</button>
        </div>

    );
};

export default Questions;

