import React from 'react';
import styles from './Testimonial.module.css';

const Testimonial = ({ data }) => {
    return (
        <div className={styles.testimonialContainer}>
            {data.image && <img className={styles.userImage} src={data.image} alt={`${data.name}'s profile`} />}
            <h3 className={styles.userName}>{data.name}</h3>
            <p className={styles.userTestimony}>{data.testimony}</p>
        </div>
    );
};

export default Testimonial;

