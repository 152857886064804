import React, { useState, useEffect } from 'react';
import styles from './LifestyleTips.module.css';
import { useUser } from '../contexts/UserContext';
import axios from 'axios';
import { useSnackbar } from 'notistack';
import { GAME_BRONZE_MUSHROOM_REWARD } from '../helpers/Constants';
import { devLog, sendAuditLog } from '../helpers/HelperFunctions'
import { ACTION_TYPE_CLICK_BUTTON, ENTITY_ID_LIFESTYLE_TIPS_RESPONSE, ENTITY_ID_LIFESTYLE_TIPS_ERROR, ENTITY_TYPE_LIFESTYLE_TIPS } from '../helpers/Constants';

const mockTips = [
  'Did you know that simply taking the stairs instead of using a lift will increase your fitness?',
  'Eating an apple before a meal can reduce your overall calorie intake by 15%.',
  'Having a glass of water 30 minutes before a meal helps you consume fewer calories.',
  'A 20-minute nap can increase your productivity significantly.',
  'Listening to music while exercising can improve workout performance.',
  'Drinking green tea can help you burn more calories.',
  'A quick walk around the block can boost your mood and energy levels.',
  'Opting for whole grains over refined grains can lower the risk of heart disease.',
  'Mindfulness meditation can reduce stress and improve mental clarity.',
  'Chewing your food more slowly can improve digestion.',
  'Eating more fiber can support weight loss.',
  'Taking time off screens before bed can improve sleep quality.',
  'Plant-based diets are linked to lower risk of heart disease.',
  'Using smaller plates can help control portion sizes.',
  'Spending time in nature can reduce stress and boost well-being.',
  'Taking short breaks during work can improve focus.',
  'Cutting back on sugar can help prevent chronic diseases.',
  'Laughing can actually improve your health.',
  'Consistent bedtime and wake-time can improve your sleep and overall health.',
  'Regular eye exercises can improve vision.',
  'Dancing is a great way to stay fit and happy.',
  'Opting for unsaturated fats can improve heart health.',
  'Learning a new skill can boost your mental health.',
  'Regular stretching can improve flexibility and reduce muscle pain.',
  'Avoiding processed foods can lead to better health.',
  'Playing puzzle games can help keep your mind sharp.',
  'Drinking more water can improve skin complexion.',
  'A small amount of dark chocolate can be good for your heart.',
  'High-intensity workouts can boost metabolism for several hours after exercise.',
  'Eating nuts can improve brain function.',
  'Pet ownership is linked to lower stress levels.',
  'Using a standing desk can burn more calories.',
  'Spending less time sitting can add years to your life.',
  'Drinking milk can strengthen your bones.',
  'Regular dental check-ups prevent gum disease and improve overall health.',
  'Having plants in your home can improve air quality.',
  'Using sunscreen daily can protect your skin from harmful UV rays.',
  'Olive oil is a healthy alternative to vegetable oil.',
  'Adding spices to your food can accelerate your metabolism.',
  'Keeping a gratitude journal can improve your outlook on life.',
  'Reading books regularly can improve empathy and understanding.',
  'Eating smaller, more frequent meals can help maintain energy levels.',
  'Switching from coffee to tea can reduce anxiety.',
  'Replacing soda with fruit-infused water is a healthier alternative.',
  'Using blue light filters on digital devices can protect your eyes.',
  'Making your own meals can lead to better dietary choices.',
  'Regular exercise can help manage symptoms of depression.'
];


function LifestyleTips() {
  const [tip, setTip] = useState('');
  const [token, setToken] = useState('');
  const { user } = useUser();
  const [userAnswer, setUserAnswer] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const BACKEND_URL = process.env.REACT_APP_BACKEND_URL || 'http://localhost:8080';

  useEffect(() => {
    if (user && user.id) {
      loadNextTip();
    }
  }, []);

  const loadNextTip = () => {
    axios.post(`${BACKEND_URL}/token`, user, { withCredentials: true })
      .then(response => {
        devLog('Lifestyle Tips - Server Response: ', response.data);
        setToken(response.data);
      })
      .catch(error => {
        if (error.response) {
          enqueueSnackbar(`Error: ${error.response.data.error || error.response.status}`, { variant: 'error' });
        } else if (error.request) {
          enqueueSnackbar('Error: No response from server.', { variant: 'error' });
        } else {
          enqueueSnackbar(`Error: ${error.message}`, { variant: 'error' });
        }
      });
    setUserAnswer(false);
    const randomTip = mockTips[Math.floor(Math.random() * mockTips.length)];
    setTip(randomTip);
  };

  const handleResponse = async (response) => {
    setUserAnswer(true);

    const collectible = {
      user_id: user.id,
      bronze_mushrooms: GAME_BRONZE_MUSHROOM_REWARD,
      silver_mushrooms: '',
      gold_mushrooms: '',
      token_id: token.token_id
    }

    if (user && user.id) {
      devLog('Collectible: ', collectible);

      await axios.put(`${BACKEND_URL}/collectible`, collectible, { withCredentials: true })
        .then(response => {
          devLog('Server Response: ', response.data)
          user.bronze_mushrooms += response.data.bronze_mushrooms;
          user.silver_mushrooms += response.data.silver_mushrooms;
          user.gold_mushrooms += response.data.gold_mushrooms;
          sendAuditLog(user.id, ACTION_TYPE_CLICK_BUTTON, ENTITY_TYPE_LIFESTYLE_TIPS, ENTITY_ID_LIFESTYLE_TIPS_RESPONSE, null);
          enqueueSnackbar('You earned 1 bronze mushroom!', { variant: 'success' });
        })
        .catch(error => {
          console.error('Error updating collectibles:', error.response.data);
          sendAuditLog(user.id, ACTION_TYPE_CLICK_BUTTON, ENTITY_TYPE_LIFESTYLE_TIPS, ENTITY_ID_LIFESTYLE_TIPS_ERROR, null);
          enqueueSnackbar(`Error: ${error.response.data.error}`, { variant: 'error' });
        });

    }

    setTimeout(() => {
      loadNextTip();
    }, 5000);
  };

  return (
    <div className={styles.container}>
      <h3 className={styles.tipHeader}>Sarah&apos;s Lifestyle Tips</h3>
      <p className={styles.description}>Did you know:</p>
      <p className={styles.tipText}>{tip}</p>

      {userAnswer ? (
        <>
          <p className={styles.answer}>Thanks for your answer</p>
        </>
      ) : (
        <>
          <button className={styles.tipButton} onClick={() => handleResponse('Yes')}>Yes</button>
          <button className={styles.tipButton} onClick={() => handleResponse('No')}>No</button>
        </>
      )}
    </div>
  );
}

export default LifestyleTips;
