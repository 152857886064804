import React, { useEffect, useState, useContext } from 'react';
import axios from 'axios';
import { UserContext } from '../contexts/UserContext';
import styles from './Collectibles.module.css';
import { devLog, sendAuditLog } from '../helpers/HelperFunctions';
import { useSnackbar } from 'notistack';
import { ACTION_TYPE_CLICK_BUTTON, ENTITY_TYPE_COLLECTIBLES, ENTITY_ID_GET_DAILY_COLLECTIBLES, DAILY_BRONZE_MUSHROOM_REWARD } from '../helpers/Constants';
import MushroomBronzeIcon from './common/MushroomBronzeIcon';

function canCollect(lastCollectibleDateString) {
    const lastCollectDate = new Date(lastCollectibleDateString);
    const today = new Date();

    return (
        today.getFullYear() !== lastCollectDate.getFullYear() ||
        today.getMonth() !== lastCollectDate.getMonth() ||
        today.getDate() !== lastCollectDate.getDate()
    );
}


function Collectibles() {
    const [timeUntilMidnight, setTimeUntilMidnight] = useState('');
    const [eligibleToCollect, setEligibleToCollect] = useState(false);
    const { user } = useContext(UserContext);
    const [token, setToken] = useState('');
    const [showModal, setShowModal] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const BACKEND_URL = process.env.REACT_APP_BACKEND_URL || 'http://localhost:8080';

    useEffect(() => {
        const addLeadingZeros = (num) => (num < 10 ? `0${num}` : num);

        const updateTime = () => {
            const now = new Date();
            const midnight = new Date();
            midnight.setHours(24, 0, 0, 0);
            const diff = midnight - now;

            const hours = addLeadingZeros(Math.floor(diff / 1000 / 60 / 60));
            const minutes = addLeadingZeros(Math.floor((diff / 1000 / 60) % 60));
            const seconds = addLeadingZeros(Math.floor((diff / 1000) % 60));

            setTimeUntilMidnight(`${hours}:${minutes}:${seconds}`);
        };

        updateTime(); // Call once immediately to avoid delay
        const intervalId = setInterval(updateTime, 1000);
        return () => clearInterval(intervalId);
    }, []);


    useEffect(() => {
        if (user && canCollect(user.last_collectible_date)) {
            setEligibleToCollect(true);

            axios.post(`${BACKEND_URL}/token`, user, { withCredentials: true })
                .then(response => {
                    devLog('Collectibles - Server Response: ', response.data);
                    setToken(response.data);
                })
                .catch(error => {
                    if (error.response) {
                        enqueueSnackbar(`Error: ${error.response.data.error || error.response.status}`, { variant: 'error' });
                    } else if (error.request) {
                        enqueueSnackbar('Error: No response from server.', { variant: 'error' });
                    } else {
                        enqueueSnackbar(`Error: ${error.message}`, { variant: 'error' });
                    }
                });
        }
    }, [user]);


    const getCollectibles = async () => {
        const userID = user ? user.id : null;

        const collectible = {
            user_id: userID,
            bronze_mushrooms: DAILY_BRONZE_MUSHROOM_REWARD,
            silver_mushrooms: '',
            gold_mushrooms: '',
            token_id: token.token_id
        }

        if (user && userID) {

            sendAuditLog(userID, ACTION_TYPE_CLICK_BUTTON, ENTITY_TYPE_COLLECTIBLES, ENTITY_ID_GET_DAILY_COLLECTIBLES, null);
            setShowModal(true);

            await axios.put(`${BACKEND_URL}/collectible`, collectible, { withCredentials: true })
                .then(response => {
                    devLog('getCollectibles - Server Response: ', response.data);
                    user.bronze_mushrooms += response.data.bronze_mushrooms;
                    user.silver_mushrooms += response.data.silver_mushrooms;
                    user.gold_mushrooms += response.data.gold_mushrooms;
                    user.last_collectible_date = response.data.last_collectible_date;
                })
                .catch(error => {
                    console.error('Error updating collectibles:', error);
                });

        }
    };

    const handleSubmit = async () => {
        setEligibleToCollect(false);
        setShowModal(false);
    };

    const handleSubmit2 = async () => {
        setShowModal(false);
    };

    return (
        <>
            {!showModal && !eligibleToCollect && <div className={styles.tooltipContainer}>
                <button className={styles.collectiblesButton} disabled={!user || !eligibleToCollect} onClick={getCollectibles}>{timeUntilMidnight} to collect more mushrooms</button>
                <span className={styles.tooltip}>Come back for more tomorrow</span>
            </div>}
            {!showModal && eligibleToCollect && <div>
                <button className={styles.collectiblesButton} disabled={!user || !eligibleToCollect} onClick={getCollectibles}>Get Daily Collectibles</button>
            </div>}
            {showModal && <main className={styles.overlay}>
                {eligibleToCollect && <div className={styles.modalContainer}>
                    <h2 className={styles.paragraph}>Here&apos;s Your Reward!</h2>
                    <h3 className={styles.paragraph}>
                        <MushroomBronzeIcon variant="small" /> +10 Bronze Mushrooms
                    </h3>
                    <p className={styles.paragraph}>Don&apos;t forget to check in tomorrow to collect some more</p>
                    <div>
                        <button className={styles.button} onClick={handleSubmit}>
                            Ok
                        </button>
                    </div>
                </div>}

                {!eligibleToCollect && <div className={styles.modalContainer}>
                    <p className={styles.paragraph}>Check Back Tomorrow</p>
                    <div>
                        <button className={styles.button} onClick={handleSubmit2}>
                            Ok
                        </button>
                    </div>
                </div>}

            </main>}

        </>
    );
};

export default Collectibles;
