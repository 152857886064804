import React, { createContext, useContext, useState } from 'react';
import { useSnackbar } from 'notistack';
import { devLog } from '../helpers/HelperFunctions';
import axios from 'axios';

const BasketContext = createContext();

export const useBasket = () => {
  return useContext(BasketContext);
};

export const BasketProvider = ({ children }) => {
  const [basketItems, setBasketItems] = useState([]);
  const [order, setOrder] = useState(null);
  const { enqueueSnackbar } = useSnackbar();
  const BACKEND_URL = process.env.REACT_APP_BACKEND_URL || 'http://localhost:8080';

  /**
   * Creates a unique ID for each product based on its id and its attributes such as colour and size.
   * 
   * e.g. 123456-blue-m
   * 
   * @param {object} product 
   * @returns unique id for this product.
   */
  const createBasketKey = (product) => {
    devLog('Product: ', product);
    let key = product.id;

    if (product.selectedColour) {
      key += `-${product.selectedColour.toLowerCase()}`;
    }

    if (product.selectedSize) {
      key += `-${product.selectedSize.toLowerCase()}`;
    }
    devLog('Product Key: ', key)
    return key;
  }

  const addToBasket = (product, quantity) => {
    devLog('addToBasket: ', product);

    const productWithKey = {
      ...product,
      basketKey: createBasketKey(product)
    };

    const existingProductIndex = basketItems.findIndex(
      item => item.product.basketKey === productWithKey.basketKey
    );

    if (existingProductIndex !== -1) {
      // Product exists in basket, update quantity
      const updatedBasketItems = [...basketItems];
      updatedBasketItems[existingProductIndex].quantity += quantity;
      setBasketItems(updatedBasketItems);
    } else {
      // Product is new to the basket
      setBasketItems([...basketItems, { product: productWithKey, quantity }]);
    }

    enqueueSnackbar('Item added to basket.', { variant: 'success' });
  };

  const removeFromBasket = (product) => {
    devLog('removeFromBasket: ', product);
    devLog('basketItems: ', basketItems);
    const keyToRemove = createBasketKey(product);
    const newBasketItems = basketItems.filter(item => item.product.basketKey !== keyToRemove);
    setBasketItems(newBasketItems);
    enqueueSnackbar('Item removed from basket.', { variant: 'success' });
  };

  const clearBasket = () => {
    setBasketItems([]);
  };

  /**
   * Returns the total of the basket but doesn't include shipping, promo codes, discounts etc.
   * 
   * @returns the raw total of items.
   */
  const basketTotal = () => {
    return basketItems.reduce((acc, item) => acc + (item.product.price * item.quantity), 0).toFixed(2);
  };

  /**
   * Updates the quantity of a product.
   * 
   * @param {product} product 
   * @param {number} newQuantity 
   */
  const updateQuantity = (product, newQuantity) => {
    const updatedBasket = basketItems.map(item =>
      item.product.id === product.id ? { ...item, quantity: newQuantity } : item
    );
    setBasketItems(updatedBasket);
  };

  /**
   * Searchs for a product in the basket.
   * 
   * @param {number} productId 
   * @returns the product
   */
  const findItemInBasket = (productId) => {
    return basketItems.find(item => item.product.id === productId);
  };

  /**
   * Returns the number of items in the basket.
   * 
   * @returns the number of items in the basket.
   */
  const itemCount = () => {
    return basketItems.reduce((acc, item) => acc + item.quantity, 0);
  };

  /**
   * Returns true if the basket is empty.
   * 
   * @returns true if the basket is empty.
   */
  const isBasketEmpty = () => {
    return basketItems.length === 0;
  };

  /**
   * Applies a promo code to the basket.
   * 
   * @param {string} code 
   */
  const applyPromoCode = (code) => {
    // TODO
  };

  /**
   * Calculates the shipping cost on the basket. 
   */
  const calculateShipping = () => {
    // TODO
  };

  /**
   * Calculates the amount of tax on the basket.
   */
  const calculateTax = () => {
    // TODO
  };

  /**
   * Persists the basket to local storage.
   */
  const persistBasket = () => {
    // TODO Persists the basket to local storage.
  }

  /**
   * Updates the server to the backend.
   */
  const placeOrder = async (userInfo, userId) => {
    devLog('Basket Items: ', basketItems);
    devLog('User Info: ', userInfo)

    var newOrder = convertToFrontendToBackendOrder(userInfo, basketItems, userId);
    devLog('Sending Order: ', newOrder);

    try {
      const response = await axios.post(`${BACKEND_URL}/orders`, newOrder, { withCredentials: true });
      devLog('Retrieved from Server: ', response.data);
      setOrder(response.data);
      enqueueSnackbar('Order Placed! Proceed to Payment', { variant: 'success' });
      return response.data.id;
    } catch (error) {
      if (error.response) {
        enqueueSnackbar(`Error: ${error.response.data.error || error.response.status}`, { variant: 'error' });
      } else if (error.request) {
        enqueueSnackbar('Error: No response from server.', { variant: 'error' });
      } else {
        enqueueSnackbar(`Error: ${error.message}`, { variant: 'error' });
      }
      return null;
    }
  };


  function convertToFrontendToBackendOrder(userInfo, frontendOrderArray, userID) {
    const items = frontendOrderArray.map(frontendOrder => {
      const pricePerUnitStr = parseFloat(frontendOrder.product.price).toFixed(2).toString();
      const totalPriceStr = (parseFloat(frontendOrder.product.price) * frontendOrder.quantity).toFixed(2).toString();

      return {
        id: frontendOrder.product.id,
        product_id: frontendOrder.product.id,
        quantity: frontendOrder.quantity,
        price_per_unit: pricePerUnitStr,
        total_price: totalPriceStr,
      };
    });

    const totalOrderPriceStr = items.reduce((total, item) => total + parseFloat(item.total_price), 0).toFixed(2).toString();

    const backendOrder = {
      user_id: userID,
      shop_id: frontendOrderArray[0].product.shop_id,
      status: 'pending',
      total_price: totalOrderPriceStr,
      items: items,
      shipping_detail: {
        recipient_title: userInfo.title,
        recipient_name: userInfo.name,
        recipient_email: userInfo.email,
        phone_number: userInfo.phone_number,
        house_number: userInfo.house_number,
        street: userInfo.street,
        city: userInfo.city_town,
        postcode: userInfo.postcode,
        county: userInfo.county,
        country: userInfo.country
      }
    };
    return backendOrder;
  }

  const updateOrder = async (orderStatus, orderID, payerID, paymentID, paymentSource) => {
    devLog({
      OrderStatus: orderStatus,
      PaymentOrderID: orderID,
      PaymentPayerID: payerID,
      PaymentID: paymentID,
      PaymentSource: paymentSource
    });
    

    const updatedOrder = { ...order, status: orderStatus, payment_provider_source: paymentSource, payment_provider_order_id: orderID, payment_provider_payer_id: payerID, payment_provider_payment_id: paymentID};

    try {
      const response = await axios.put(`${BACKEND_URL}/orders`, updatedOrder, { withCredentials: true });
      devLog('Order Retrieved from Server: ', response.data);
      devLog('Order updated');
      setOrder(response.data);
      return response.data.id;
    } catch (error) {
      if (error.response) {
        enqueueSnackbar(`Error: ${error.response.data.error || error.response.status}`, { variant: 'error' });
      } else if (error.request) {
        enqueueSnackbar('Error: No response from server.', { variant: 'error' });
      } else {
        enqueueSnackbar(`Error: ${error.message}`, { variant: 'error' });
      }
      return null; // Return null or similar to indicate failure
    }
  }


  const value = {
    basketItems,
    addToBasket,
    removeFromBasket,
    clearBasket,
    basketTotal,
    updateQuantity,
    findItemInBasket,
    itemCount,
    isBasketEmpty,
    applyPromoCode,
    calculateShipping,
    calculateTax,
    persistBasket,
    placeOrder,
    updateOrder,
    order
  };

  return (
    <BasketContext.Provider value={value}>
      {children}
    </BasketContext.Provider>
  );
};
