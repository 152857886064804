import React from 'react';
import styles from './CheckoutSuccess.module.css';
import { useNavigate } from 'react-router-dom';

const CheckoutSuccess = () => {
    const navigate = useNavigate();

    return (
        <main className={styles.container}>
            <h1 className={styles.title}>Checkout Success!</h1>
            <h2 className={styles.subtitle}>Thank you for your purchase.</h2>
            <p className={styles.text}>Your order is now being processed and you&apos;ll receive an email confirmation shortly.</p>
            <button className={styles.button} onClick={() => navigate('/')}>Back to Home</button>
        </main>
    );
};

export default CheckoutSuccess;
