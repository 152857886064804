import React, { useState } from 'react';
import styles from './NewsLetterSignup2.module.css';
import axios from 'axios';
import { useSnackbar } from 'notistack';
import config from '../config';
import { Helmet } from 'react-helmet';

function NewsletterSignup2() {
    const [email, setEmail] = useState('');
    const { enqueueSnackbar } = useSnackbar();
    const BACKEND_URL = process.env.REACT_APP_BACKEND_URL || 'http://localhost:8080';

    const handleSubmit = async () => {
        await axios.post(`${BACKEND_URL}/email`, { email: email },  { withCredentials: true })
            .then(response => {
                localStorage.setItem(config.emailSubmitted, 'true');
                enqueueSnackbar('Thanks for signing up!', { variant: 'success' });
                setEmail('');
            })
            .catch(error => {
                if (error.response) {
                    enqueueSnackbar(`Error: ${error.response.data.error || error.response.status}`, { variant: 'error' });
                } else if (error.request) {
                    enqueueSnackbar('Error: No response from server.', { variant: 'error' });
                } else {
                    enqueueSnackbar(`Error: ${error.message}`, { variant: 'error' });
                }
            });
    };

    return (
        <main className={styles.container}>
            <Helmet>
                <title>Mind Nourish Tech Newsletter</title>
                <meta name="description" content="Alows you to sign up to the newsletter" />
                <meta name="keywords" content="lifestyle newsletter" />
            </Helmet>

            <h1 className={styles.title}><span role="img" aria-label="padlock">🔓</span> Embrace Your Health Journey with Mind Nourish Tech <span role="img" aria-label="heart">❤️</span><span role="img" aria-label="ribbon">🎗️</span></h1>

            <p className={styles.paragraph}>
                Join a community dedicated to empowering its members with the knowledge and resources to lead a healthier life.
            </p>
            
            <div>
                <input className={styles.input}
                    type="email"
                    id="emailNewsletter2"
                    name="emailNewsletter2"
                    value={email}
                    onChange={e => setEmail(e.target.value)}
                    placeholder="Enter your email address"
                    autoComplete="email" 
                />
                <button className={styles.button} onClick={handleSubmit}>
                    Sign Up
                </button>
            </div>
        </main>
    );
}

export default NewsletterSignup2;
