import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import styles from './CookieBanner.module.css';
import config from '../config';

function CookieBanner() {
  const [isVisible, setIsVisible] = useState(false);
  const location = useLocation();

  useEffect(() => {
    // Assuming lf.html somehow triggers React code, which is unusual, we dont show cookie
    const path = location.pathname;
    const shouldShowBanner = !localStorage.getItem(config.mindNourishCookieAccepted) && !path.includes('lf.html');
    setIsVisible(shouldShowBanner);
  }, [location.pathname]);
  

  const acceptCookies = () => {
    localStorage.setItem(config.mindNourishCookieAccepted, 'true');
    setIsVisible(false);
  };

  if (!isVisible) {
    return null;
  }

  return (
    <div className={styles.container}>
        <span className={styles.text}>
          By using this site you accept our use of cookies
        </span>
        <button className={styles.button} onClick={acceptCookies}>Accept</button>
    </div>
);

}

export default CookieBanner;
