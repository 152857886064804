import React, { useState, useEffect } from 'react';
import axios from 'axios';
import styles from './ViewProfile.module.css';
import { useUser } from '../../contexts/UserContext';
import { useSnackbar } from 'notistack';
import { devLog, toCapitalisedWords } from '../../helpers/HelperFunctions';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';

function ViewProfile() {
    const [userInfo, setUserInfo] = useState({
        name: '',
        age: '',
        gender: '',
        email: '',
        weight: '',
        height: '',
        activity_level: '',
        diet_preference: '',
        diet_restriction: '',
        password: ''
    });

    const { user } = useUser();
    const [isLoading, setIsLoading] = useState(true);
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();

    const BACKEND_URL = process.env.REACT_APP_BACKEND_URL || 'http://localhost:8080';

    useEffect(() => {
        devLog('View User: ', user);
        if (!user || !user.id) return; // Ensure user and user's id are available before making the request

        // Fetch the user's current details when the component mounts and place in userInfo
        axios.get(`${BACKEND_URL}/user/${user.id}`, { withCredentials: true })
            .then(response => {
                devLog('Retrieved from server: ', response.data)

                const userData = response.data;

                // Transform null values to empty strings for specific fields
                const fieldsToCheck = ['age', 'weight', 'height'];
                fieldsToCheck.forEach(field => {
                    if (userData[field] === null) {
                        userData[field] = '';
                    } else if (userData[field] != null) {
                        // If not null, convert to string for controlled input compatibility
                        userData[field] = userData[field].toString();
                    }
                });

                setUserInfo(userData);
                setIsLoading(false);
            })
            .catch(error => {
                if (error.response) {
                    enqueueSnackbar(`Error: ${error.response.data.error || error.response.status}`, { variant: 'error' });
                } else if (error.request) {
                    enqueueSnackbar('Error: No response from server.', { variant: 'error' });
                } else {
                    enqueueSnackbar(`Error: ${error.message}`, { variant: 'error' });
                }
            });
    }, [user]);

    const handleEditClick = () => {

        navigate('/edit-profile');
    };

    return (
        <main className={styles.container}>
            <Helmet>
                <title>View Profile</title>
                <meta name="description" content="View your mind nourish tech profile" />
                <meta name="keywords" content="view profile, view mind nourish tech profile" />
            </Helmet>
            {isLoading ? (
                <div>Loading...</div>
            ) : (
                <>
                    <h1 className={styles.title}>
                        Mind Nourish Tech Profile Details
                    </h1>

                    <div className={styles.profileGroup}>
                        <label className={styles.styledLabel}>Name:</label>
                        <span className={styles.styledText}>{userInfo.name}</span>
                    </div>
                    <div className={styles.profileGroup}>
                        <label className={styles.styledLabel}>Email:</label>
                        <span className={styles.styledText}>{userInfo.email}</span>
                    </div>
                    <div className={styles.profileGroup}>
                        <label className={styles.styledLabel}>Password:</label>
                        <span className={styles.styledText}>[Hidden]</span>
                    </div>
                    <div className={styles.profileGroup}>
                        <label className={styles.styledLabel}>Age:</label>
                        <span className={styles.styledText}>{userInfo.age}</span>
                    </div>
                    <div className={styles.profileGroup}>
                        <label className={styles.styledLabel}>Weight (kgs):</label>
                        <span className={styles.styledText}>{userInfo.weight}</span>
                    </div>
                    <div className={styles.profileGroup}>
                        <label className={styles.styledLabel}>Height (cm):</label>
                        <span className={styles.styledText}>{userInfo.height}</span>
                    </div>
                    <div className={styles.profileGroup}>
                        <label className={styles.styledLabel}>Gender:</label>
                        <span className={styles.styledText}>{toCapitalisedWords(userInfo.gender)}</span>
                    </div>
                    <div className={styles.profileGroup}>
                        <label className={styles.styledLabel}>Activity Level:</label>
                        <span className={styles.styledText}>{toCapitalisedWords(userInfo.activity_level)}</span>
                    </div>
                    <div className={styles.profileGroup}>
                        <label className={styles.styledLabel}>Diet Preference:</label>
                        <span className={styles.styledText}>{toCapitalisedWords(userInfo.diet_preference)}</span>
                    </div>
                    <div className={styles.profileGroup}>
                        <label className={styles.styledLabel}>Dietary Restrictions:</label>
                        <span className={styles.styledText}>{toCapitalisedWords(userInfo.diet_restriction)}</span>
                    </div>
                    <button className={styles.editButton} onClick={handleEditClick}>Edit Profile</button>
                </>
            )}
        </main>
    );

}
export default ViewProfile;
