import React from 'react';
import styles from './SarahIntro.module.css';
import SarahAvatar from '../dynamicAssets/sarah.jpg';
import { Helmet } from 'react-helmet';

function SarahIntro() {

    return (
        <main className={styles.sarahIntroContainer}>
            <Helmet>
                <title>Meet our AI Helper, Sarah</title>
                <meta name="description" content="Describes who Sarah is and how AI can help" />
                <meta name="keywords" content="AI, lifestyle help" />
            </Helmet>
            <h1 className={styles.title}>Meet Sarah!</h1>
            <div className={styles.imageContainer}>
                <img className={styles.sarahImage} src={SarahAvatar} alt="Sarah Avatar" />
            </div>
            <p className={styles.introText}>
                Hi! I&apos;m Sarah, your AI buddy at Mind Nourish Tech. <span role="img" aria-label="female">💁‍♀️</span>
                Think of me as your digital guide to a better you. My goal? To simplify your lifestyle reports with a mix of fun and expertise.
                I&apos;m here for all your wellness needs!
            </p>
            <p className={styles.introText}>
                And for a little fun? We have a mushroom-collecting game! <span role="img" aria-label="mushroom">🍄</span> Engage with our platform and collect different mushrooms.
                Stay tuned, as these could unlock some special Mind Nourish rewards soon.
            </p>
            <p className={styles.introText}>
                Tailored just for you, I work behind the scenes to craft reports based on your data. And as we evolve, expect to see more of me - maybe even dancing!
                Together, we&apos;ll make your wellness journey a delightful experience. Ready to start? <span role="img" aria-label="star">🌟</span>
            </p>

        </main>
    );
};

export default SarahIntro;
