import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';

/*if (process.env.NODE_ENV === 'development') {
    devLog("Initialising why-did-you-render");
    const whyDidYouRender = require('@welldone-software/why-did-you-render');
    whyDidYouRender(React, {
        trackAllPureComponents: true,
    });
}*/

const root = document.getElementById('root');
const appRoot = ReactDOM.createRoot(root);

appRoot.render(<App />);
