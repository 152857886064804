import React, { useEffect, useState } from 'react';
import styles from './KeyMetrics.module.css';

const KeyMetrics = () => {
  const [keyMetrics, setKeyMetrics] = useState([]);

  useEffect(() => {
    // Simulating an API call
    // Uncomment the following lines when you're ready to connect to an actual endpoint
    // axios.get('/api/key-metrics')
    // .then(response => setKeyMetrics(response.data))
    // .catch(error => devLog(error));

    // Mock data
    const mockData = [
      { id: 1, name: 'BMI', value: '23.4' },
      { id: 2, name: 'Blood Pressure', value: '120/80' },
      { id: 3, name: 'Cholesterol', value: '180 mg/dL' },
      { id: 4, name: 'Resting Heart Rate', value: '72 bpm' },
    ];

    setKeyMetrics(mockData);
  }, []);

  return (
    <section className={styles.container}>
      <h2 className={styles.title}>Key Health Metrics</h2>
      <table className={styles.table}>
        <thead>
          <tr className={styles.metricRow}>
            <th>Metric</th>
            <th>Value</th>
          </tr>
        </thead>
        <tbody>
          {keyMetrics.map(metric => (
            <tr className={styles.metricRow} key={metric.id}>
              <td>{metric.name}</td>
              <td>{metric.value}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </section>
  );
};

export default KeyMetrics;
