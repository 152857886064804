import React from 'react';
import styles from './MushroomSilverIcon.module.css';
import MushroomSilver from '../../dynamicAssets/Mushroom_silver.png';

function MushroomSilverIcon({ variant }) {
    let className;

    if (variant === 'small') {
        className = styles.small;
    } 
    if (variant === 'extra small') {
        className = styles.extraSmall;
    } 

    return (
        <img src={MushroomSilver} alt="Silver Mushroom" className={className} />
    );
}

export default MushroomSilverIcon;
