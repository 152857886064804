/// <reference path="../../types/phaser.d.ts" />

import React, { useEffect, useRef, useState } from 'react';
import Phaser from 'phaser';
import { useGameContext } from '../contexts/GameContext';
import { countThousands, devLog, getRandomInt } from '../helpers/HelperFunctions';
import jsonConfig from '../config.js';

const PhaserGameWrapper = () => {
    // Uses the game context to store state
    const { gameState, setGameState, resetGame, setResetGame, harvestingInitiated, setHarvestingInitiated, updateServer, resumedGameData, setResumedGameData, createGame } = useGameContext();

    let initialMushroomSpeed = jsonConfig.game.initialMushroomSpeed;
    let initialContaminationSpeed = jsonConfig.game.initialComtaminationSpeed;

    // Containts the speed of the mushroom and contamination
    const [contaminationSpeed, setContaminationSpeed] = useState(initialContaminationSpeed);
    const [mushroomSpeed, setMushroomSpeed] = useState(initialMushroomSpeed);

    const gameRef = useRef(null);
    const contaminationSpeedRef = useRef(contaminationSpeed);
    const mushroomSpeedRef = useRef(mushroomSpeed);
    const updateServerRef = useRef(updateServer);

    //let buttonClickSound;

    // Contains all the platforms
    let platforms;

    // Contains all the anti-contamination
    //let antiContams;

    // Bound functions to 'this' so they can be called elsewhere
    let createContaminationSpritesFunction;
    let createMushroomSpriteFunction;
    let createAntiContamSpriteFunction;

    // The amount of time that passes before chaing direction of the mushroom and contamination. This is to help if they get stuck in one direction.
    const changeDirectionInterval = 5000;

    // These are used to store the last time the sprites moved in case they get stuck 
    let mushroomLastDirectionChangeTime = 0;
    let contamination1LastDirectionChangeTime = 0;
    let contamination2LastDirectionChangeTime = 0;
    let contamination3LastDirectionChangeTime = 0;

    // These two flags are necessary because update() uses the mushroom and contamination
    // sprites. It happens so quickly, that when these srpites die, update() tries to
    // get them moving again before they are ready.
    let mushroomReady;
    let contaminationReady;

    // This timeout makes the anti contam respawsn. Adding it as a variable so it can be destroyed later.
    let releaseAntiContamTimeout;

    /**
     * Takes the current velocity and increases it by a factor between 0 and 1.
     * Returns a max value of 100.
     * 
     * @param {number} velocity 
     * @param {number} factor 
     * @returns The new velocity
     */
    function calculateVelocityIncrease(velocity, factor, maxValue = 100) {
        if (factor < 0 || factor > 1) {
            throw new Error('Factor should be between 0 and 1 inclusive');
        }
        const increase = factor * 10 * 0.05 * velocity;
        return Math.min(maxValue, Math.floor(velocity + increase));
    }

    /**
     * Takes the current velocity and decreases it by a factor between 0 and 1.
     * Returns a min value of 1.
     * 
     * @param {number} velocity 
     * @param {number} factor 
     * @returns The new velocity
     */
    function calculateVelocityDecrease(velocity, factor, minValue = 1) {
        if (factor < 0 || factor > 1) {
            throw new Error('Factor should be between 0 and 1 inclusive');
        }
        const decrease = factor * 10 * 0.05 * velocity;
        return Math.max(minValue, Math.floor(velocity - decrease));
    }

    /**
     * This is used to calculate the velocity of a sprite when loading a 
     * game from the database.
     * 
     * @param {number} initialVelocity 
     * @param {number} maxFactor 
     * @param {number} factorIncrement 
     * @param {number} maxValue 
     * @returns The new velocity
     */
    function calculateTotalVelocityIncrease(initialVelocity, maxFactor = 1, factorIncrement = 0.02, maxValue = 100) {
        let velocity = initialVelocity;

        for (let factor = factorIncrement; factor <= maxFactor; factor += factorIncrement) {
            velocity = calculateVelocityIncrease(velocity, factor);
            if (velocity >= maxValue) {
                return maxValue;
            }
        }

        return velocity;
    }


    /**
    * This is called when a sprite collides with the mushroom. Mushrooms health is reduced.
    * The damage is reduced by the armour class of the mushroom.
    *
    * @param {Phaser.GameObjects.Sprite} mushroom - A Mushroom sprite.
    * @param {Phaser.GameObjects.Sprite} sprite - A Phaser sprite.
    */
    const handleMushroomContamCollision = (mushroom, sprite) => {
        devLog(`Mushroom collided with: ${sprite.texture.key}. Damage: ${jsonConfig.game.mushroom.collisionHealthDecrement}. Armour Class: ${gameState.armour_class}`);

        setGameState(prevState => {

            const newHealth = Math.max(0, prevState.health - (jsonConfig.game.mushroom.collisionHealthDecrement - prevState.armour_class));

            return {
                ...prevState,
                health: newHealth
            };
        });
    };

    /**
    * This is called when two sprites collide with each other. Sprite2 is destroyed.
    *
    * @param {Phaser.GameObjects.Sprite} antiContam - A Phaser sprite.
    * @param {Phaser.GameObjects.Sprite} contam - A Phaser sprite.
    */
    const handleAntiContamContamCollision = (scene, antiContam, contam) => {
        //devLog(`Anti Contamination collided with ${contam.texture.key}`);

        antiContam.isAlive = false;
        antiContam.destroy();
        contam.isAlive = false;
        contam.destroy();

        //devLog('Scene: ', scene);

        respawnContamAfterDelay(scene, contam.name);
    };

    function respawnContamAfterDelay(scene, spriteName) {
        //devLog('NAME: ', spriteName);
        //devLog('SCENE: ', scene);
        scene.time.delayedCall(getRandomInt(3000, 10000), () => {
            switch (spriteName) {
                case 'contamination1':
                    scene.contamination1 = gameRef.current.scene.scenes[0].createContaminationSprite('contaminationIcon', 200, 200, 'contamination1');
                    setupMushroomColliders(scene);
                    setupAntiContamColliders(scene);
                    setupContaminationColliders(scene);
                    break;
                case 'contamination2':
                    scene.contamination2 = gameRef.current.scene.scenes[0].createContaminationSprite('contaminationIcon2', 200, 300, 'contamination2');
                    setupMushroomColliders(scene);
                    setupAntiContamColliders(scene);
                    setupContaminationColliders(scene);
                    break;
                case 'contamination3':
                    scene.contamination3 = gameRef.current.scene.scenes[0].createContaminationSprite('contaminationIcon', 300, 200, 'contamination3');
                    setupMushroomColliders(scene);
                    setupAntiContamColliders(scene);
                    setupContaminationColliders(scene);
                    break;
                default:
                    break;
            }
        });
    }



    /**
    * Changes a sprites direction and velocity to a new random direction and velocity. Also sets a random
    * timeout before it changes direction again.
    *
    * @param {Phaser.Scene} scene - The Phaser scene.
    * @param {Phaser.GameObjects.Sprite} sprite - A Phaser sprite.
    */
    const changeDirection = (scene, sprite) => {

        if (!sprite || !scene) {
            console.warn("Sprite or Scene does not exist.");
            return;
        }

        // Calculate the sprites speed depending on what type of sprite it is.
        let speed
        if (sprite.name === 'mushroom') {
            speed = mushroomSpeedRef.current;
        } else {
            speed = contaminationSpeedRef.current;
        }

        //devLog('SCENE: ', scene);
        //devLog('SPRITE: ', sprite.name);
        //devLog('SPRITE SPEED: ', speed);

        if (sprite.timeOut) {
            clearTimeout(sprite.timeOut);
        }

        const randomDirection = Math.floor(Math.random() * 4);
        const randomTime = Math.random() * 2500 + 500;

        sprite.timeOut = setTimeout(() => {
            changeDirection(scene, sprite);
        }, randomTime);

        switch (randomDirection) {
            case 0:
                if (sprite.isAlive) {
                    sprite.setVelocity(-speed, 0);
                }
                break;
            case 1:
                if (sprite.isAlive) {
                    sprite.setVelocity(speed, 0);
                }
                break;
            case 2:
                if (sprite.isAlive) {
                    sprite.setVelocity(0, -speed);
                }
                break;
            case 3:
                if (sprite.isAlive) {
                    sprite.setVelocity(0, speed);
                }
                break;
            default:
                break;
        }

        mushroomLastDirectionChangeTime = Date.now();
        contamination1LastDirectionChangeTime = Date.now();
        contamination2LastDirectionChangeTime = Date.now();
        contamination3LastDirectionChangeTime = Date.now();

        //devLog('Returning from changeDirection()')
    };

    const releaseAntiContam = (scene) => {
        //devLog('releaseAntiContam() ', scene);
        if (!scene) {
            console.warn("Scene does not exist.");
            return;
        }

        if (scene.antiContam && scene.antiContam.isAlive) {
            //devLog("Anti-Contam already alive");
        } else {
            //devLog('Releasing anti-contam');
            createAntiContamSpriteFunction('antiContamIcon');
            setupAntiContamColliders(scene);
            setupMushroomColliders(scene);
            setupContaminationColliders(scene);
        }

        const randomTime = Math.random() * 10000;

        releaseAntiContamTimeout = setTimeout(() => {
            releaseAntiContam(scene);
        }, randomTime);

        //devLog('Returning from releaseAntiContam()')
    };

    const setupContaminationColliders = (scene) => {
        if (scene.contamination1) {
            scene.physics.add.collider(scene.contamination1, platforms, null, null, scene);
        }
        if (scene.contamination1) {
            scene.physics.add.collider(scene.contamination2, platforms, null, null, scene);
        }
        if (scene.contamination1) {
            scene.physics.add.collider(scene.contamination3, platforms, null, null, scene);
        }
    }

    const setupAntiContamColliders = (scene) => {
        if (scene.contamination1 && scene.antiContam) {
            //scene.physics.add.collider(scene.contamination1, scene.antiContam, () => handleAntiContamContamCollision(scene, scene.antiContam, scene.contamination1), null, scene);

            scene.physics.add.collider(scene.contamination1, scene.antiContam, function () {
                handleAntiContamContamCollision(scene, scene.antiContam, scene.contamination1);
            }, null, scene);
        }
        if (scene.contamination2 && scene.antiContam) {
            //scene.physics.add.collider(scene.contamination2, scene.antiContam, () => handleAntiContamContamCollision(scene, scene.antiContam, scene.contamination2), null, scene);
            scene.physics.add.collider(scene.contamination2, scene.antiContam, function () {
                handleAntiContamContamCollision(scene, scene.antiContam, scene.contamination2);
            }, null, scene);
        }
        if (scene.contamination3 && scene.antiContam) {
            //scene.physics.add.collider(scene.contamination3, scene.antiContam, () => handleAntiContamContamCollision(scene, scene.antiContam, scene.contamination3), null, scene);
            scene.physics.add.collider(scene.contamination3, scene.antiContam, function () {
                handleAntiContamContamCollision(scene, scene.antiContam, scene.contamination3);
            }, null, scene);
        }

        scene.physics.add.collider(scene.antiContam, platforms);
    }

    const setupMushroomColliders = (scene) => {
        if (scene.contamination1) {
            scene.physics.add.collider(scene.mushroom, scene.contamination1, () => handleMushroomContamCollision(scene.mushroom, scene.contamination1), null, scene);
        }
        if (scene.contamination2) {
            scene.physics.add.collider(scene.mushroom, scene.contamination2, () => handleMushroomContamCollision(scene.mushroom, scene.contamination2), null, scene);
        }
        if (scene.contamination3) {
            scene.physics.add.collider(scene.mushroom, scene.contamination3, () => handleMushroomContamCollision(scene.mushroom, scene.contamination3), null, scene);
        }
        scene.physics.add.collider(scene.mushroom, platforms, null, null, scene);

        scene.physics.add.collider(scene.mushroom, scene.antiContam, null, null, scene);
    }

    /**
    * Adjusts the CO2 bar's display height based on the CO2 level
    * A higher CO2 speeds up contamination.
    * 
    * @param {Phaser.Scene} scene - The Phaser scene.
    * @param {number} level - The level of the CO2. Should be between 0 (empty) and 1 (full).
    */
    const setCO2Level = (scene, level) => {
        // Crop only the fill, leaving the border unaffected
        scene.co2BarFill.setCrop(0, scene.co2BarFill.height * (1 - level), scene.co2BarFill.width, scene.co2BarFill.height * level);
        setContaminationSpeed(calculateVelocityIncrease(contaminationSpeed, level));
    }

    /**
    * Adjusts the Humidity bar's display height based on the Humidity level.
    * A higher humidity slows down contamination.
    *
    * @param {Phaser.Scene} scene - The Phaser scene.
    * @param {number} level - The level of the Humidity. Should be between 0 (empty) and 1 (full).
    */
    const setHumidityLevel = (scene, level) => {
        scene.humidityBarFill.setCrop(0, scene.humidityBarFill.height * (1 - level), scene.humidityBarFill.width, scene.humidityBarFill.height * level);
        setContaminationSpeed(calculateVelocityDecrease(contaminationSpeed, level));
    }

    /**
    * Adjusts the Satiety bar's display height based on the Satiety level
    *
    * @param {Phaser.Scene} scene - The Phaser scene.
    * @param {number} level - The level of the Satiety. Should be between 0 (empty) and 1 (full).
    */
    const setSatietyLevel = (scene, level) => {
        scene.satietyBarFill.setCrop(0, scene.satietyBarFill.height * (1 - level), scene.satietyBarFill.width, scene.satietyBarFill.height * level);
    }

    /**
     * contaminationSpeed has changed.
     */
    useEffect(() => {
        //devLog("Updated contaminationSpeed: ", contaminationSpeed);
        contaminationSpeedRef.current = contaminationSpeed;
    }, [contaminationSpeed]);

    /**
     * mushroomSpeed has changed.
     */
    useEffect(() => {
        //devLog("Updated mushroomSpeed: ", mushroomSpeed);
        mushroomSpeedRef.current = mushroomSpeed;
    }, [mushroomSpeed]);

    // Server update function held as a ref so its not reset on re-renders.
    useEffect(() => {
        updateServerRef.current = updateServer;
    }, [updateServer]);
    // Start interval for server updates
    useEffect(() => {
        const interval = setInterval(() => {
            //devLog('Server update interval fired');
            updateServerRef.current();
        }, 1000 * 60 * jsonConfig.game.serverUpdate);

        // Clearing the interval when the component unmounts
        return () => clearInterval(interval);
    }, []);



    /**
     * Flag to say whether the New Game button has been clicked.
     * 
     * Resets the game and flag.
     */
    useEffect(() => {
        //devLog('New Game')
        if (resetGame) {
            gameRef.current.scene.scenes[0].resetPhaserGame();
            gameRef.current.scene.scenes[0].messageText.setText('Game Starting');
            setResetGame(false);
        }
    }, [resetGame]);

    /**
     * Flag to say when a game state is to be resumed for logged in users.
     */
    useEffect(() => {
        //devLog("Resumed state changed:", resumedGameData);
        if (resumedGameData.shouldResume) {
            gameRef.current.scene.scenes[0].resumePhaserGame(resumedGameData.gameState);
            gameRef.current.scene.scenes[0].messageText.setText('Game Resuming');
            setResumedGameData({ shouldResume: false });
        }
    }, [resumedGameData.shouldResume]);


    /**
     * Flag to say whether the Harvest button has been clicked.
     * 
     * Harvests the mushroom and resets the flag.
     */
    useEffect(() => {
        if (harvestingInitiated) {
            gameRef.current.scene.scenes[0].harvestMushroom();
            setHarvestingInitiated(false);
        }
    }, [harvestingInitiated]);

    // Flashing 'Game Over' text.
    let flashCount = 0;
    const maxFlashes = 5;
    const flashText = () => {
        if (flashCount < maxFlashes) {
            gameRef.current.scene.scenes[0].gameOverText.setVisible(!gameRef.current.scene.scenes[0].gameOverText.visible);
            flashCount++;
        } else {
            // Remove the timer event if done flashing
            gameRef.current.scene.scenes[0].time.removeAllEvents();
        }
    };

    /**
     * Health has changed. 
     * 
     * Check if the mushroom has died. If so, reset the game.
     * If not, update the mushroom's mood.
     */
    useEffect(() => {
        if (gameRef.current && gameRef.current.scene && gameRef.current.scene.scenes[0]) {
            if (gameState.health < 1) {
                // Create a new game in the backend.
                createGame();

                // Now reset the game
                gameRef.current.scene.scenes[0].resetPhaserGame();
                gameRef.current.scene.scenes[0].youDiedText.setText('You Died!');

                // Flashing 'Game Over' text.
                gameRef.current.scene.scenes[0].time.addEvent({
                    delay: 500,
                    callback: flashText,
                    loop: true
                });
            }
            if (gameState.health > 70) {
                gameState.mood = 'happy';
            } else if (gameState.health > 30) {
                gameState.mood = 'neutral';
            } else {
                gameState.mood = 'sad';
            }
        }
    }, [gameState.health]);

    /**
     * Score has changed.
     * 
     * Check to see if the user goes up a level.
     */
    useEffect(() => {
        if (gameRef.current && gameRef.current.scene && gameRef.current.scene.scenes[0]) {
            gameRef.current.scene.scenes[0].scoreText.setText('score: ' + gameState.score);

            // Work out the game level
            let lvl = countThousands(gameState.score);
            gameRef.current.scene.scenes[0].lvlText.setText('lvl: ' + lvl);

        }
    }, [gameState.score]);

    /**
     * CO2 has changed.
     */
    useEffect(() => {
        // Check if gameRef and scene are initialized.
        if (gameRef.current && gameRef.current.scene && gameRef.current.scene.scenes[0]) {
            setCO2Level(gameRef.current.scene.scenes[0], gameState.co2);
        }
    }, [gameState.co2]);

    /**
     * Humidity has changed.
     */
    useEffect(() => {
        // Check if gameRef and scene are initialized.
        if (gameRef.current && gameRef.current.scene && gameRef.current.scene.scenes[0]) {
            setHumidityLevel(gameRef.current.scene.scenes[0], gameState.humidity);
        }
    }, [gameState.humidity]);

    /**
     * Satiety has changed. Check if the mushroom will grow.
     * 
     * If Satiety is >= 1, grow the mushroom.
     */
    useEffect(() => {
        // Check if gameRef and scene are initialized.
        if (gameRef.current && gameRef.current.scene && gameRef.current.scene.scenes[0]) {
            setSatietyLevel(gameRef.current.scene.scenes[0], gameState.satiety);
            if (gameState.satiety >= 1) {
                gameRef.current.scene.scenes[0].growMushroom(gameState);
            }
        }
    }, [gameState.satiety]);

    // *****************************************************************************************
    // Main useEffect for Phaser stuff *********************************************************
    // *****************************************************************************************
    useEffect(() => {
        const config = {
            type: Phaser.AUTO,
            width: 350,
            height: 500,
            parent: 'phaser-game',
            physics: {
                default: 'arcade',
                arcade: {
                    gravity: { y: 0 },
                    debug: false
                }
            },
            scene: {
                preload: preload,
                create: create,
                update: update,
            }
        };

        gameRef.current = new Phaser.Game(config);

        function preload() {
            //this.load.image(process.env.PUBLIC_URL + 'mushroomIcon', 'assets/Mushroom_gold_sm.png');
            this.load.image(process.env.PUBLIC_URL + 'mushroomIcon', 'assets/game/Mushroom_v1_sm.png');
            this.load.image(process.env.PUBLIC_URL + 'contaminationIcon', 'assets/game/Contam_v1.png');
            this.load.image(process.env.PUBLIC_URL + 'contaminationIcon2', 'assets/game/Contam_v1.1.png');
            this.load.image(process.env.PUBLIC_URL + 'antiContamIcon', 'assets/game/bomb.png');
            this.load.image(process.env.PUBLIC_URL + 'sky', 'assets/game/sky.png');
            this.load.image(process.env.PUBLIC_URL + 'ground', 'assets/game/platform.png');
            this.load.image(process.env.PUBLIC_URL + 'co2BarBorder', 'assets/game/VerticalBarBorder.png');
            this.load.image(process.env.PUBLIC_URL + 'co2BarFill', 'assets/game/Co2BarFill.png');
            this.load.image(process.env.PUBLIC_URL + 'humidityBarBorder', 'assets/game/VerticalBarBorder.png');
            this.load.image(process.env.PUBLIC_URL + 'humidityBarFill', 'assets/game/HumidityBarFill.png');
            this.load.image(process.env.PUBLIC_URL + 'satietyBarBorder', 'assets/game/VerticalBarBorder.png');
            this.load.image(process.env.PUBLIC_URL + 'satietyBarFill', 'assets/game/SatietyBarFill.png');
            this.load.image(process.env.PUBLIC_URL + 'humidityIcon', 'assets/game/Water_white.png');
            this.load.image(process.env.PUBLIC_URL + 'foodIcon', 'assets/game/Food_white.png');
            this.load.image(process.env.PUBLIC_URL + 'co2Icon', 'assets/game/CO2_white.png');


            this.load.image(process.env.PUBLIC_URL + 'background', 'assets/game/Background1_v2.png');
            //this.load.image(process.env.PUBLIC_URL + 'backgroundRain', 'assets/Background1_rain_v2.png');
            this.load.image(process.env.PUBLIC_URL + 'sun', 'assets/game/Sun1.png');
            this.load.image(process.env.PUBLIC_URL + 'platform_horizontal', 'assets/game/Horizontal.png');
            this.load.image(process.env.PUBLIC_URL + 'platform_vertical', 'assets/game/Vertical.png');
            this.load.image(process.env.PUBLIC_URL + 'tree1', 'assets/game/Tree1_v2.png');

            this.load.image(process.env.PUBLIC_URL + 'raindrop', 'assets/game/Raindrop1.png');
            this.load.atlas(process.env.PUBLIC_URL + 'flares', 'assets/game/particles/flares.png', 'assets/game/particles/flares.json');
            
        }

        function create() {
            // HTML5 Canvas stuff. Create a canvas element and add to a phaser texture
            const canvas = document.createElement('canvas');
            canvas.width = 350;
            canvas.height = 375;
            const ctx = canvas.getContext('2d');

            // Create gradient for the ground
            const grd = ctx.createLinearGradient(0, 0, 0, 375);
            grd.addColorStop(0, '#7CFC00');
            grd.addColorStop(1, '#006400');

            // Fill with gradient
            ctx.fillStyle = grd;
            ctx.fillRect(0, 0, 350, 375);

            // Add canvas as a texture
            this.textures.addCanvas('groundTexture', canvas);

            /***************************************************/
            // Phaser stuff. Use the texture in Phaser

            // Add the images
            this.add.image(0, 125, 'groundTexture').setOrigin(0);
            //this.add.image(0, -125, 'sky');

            // Add the graphics
            //const graphics = this.add.graphics();

            // Draw Sun
            //graphics.fillStyle(0xFFFF00, 1);
            //graphics.fillCircle(50, 50, 20);

            this.add.image(0, 0, 'background').setOrigin(0);
            //this.bgRain = this.add.image(0, 0, 'backgroundRain').setOrigin(0);
            //this.bgRain.visible = false; // Hide the rain
            this.add.image(50, 50, 'sun').setOrigin(0);

            this.add.image(170, 100, 'tree1').setOrigin(0, 0);

            platforms = this.physics.add.staticGroup();

            //platforms.create(250, 270, 'ground').setScale(0.25).refreshBody();
            //platforms.create(25, 400, 'ground').setScale(0.25).refreshBody();

            //platforms.create(55, 200, 'platform_vertical').refreshBody();
            platforms.create(150, 400, 'platform_horizontal').refreshBody();
            platforms.create(250, 300, 'platform_horizontal').refreshBody();

            /*let rotatingPlatform = platforms.create(250, 300, 'platform_horizontal').refreshBody();
            rotatingPlatform.setOrigin(0.5, 0);

            this.tweens.add({
                targets: rotatingPlatform,
                angle: { from: 0, to: 90 }, // rotate from 0 to 90 degrees
                duration: 1000,
                ease: 'Linear',
                yoyo: true,
                repeat: -1
            });*/

            let platform = platforms.create(55, 200, 'platform_vertical').refreshBody();

            this.tweens.add({
                targets: platform,
                alpha: 0,
                duration: 1000,
                ease: 'Linear',
                yoyo: true,
                repeat: -1,
                onYoyo: function () {
                    platform.setActive(true).setVisible(true);
                },
                onRepeat: function () {
                    platform.setActive(false).setVisible(false);
                }
            });

            // Add sounds
            //buttonClickSound = this.sound.add('buttonClick');


            // Create a continuous fade in and fade out effect
            this.tweens.add({
                targets: platform,
                alpha: { from: 1, to: 0 }, // fade out (from visible to invisible)
                duration: 1000,            // time duration of fade
                yoyo: true,                // reverse the tween to make it fade in again
                repeat: -1                 // repeat forever
            });



            // Create multiple horizontal platforms to make a vertical wall/platform
            /*for (let y = 180; y < 300; y += 8) {
                platforms.create(70, y, 'platform').setScale(0.25).refreshBody();
            }*/

            this.add.image(270, 5, 'foodIcon').setOrigin(0, 0);
            this.add.image(297, 10, 'co2Icon').setOrigin(0, 0);
            this.add.image(321, 5, 'humidityIcon').setOrigin(0, 0);

            // Satiety Bar *******************************************************************
            // Add the border
            const satietyBarBorder = this.add.image(270, 30, 'satietyBarBorder').setOrigin(0, 0);

            // Add the fill inside the border
            const satietyBarFill = this.add.image(272, satietyBarBorder.height + 29, 'satietyBarFill').setOrigin(0, 1);

            // Store the fill in the scene for later cropping
            this.satietyBarFill = satietyBarFill;

            // Setting initial Satiety level
            setSatietyLevel(this, 0.1);

            // CO2 Bar *******************************************************************
            // Add the border
            const co2BarBorder = this.add.image(295, 30, 'co2BarBorder').setOrigin(0, 0);

            // Add the fill inside the border
            const co2BarFill = this.add.image(297, co2BarBorder.height + 29, 'co2BarFill').setOrigin(0, 1);

            // Store the fill in the scene for later cropping
            this.co2BarFill = co2BarFill;

            // Setting initial CO2 level
            setCO2Level(this, 0.1);

            // Humidity Bar *******************************************************************
            // Add the border
            const humidityBarBorder = this.add.image(320, 30, 'humidityBarBorder').setOrigin(0, 0);

            // Add the fill inside the border
            const humidityBarFill = this.add.image(322, humidityBarBorder.height + 29, 'humidityBarFill').setOrigin(0, 1);

            // Store the fill in the scene for later cropping
            this.humidityBarFill = humidityBarFill;

            // Setting initial Humidity level
            setHumidityLevel(this, 0.1);

            // Create all the sprites ***************************************************
            createAntiContamSpriteFunction = createAntiContamSprite.bind(this);
            createAntiContamSpriteFunction('antiContamIcon');

            createContaminationSpritesFunction = createContaminationSprites.bind(this);
            createContaminationSpritesFunction('contaminationIcon', 'contaminationIcon2');

            createMushroomSpriteFunction = createMushroomSprite.bind(this);
            createMushroomSpriteFunction('mushroomIcon');

            this.createContaminationSprite = createContaminationSprite.bind(this);

            setupAntiContamColliders(this);
            setupContaminationColliders(this);
            setupMushroomColliders(this);

            // Set initial sprite directions and velocity and start them off
            changeDirection(this, this.mushroom);
            changeDirection(this, this.contamination1);
            changeDirection(this, this.contamination2);
            changeDirection(this, this.contamination3);

            releaseAntiContam(this);

            // Attach these functions to `this` so they can be called elsewhere
            this.resetPhaserGame = resetPhaserGame.bind(this);
            this.resumePhaserGame = resumePhaserGame.bind(this);
            this.growMushroom = growMushroom.bind(this);
            this.harvestMushroom = harvestMushroom.bind(this);

            // Create the rain emitter and schedule the rain
            const rainEmitter = this.add.particles(0, 0, 'raindrop', {
                lifespan: 4000,
                speed: { min: 150, max: 250 },
                scale: { start: 0.8, end: 0.2 },
                gravityY: 300,
                blendMode: 'BLEND',
                angle: 90,
                quantity: 3,
                frequency: 100,
                x: { min: 0, max: 480 },
                y: -5,
                emitting: false
            });
            this.time.addEvent({
                delay: Phaser.Math.Between(1, 5) * 60000, // Random delay between 1 to 5 minutes
                callback: () => {
                    this.messageTextBottom.setText('Its started to rain');
                    rainEmitter.start();

                    setGameState(prevState => {
                        return {
                            ...prevState,
                            humidity: Math.min(1, prevState.humidity + jsonConfig.game.rain.humidityIncrement),
                            health: Math.min(100, prevState.health + jsonConfig.game.rain.healthIncrement),
                        };
                    });

                    this.time.delayedCall(3000, () => {
                        this.messageTextBottom.setText('');
                    });

                    this.time.delayedCall(10000, () => {rainEmitter.stop(); }, [], this); 
                },
                loop: true,
                callbackScope: this
            });

            // Create a flare emitter
            this.flareEmitter = this.add.particles(175, 250, 'flares', {
                frame: [ 'red', 'yellow', 'green' ],
                lifespan: 4000,
                speed: { min: 150, max: 250 },
                scale: { start: 0.8, end: 0 },
                gravityY: 150,
                blendMode: 'ADD',
                emitting: false
            });

            // Add score text in top right corner of screen.
            this.lvlText = this.add.text(140, 5, 'lvl: 0', { fontSize: '18px', fill: '#FFF' });

            // Add score text in top right corner of screen.
            this.scoreText = this.add.text(10, 5, 'score: 0', { fontSize: '18px', fill: '#FFF' });

            // Add message text in centre of screen.
            this.messageText = this.add.text(50, 140, '', { fontSize: '32px', fill: '#FFF' });
            
            // Add message text to bottom left of screen.
            this.messageTextBottom = this.add.text(10, 475, '', { fontSize: '18px', fill: '#FFF' });

            this.youDiedText = this.add.text(90, 140, '', { fontSize: '32px', fill: '#FFF' });

            this.gameOverText = this.add.text(80, 180, 'Game Over', { fontSize: '32px', fill: '#FFF' });
            this.gameOverText.setVisible(false);
        }

     
      

        /**
         * Creates a contamination sprite.
         * 
         * @param {string} spriteIconName - The name of the contamination icon. 
         */
        /*function createContaminationSprites(spriteIconName, spriteIconName2) {
            // Create and configure sprites
            this.contamination1 = this.physics.add.sprite(200, 200, spriteIconName);
            this.contamination2 = this.physics.add.sprite(200, 300, spriteIconName2);
            this.contamination3 = this.physics.add.sprite(300, 200, spriteIconName);

            // Create their names
            this.contamination1.name = 'contamination1';
            this.contamination2.name = 'contamination2';
            this.contamination3.name = 'contamination3';

            // Set world bounds for each sprite
            this.contamination1.setCollideWorldBounds(true);
            this.contamination2.setCollideWorldBounds(true);
            this.contamination3.setCollideWorldBounds(true);

            // Shrink it down a bit
            this.contamination1.setScale(this.contamination1.scaleX * 0.7, this.contamination1.scaleY * 0.7);
            this.contamination2.setScale(this.contamination2.scaleX * 0.7, this.contamination2.scaleY * 0.7);
            this.contamination3.setScale(this.contamination3.scaleX * 0.7, this.contamination3.scaleY * 0.7);

            // Set the bounce
            this.contamination1.setBounce(0.2);
            this.contamination2.setBounce(0.2);
            this.contamination3.setBounce(0.2);

            this.contamination1.isAlive = true;
            this.contamination2.isAlive = true;
            this.contamination3.isAlive = true;

            contaminationReady = true;
        }*/

        function createContaminationSprite(spriteIconName, x, y, name) {
            const sprite = this.physics.add.sprite(x, y, spriteIconName);

            sprite.name = name;
            sprite.setCollideWorldBounds(true);
            sprite.setScale(sprite.scaleX * 0.7, sprite.scaleY * 0.7);
            sprite.setBounce(0.2);
            sprite.isAlive = true;
            changeDirection(this, sprite);
            return sprite;
        }

        function createContaminationSprites(spriteIconName, spriteIconName2) {
            this.contamination1 = createContaminationSprite.call(this, spriteIconName, 200, 200, 'contamination1');
            this.contamination2 = createContaminationSprite.call(this, spriteIconName2, 200, 300, 'contamination2');
            this.contamination3 = createContaminationSprite.call(this, spriteIconName, 300, 200, 'contamination3');
        }





        /**
         * Creates a new player mushroom.
         * 
         * @param {string} spriteIconName - The name of the mushroom icon. 
         */
        function createMushroomSprite(spriteIconName) {
            this.mushroom = this.physics.add.sprite(100, 100, spriteIconName);
            this.mushroom.name = 'mushroom';

            // Set world bounds 
            this.mushroom.setCollideWorldBounds(true);

            // Set the bounce
            this.mushroom.setBounce(0.2);

            this.mushroom.isAlive = true;
            mushroomReady = true;
        }

        /**
         * Creates a new anti-contam.
         * 
         * @param {string} antiContamIconName - The name of anti-contam icon.
         */
        function createAntiContamSprite(antiContamIconName) {
            //antiContams = this.physics.add.group();
            //this.antiContam = antiContams.create(50, 16, antiContamIconName);
            this.antiContam = this.physics.add.sprite(50, 16, antiContamIconName);
            this.antiContam.setBounce(1);
            this.antiContam.setCollideWorldBounds(true);
            this.antiContam.setVelocity(Phaser.Math.Between(-200, 200), 20);
            this.antiContam.allowGravity = false;
            this.antiContam.name = 'anti contam';

            this.antiContam.isAlive = true;
        }

        /**
         * update() is called once per frame, i.e. 60 times a second.
         */
        function update() {
            // Check for collisions with the world bounds and change direction if they have collided with a boundary
            if (mushroomReady) {
                if (this.mushroom.x <= 0 || this.mushroom.x >= 350 || this.mushroom.y <= 0 || this.mushroom.y >= 500) {
                    changeDirection(this, this.mushroom)
                }
            }
            if (contaminationReady) {
                if (this.contamination1.x <= 0 || this.contamination1.x >= 350 || this.contamination1.y <= 0 || this.contamination1.y >= 500) {
                    changeDirection(this, this.contamination1);
                }
                if (this.contamination2.x <= 0 || this.contamination2.x >= 350 || this.contamination2.y <= 0 || this.contamination2.y >= 500) {
                    changeDirection(this, this.contamination2);
                }
                if (this.contamination3.x <= 0 || this.contamination3.x >= 350 || this.contamination3.y <= 0 || this.contamination3.y >= 500) {
                    changeDirection(this, this.contamination3);
                }
            }

            // Check if any sprites have got stuck, then move them in a different direction.
            if (Date.now() - mushroomLastDirectionChangeTime >= changeDirectionInterval) {
                changeDirection(this, this.mushroom);
            }
            if (Date.now() - contamination1LastDirectionChangeTime >= changeDirectionInterval) {
                changeDirection(this, this.contamination1);
            }
            if (Date.now() - contamination2LastDirectionChangeTime >= changeDirectionInterval) {
                changeDirection(this, this.contamination2);
            }
            if (Date.now() - contamination3LastDirectionChangeTime >= changeDirectionInterval) {
                changeDirection(this, this.contamination3);
            }
        }

        const initialGameState = {
            name: 'Mushroom',
            health: 100,
            mood: 'happy',
            score: 0,
            co2: 0.1,
            humidity: 0.1,
            satiety: 0.1,
            can_harvest: false,
            size: 'small',
            armour_class: 0,
            can_ventilate: false,
        };

        /**
         * Resets the whole game to initial values to start again.
         */
        function resetPhaserGame() {

            devLog("Resetting game...");

            // TODO This is supposed to stop the sprites. It does briefly but the update function
            // starts them off again as it runs 60 times per second.
            if (this.antiContam.isAlive) {
                this.antiContam.setVelocity(0, 0);
            }
            if (this.mushroom) {
                this.mushroom.setVelocity(0, 0);
            }
            if (this.contamination1.isAlive) {
                this.contamination1.setVelocity(0, 0);
            }
            if (this.contamination2.isAlive) {
                this.contamination2.setVelocity(0, 0);
            }
            if (this.contamination3.isAlive) {
                this.contamination3.setVelocity(0, 0);
            }

            setTimeout(() => {
                // Reset the state to initial values
                setGameState({
                    ...initialGameState,
                });

                mushroomReady = false;
                contaminationReady = false;

                this.mushroom.destroy();
                this.contamination1.destroy();
                this.contamination2.destroy();
                this.contamination3.destroy();
                this.antiContam.destroy();

                this.mushroom.isAlive = false;
                this.contamination1.isAlive = false;
                this.contamination2.isAlive = false;
                this.contamination3.isAlive = false;

                createAntiContamSpriteFunction('antiContamIcon');
                createContaminationSpritesFunction('contaminationIcon', 'contaminationIcon2');
                createMushroomSpriteFunction('mushroomIcon');

                setupAntiContamColliders(this);
                setupContaminationColliders(this);
                setupMushroomColliders(this);

                setMushroomSpeed(initialMushroomSpeed);
                setContaminationSpeed(initialContaminationSpeed);

                changeDirection(this, this.mushroom);
                changeDirection(this, this.contamination1);
                changeDirection(this, this.contamination2);
                changeDirection(this, this.contamination3);

                this.messageText.setText('');
                this.youDiedText.setText('');
                this.lvlText.setText('lvl: 0');
                this.gameOverText.setVisible(false);
            }, 3000);
        }

        /**
         * 
         * Note: Have to pass in the resumedState to avoid issues with closure.
         * 
         * @param {state} resumedState 
         */
        function resumePhaserGame(resumedState) {

            devLog("Resumming game...", resumedState);

            // TODO This is supposed to stop the sprites. It does briefly but the update function
            // starts them off again as it runs 60 times per second.
            if (this.antiContam.isAlive) {
                this.antiContam.setVelocity(0, 0);
            }
            if (this.mushroom) {
                this.mushroom.setVelocity(0, 0);
            }
            if (this.contamination1.isAlive) {
                this.contamination1.setVelocity(0, 0);
            }
            if (this.contamination2.isAlive) {
                this.contamination2.setVelocity(0, 0);
            }
            if (this.contamination3.isAlive) {
                this.contamination3.setVelocity(0, 0);
            }

            setTimeout(() => {
                // Reset the state to initial values
                setGameState({
                    ...resumedState,
                });

                mushroomReady = false;
                contaminationReady = false;

                this.mushroom.destroy();
                this.contamination1.destroy();
                this.contamination2.destroy();
                this.contamination3.destroy();
                this.antiContam.destroy();

                this.mushroom.isAlive = false;
                this.contamination1.isAlive = false;
                this.contamination2.isAlive = false;
                this.contamination3.isAlive = false;

                createAntiContamSpriteFunction('antiContamIcon');
                createContaminationSpritesFunction('contaminationIcon', 'contaminationIcon2');
                createMushroomSpriteFunction('mushroomIcon');

                setupAntiContamColliders(this);
                setupContaminationColliders(this);
                setupMushroomColliders(this);

                // Calculates the speed based on initial speed, co2 and humidity levels.
                // Only with contamination. Mushroom moves at the same speed at the moment.
                setMushroomSpeed(initialMushroomSpeed);
                setContaminationSpeed(calculateTotalVelocityIncrease(initialContaminationSpeed, resumedState.co2));

                changeDirection(this, this.mushroom);
                changeDirection(this, this.contamination1);
                changeDirection(this, this.contamination2);
                changeDirection(this, this.contamination3);

                this.messageText.setText('');
                this.youDiedText.setText('');
                this.lvlText.setText('lvl: 0');
                this.gameOverText.setVisible(false);
            }, 2000);
        }

        /**
        * Makes the mushroom grow by 10% and increases its health by 20.
        *
        * A mushroom can only grow three times. It goes from Small to Medium to Large.
        * 
        * Note: Have to pass in the gameState to avoid issues with closure.
        * 
        * @param {gameState} currentGameState
        */
        function growMushroom(currentGameState) {
            if (this.mushroom) {
                if (currentGameState.size !== 'large') {
                    devLog("Mushroom is growing from: ", currentGameState.size);
                    // To scale up by 10% based on its current size
                    this.mushroom.setScale(this.mushroom.scaleX * 1.1, this.mushroom.scaleY * 1.1);

                    let newSize;
                    switch (currentGameState.size) {
                        case 'small':
                            newSize = 'medium';
                            break;
                        case 'medium':
                            newSize = 'large';
                            break;
                        default:
                            newSize = currentGameState.size;
                    }

                    setGameState((prevState) => ({
                        ...prevState,
                        health: currentGameState.health + 20,
                        satiety: initialGameState.satiety,
                        size: newSize,
                        can_harvest: newSize === 'large'
                    }));
                }
            } else {
                devLog("Mushroom can't grow any more");
            }
        }

        /**
        * Harvests the mushroom and reduces it to its orginal size and its health to initial value. 
        */
        function harvestMushroom() {
            devLog("Mushroom is being harvested");
            if (this.mushroom) {
                this.mushroom.setScale(1);
                setGameState((prevState) => ({
                    ...prevState,
                    health: initialGameState.health,
                    size: 'small',
                    can_harvest: false,
                }));
                this.flareEmitter.explode(16);
            }
        }

        return () => {
            if (gameRef.current) {
                devLog(gameRef.current)
                clearTimeout(gameRef.current.scene.scenes[0].mushroom.timeOut);
                clearTimeout(gameRef.current.scene.scenes[0].contamination1.timeOut);
                clearTimeout(gameRef.current.scene.scenes[0].contamination2.timeOut);
                clearTimeout(gameRef.current.scene.scenes[0].contamination3.timeOut);
                clearTimeout(gameRef.current.scene.scenes[0].antiContam.timeOut);
                clearTimeout(releaseAntiContamTimeout); 
                gameRef.current.destroy(true);
            }
        }
    }, []); // End main massive useEffect *********************************************************************

    return <div id="phaser-game"></div>;
}
export default PhaserGameWrapper;
