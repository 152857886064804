import React from 'react';
import PropTypes from 'prop-types';
import styles from './VideoWrapper.module.css';

const VideoWrapper = ({ videoId }) => {
  return (
    <div className={styles.container}>
      <iframe className={styles.videoFrame}
        src={`https://www.youtube.com/embed/${videoId}`}
        allowFullScreen
        title="YouTube video player"
      ></iframe>
    </div>
  );
};

VideoWrapper.propTypes = {
  videoId: PropTypes.string.isRequired,
};

export default VideoWrapper;
