import React, { useEffect, useState } from 'react';
import styles from './UserFeedback.module.css';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { useSnackbar } from 'notistack';
import config from '../../config';

function UserFeedback({ pageId }) {
  const [feedbackGiven, setFeedbackGiven] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  // Generate a key for the current page using the pageId
  const pageFeedbackKey = `${config.feedbackSubmitted}_${pageId}`;

  const BACKEND_URL = process.env.REACT_APP_BACKEND_URL || 'http://localhost:8080';

  useEffect(() => {
    if (localStorage.getItem(pageFeedbackKey) === "true") {
      setFeedbackGiven(true);
    }
  }, [pageFeedbackKey]);

  const handleFeedback = async (response) => {
    const feedback = {
      reply: '',
      source: ''
    }
    feedback.source = pageId;
    feedback.reply = response;

    await axios.post(`${BACKEND_URL}/feedback`, feedback, { withCredentials: true })
      .then(response => {

        localStorage.setItem(pageFeedbackKey, "true");
        setFeedbackGiven(true);
        enqueueSnackbar('Thanks for the feedback!', { variant: 'success' });
      })
      .catch(error => {
        if (error.response) {
          enqueueSnackbar(`Error: ${error.response.data.error || error.response.status}`, { variant: 'error' });
        } else if (error.request) {
          enqueueSnackbar('Error: No response from server.', { variant: 'error' });
        } else {
          enqueueSnackbar(`Error: ${error.message}`, { variant: 'error' });
        }
      });
  };

  return (
    <div className={styles.feedbackContainer}>
      <p className={styles.feedbackText}>Did this page help you?</p>
      <div className={styles.buttonGroup}>
        <button className={styles.feedbackButton} disabled={feedbackGiven} onClick={() => handleFeedback('yes')}><span role="img" aria-label="thumb up">👍 Yes</span></button>
        <button className={styles.feedbackButton} disabled={feedbackGiven} onClick={() => handleFeedback('no')}><span role="img" aria-label="thumb down">👎 No</span></button>
      </div>
      <Link className={styles.feedbackLink} to="/contact">Provide Feedback</Link>
    </div>
  );
}

export default UserFeedback;
