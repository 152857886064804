import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import Helmet from 'react-helmet';
import { useSnackbar } from 'notistack';
import styles from './BlogList.module.css';

const BlogList = ({ limit, isEmbedded }) => {
  const [blogs, setBlogs] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const BACKEND_URL = process.env.REACT_APP_BACKEND_URL || 'http://localhost:8080';

  useEffect(() => {
    const url = `${BACKEND_URL}/blogs${limit ? `?limit=${limit}` : ''}`;
    axios.get(url)
      .then(response => setBlogs(response.data || []))
      .catch(error => {
        console.error('Error fetching blogs:', error);
        setBlogs([]);
        enqueueSnackbar(`Error fetching blogs: ${error.message}`, { variant: 'error' });
      });
  }, [limit, BACKEND_URL, enqueueSnackbar]);

  return (
    <main className={styles.blogListContainer}>
      {!isEmbedded && (
        <Helmet>
          <title>Mind Nourish Musings: Blog List</title>
          <meta name="description" content="List of lifestyle and technology blogs" />
          <meta name="keywords" content="blogs, lifestyle report blogs, health blogs, tech blogs, blog list" />
        </Helmet>
      )}

      {!isEmbedded && (
        <>
          <h1 className={styles.title}>
            <strong>Mind Nourish Musings:</strong> Tech Twists on Timeless Wellness
          </h1>
          <section className={styles.section}>
            <p className={styles.description}>
              Welcome to Mind Nourish Musings, where we blend the latest in technology with timeless wellness wisdom. Our blog is your go-to source for insightful articles on health, lifestyle, fitness, and how modern advancements can enhance your well-being.
            </p>
            <h2>Discover our most popular and insightful blogs, handpicked for their valuable content and unique perspectives.</h2>
          </section>
        </>
      )}

      {blogs.filter(blog => blog.isPublished).slice(0, limit).map(blog => (
        <Link to={`/blog-title/${blog.slug}/`} className={styles.blogItemLink} key={blog.id}>
          <div className={styles.blogItem}>
            <h2 className={styles.blogTitle}>{blog.title}</h2>
            <span className={styles.blogTopic}>Topic: {blog.topic}</span>
            {blog.imageUrl && (
              <div className={styles.imageContainer}>
                <img src={`${process.env.PUBLIC_URL}/${blog.imageUrl}`} alt="blog featured" className={styles.blogImage} />
              </div>
            )}
            <p className={styles.blogSnippet} dangerouslySetInnerHTML={{ __html: blog.snippet }} />
            <span className={styles.readMoreLink}>Read More</span>
          </div>
        </Link>
      ))}
    </main>
  );
};

export default BlogList;
