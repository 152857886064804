import React, { useEffect } from 'react';
import Home from "./layout/Home";
import Journey from "./layout/Journey";
import Header from "./layout/Header";
import Footer from "./layout/Footer";
import SignupComponent from './components/authentication/SignupComponent';
import { QuestionsProvider } from './contexts/QuestionsContext';
import { UserProvider } from './contexts/UserContext';
import { createGlobalStyle, ThemeProvider } from 'styled-components';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import ReactGA from 'react-ga4';
//import ReactPixel from 'react-facebook-pixel';
import PrivacyPolicyComponent from './components/PrivacyPolicy';
import FAQComponent from './components/FAQs'
import ContactUsComponent from './components/ContactUs';
import AboutUsComponent from './components/AboutUs';
import SubscriptionsComponent from './components/Subscriptions';
import SarahIntroComponent from './components/SarahIntro';
import Blog from './components/blogs/Blog';
import BlogList from './components/blogs/BlogList';
import AdminAddBlogComponent from './components/admin/AdminAddBlog';
import AdminListBlogsComponent from './components/admin/AdminListBlogs';
import AdminListCommentsComponent from './components/admin/AdminListComments';
import AdminListUsersComponent from './components/admin/AdminListUsers';
import EditProfileComponent from './components/user/EditProfile';
import ViewProfileComponent from './components/user/ViewProfile';
import DashboardComponent from './components/dashboard/Dashboard';
import ReportResponseComponent from './components/reports/ReportResponse';
import ReportErrorResponseComponent from './components/reports/ReportErrorResponse';
import DisclaimerComponent from './components/Disclaimer';
import LeaderBoardComponent from './components/LeaderBoard';
import SiteMapComponent from './components/SiteMap';
import MushroomStatsComponent from './components/MushroomStats';
import GameInstructions from './components/GameInstructions';
import ReportInstructions from './components/ReportInstructions';
import ReportInfo from './components/ReportInfo';
import Videos from './components/Videos';
import ProductList from './components/shop/ProductList';
import CategoryList from './components/shop/CategoryList';
import Product from './components/shop/Product';
import Basket from './components/shop/Basket';
import CheckoutSuccess from './components/shop/CheckoutSuccess';
import CheckoutFailure from './components/shop/CheckoutFailure';
import CheckoutCancel from './components/shop/CheckoutCancel';
import Checkout from './components/shop/Checkout';
import HelloTensor from './components/HelloTensor';
import CookieBanner from './components/CookieBanner';
import GameComponent from './components/GameComponent';
import AdminToolsComponent from './components/admin/AdminTools';
import ChatBot from './components/ChatBot';
import TheMushroomComponent from './components/TheMushroom';
import TestComponent from './components/TestComponent';
import NewsLetterSignupComponent from './components/NewsLetterSignup';
import NewsLetterSignupComponent2 from './components/NewsLetterSignup2';
import NewsletterSignupModal from './components/NewsLetterSignupModal';
import AIEmbraceComponent from './components/AIEmbrace';
import DonationsPage from './components/DonationsPage';
import CollectibleMushrooms from './components/CollectibleMushrooms';
import ScrollToTop from './components/common/ScrollToTop';
import { BasketProvider } from './contexts/BasketContext';
import { GameProvider } from './contexts/GameContext';
import { FacebookPixelProvider } from './contexts/FacebookPixelContext';
import config from './config';
import { useUser } from './contexts/UserContext';
import { SnackbarProvider } from 'notistack';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faFacebook, faInstagram, faTiktok, faYoutube, faSquareInstagram, faSquareYoutube } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope, faFile, faBell } from '@fortawesome/free-solid-svg-icons';
import AdminListOrdersComponent from './components/admin/AdminListOrders';

// These are required for each font awesome icon used.
library.add(faFacebook, faInstagram, faSquareInstagram, faTiktok, faYoutube, faEnvelope, faSquareYoutube, faFile, faBell);

const GlobalStyle = createGlobalStyle`
    body {
        background-color: #E9F7EF;
        font-size: larger;
    }
`;

const theme = {
  main: "blue",
  second: "red"
};

const UserProviderWithLocation = ({ children }) => {
  const location = useLocation();

  return <UserProvider currentPath={location.pathname}>{children}</UserProvider>;
};



function ProtectedRoutes() {
  const { user } = useUser();

  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/signup" element={<SignupComponent />} />
      <Route path="/journey" element={<Journey />} />
      <Route path="/privacy-policy" element={<PrivacyPolicyComponent />} />
      <Route path="/faqs" element={<FAQComponent />} />
      <Route path="/contact" element={<ContactUsComponent />} />
      <Route path="/about" element={<AboutUsComponent />} />
      <Route path="/subscriptions" element={<SubscriptionsComponent />} />
      <Route path="/sarah" element={<SarahIntroComponent />} />
      <Route path="/report" element={<ReportResponseComponent />} />
      <Route path="/report-error" element={<ReportErrorResponseComponent />} />
      <Route path="/disclaimer" element={<DisclaimerComponent />} />
      <Route path="/game-instructions" element={<GameInstructions />} />
      <Route path="/report-instructions" element={<ReportInstructions />} />
      <Route path="/report-info" element={<ReportInfo />} />
      <Route path="/mushroom" element={<TheMushroomComponent />} />
      <Route path="/newsletter" element={<NewsLetterSignupComponent />} />
      <Route path="/newsletter2" element={<NewsLetterSignupComponent2 />} />
      <Route path="/ai" element={<AIEmbraceComponent />} />
      <Route path="/site-map" element={<SiteMapComponent user={user} config={config} />} />
      <Route path="/blogs" element={<BlogList />} />
      <Route path="/blog-title/:slug" element={<Blog />} />
      <Route path="/leader-board" element={<LeaderBoardComponent limit={10} />} />
      <Route path="/donate" element={<DonationsPage />} />
      <Route path="/collectibles" element={<CollectibleMushrooms />} />

      {user && <Route path="/edit-profile" element={<EditProfileComponent />} />}
      {user && <Route path="/view-profile" element={<ViewProfileComponent />} />}
      {user && <Route path="/mushroom-stats" element={<MushroomStatsComponent />} />}

      {user && <Route path="/dashboard" element={<DashboardComponent />} />}

      {config.enableDevFeatures && <Route path="/game" element={<GameProvider><GameComponent /></GameProvider>} />}
      {config.enableDevFeatures && <Route path="/videos" element={<Videos />} />}
      {config.enableDevFeatures && <Route path="/tensor" element={<HelloTensor />} />}
      {config.enableDevFeatures && <Route path="/chat" element={<ChatBot />} />}

      {config.enableDevFeatures && <Route path="/shop/categories" exact element={<CategoryList />} />}
      {config.enableDevFeatures && <Route path="/shop/products/:shopId/:categoryId" element={<ProductList />} />}
      {config.enableDevFeatures && <Route path="/shop/product/:productName" element={<Product />} />}
      {config.enableDevFeatures && <Route path="/shop/basket" element={<Basket />} />}
      {config.enableDevFeatures && <Route path="/shop/checkout" element={<Checkout />} />}
      {config.enableDevFeatures && <Route path="/shop/checkout-success" element={<CheckoutSuccess />} />}
      {config.enableDevFeatures && <Route path="/shop/checkout-failure" element={<CheckoutFailure />} />}
      {config.enableDevFeatures && <Route path="/shop/checkout-cancel" element={<CheckoutCancel />} />}

      {user && user.is_admin && <Route path="/admin/add-blog" element={<AdminAddBlogComponent />} />}
      {user && user.is_admin && <Route path="/admin/list-blogs" element={<AdminListBlogsComponent />} />}
      {user && user.is_admin && <Route path="/admin/edit-blog/:id" element={<AdminAddBlogComponent />} />}
      {user && user.is_admin && <Route path="/admin/list-comments" element={<AdminListCommentsComponent />} />}
      {user && user.is_admin && <Route path="/admin/list-orders" element={<AdminListOrdersComponent />} />}
      {user && user.is_admin && <Route path="/admin/list-users" element={<AdminListUsersComponent />} />}
      {user && user.is_admin && <Route path="/admin/tools" element={<AdminToolsComponent />} />}

      {user && user.is_admin && config.enableDevFeatures && <Route path="/admin/test" element={<TestComponent />} />}
    </Routes>
  );
}

function AppWithRoutes() {
  const location = useLocation();

  ReactGA.initialize([
    {
      trackingId: 'G-2CC9FN0ND1',
      testMode: process.env.REACT_APP_GOOGLE_ANALYTICS_DEBUG === 'true',
      // Setting cookie_flags to ensure cookies are compliant with upcoming browser changes
      cookie_flags: 'SameSite=None; Secure',
    }
  ]);

  useEffect(() => {
    const isGoogleAnalyticsEnabled = process.env.REACT_APP_ENABLE_GOOGLE_ANALYTICS === 'true';

    if (isGoogleAnalyticsEnabled) {
      ReactGA.send({ hitType: "pageview", page: location.pathname + location.search, title: "Location Change" });
    }
  }, [location]);

  return (
    <>
      <Header />
      <NewsletterSignupModal />
      <QuestionsProvider>
        <ProtectedRoutes />
      </QuestionsProvider>
      <Footer />
      <CookieBanner />
    </>
  );
}

function App() {


  /*useEffect(() => {
    const isFacebookPixelEnabled = process.env.REACT_APP_ENABLE_FACEBOOK_PIXEL === 'true';

    if (isFacebookPixelEnabled) {
      console.log(111)
      const advancedMatching = {}; // optional, more info: https://developers.facebook.com/docs/facebook-pixel/advanced/advanced-matching
      const options = {
        autoConfig: true, 	// set pixel's autoConfig
        debug: process.env.REACT_APP_FACEBOOK_PIXEL_DEBUG, 	        // enable logs
      };

      //ReactPixel.init("3617411984961365", advancedMatching, options);
      ReactPixel.init("1245358512496965", advancedMatching, options);
      ReactPixel.pageView(); 	// For tracking page view

      // Add event listeners (optional)
      // ReactPixel.track(event, data); // For tracking custom events
    }
    console.log(222)
  }, []);*/

  return (
    <FacebookPixelProvider>
      <SnackbarProvider maxSnack={3}>
        <BasketProvider>
          <Router>
            <ScrollToTop />
            <GameProvider>
              <UserProviderWithLocation>
                <GlobalStyle />
                <ThemeProvider theme={theme}>
                  <Routes>
                    <Route path="*" element={<AppWithRoutes />} />
                  </Routes>
                </ThemeProvider>
              </UserProviderWithLocation>
            </GameProvider>
          </Router>
        </BasketProvider>
      </SnackbarProvider>
    </FacebookPixelProvider>
  );
}

export default App;
