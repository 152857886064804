import React from 'react';
import Nav from "./Nav";

function Header() {

    return (
        <>
            <Nav />
        </>
    );
}

export default Header;
