import React, { useState } from 'react';
import PhaserGameWrapper from './PhaserGameWrapper';
import styles from './GameComponent.module.css';
import { useGameContext } from '../contexts/GameContext';
import { useUser } from '../contexts/UserContext';
import config from '../config';
import HappyFace from '../dynamicAssets/HappyFace.png';
import NeutralFace from '../dynamicAssets/NeutralFace.png';
import SadFace from '../dynamicAssets/SadFace.png';

import SoundOnIcon from '../dynamicAssets/VolumeOn.png';
import SoundOffIcon from '../dynamicAssets/VolumeOff.png';

const GameComponent = () => {
    const { gameState, handleWatering, handleFeeding, handleVentilate, handleHarvesting, handleResumeOrReset } = useGameContext();
    const { user } = useUser();
    const [isSoundOn, setSoundOn] = useState(false);


    const toggleSound = () => {
        setSoundOn(!isSoundOn);
        // TODO Integrate with your Phaser game logic to actually mute or unmute the game
    };

    const [disabledButtons, setDisabledButtons] = useState({});
    const [ventilateIsDisabled, setVentilateIsDisabled] = useState(false);

    const handleButtonClick = (actionFunc, buttonId) => {
        actionFunc();

        setDisabledButtons(prevState => ({
            ...prevState,
            [buttonId]: true
        }));

        setTimeout(() => {
            setDisabledButtons(prevState => ({
                ...prevState,
                [buttonId]: false
            }));
        }, config.game.controlButtonDelay);
    };

    const handleVentilateClick = () => {

        handleVentilate();
        setVentilateIsDisabled(true);

        setTimeout(() => {
            setVentilateIsDisabled(false);
        }, config.game.ventilate.buttonDelay);
    };

    const bgColorClass = gameState.health > 50 ? styles.green : styles.red;

    return (
        <div className={styles.container}>

            <div className={styles.titleBar}>
                <h2 className={styles.title}>Mushroom&apos;s Adventure</h2>
                <div className={styles.soundToggle} onClick={toggleSound}>
                    {isSoundOn ?
                        <img src={SoundOnIcon} alt="Sound On" />
                        :
                        <img src={SoundOffIcon} alt="Sound Off" />
                    }
                </div>
            </div>
            <div className={styles.gameStateInfo}>
                <div className={`${styles.healthBar} ${bgColorClass}`} style={{ width: `${gameState.health}%` }}></div>
                <div className={styles.mushroomImagePlaceholder}>
                    {gameState.mood === 'happy' ?
                        <img src={HappyFace} alt="Happy Mood" />
                        : gameState.mood === 'neutral' ?
                            <img src={NeutralFace} alt="Neutral Mood" />
                            :
                            <img src={SadFace} alt="Sad Mood" />}
                </div>
            </div>




            <div className={styles.styledPhaserGameWrapper}>
                <PhaserGameWrapper />
            </div>

            <div className={styles.gameControls}>
                <div className={styles.buttonContainer}>
                    {user ? (
                        <button className={styles.controlButton} onClick={() => handleButtonClick(() => handleResumeOrReset(user))}>Resume</button>
                    ) : (
                        <button className={styles.controlButton} onClick={() => handleButtonClick(() => handleResumeOrReset())}>New Game</button>
                    )}


                    <button className={styles.controlButton}
                        onClick={() => handleButtonClick(handleWatering, 'water')}
                        disabled={disabledButtons.water}
                    >
                        Water
                    </button>
                    <button className={styles.controlButton}
                        onClick={() => handleButtonClick(handleFeeding, 'feed')}
                        disabled={disabledButtons.feed}
                    >
                        Feed
                    </button>
                    <button className={styles.controlButton} onClick={handleVentilateClick}
                        disabled={ventilateIsDisabled}
                    >
                        Ventilate
                    </button>
                    <button className={styles.controlButton} onClick={handleHarvesting} disabled={gameState.can_harvest === false}>Harvest</button>
                </div>
            </div>

        </div>
    );
}

export default GameComponent;

