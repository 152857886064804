import React from 'react';
import styles from './TheMushroom.module.css';
import { Link } from 'react-router-dom';
import UserFeedback from './common/UserFeedback';
import { Helmet } from 'react-helmet';

function TheMushroom() {
    
    return (
        <main className={styles.container}>
            <Helmet>
                <title>Mushroom Information Page</title>
                <meta name="description" content="Information on the benefits of mushrooms and the in-game mushroom hero" />
                <meta name="keywords" content="mushroom benefits" />
            </Helmet>
            <h1 className={styles.title}>Discover the Magic of Mushrooms!</h1>

            <p className={styles.paragraph}>
                Step into the enchanting realm of fungi with our interactive and educational game! As you nurture and protect your virtual mushroom, you&apos;ll also uncover the myriad benefits mushrooms bring to our plates and our health.
            </p>

            <h2 className={styles.sectionTitle}>Mushroom Mantras</h2>

            <p className={styles.paragraph}>
                Often seen as culinary delights, mushrooms pack more than just flavor. They&apos;re low in calories yet rich in essential nutrients - think fiber, protein, and a medley of antioxidants. Did you know they could even play a part in warding off conditions like Alzheimer&apos;s, heart disease, cancer, and diabetes? Not to mention, they&apos;re brimming with Selenium, Vitamin D, and a range of B-vitamins.
            </p>

            <h2 className={styles.sectionTitle}>Mighty Health Benefits</h2>

            <p className={styles.paragraph}>
                But there&apos;s more! Recent studies have spotlighted mushrooms for their antiviral, anti-inflammatory, and anti-diabetic properties. Incorporate them into your diet, and you might find an uptick in immunity, a decreased risk of heart disease, and support in your weight loss journey.
            </p>

            <h2 className={styles.sectionTitle}>Game, Set, Learn!</h2>

            <p className={styles.paragraph}>
                Our Mushroom Survival Game isn&apos;t just about dodging threats and keeping your mushroom alive. As you progress, you&apos;ll unlock bite-sized facts about mushrooms&apos; real-world benefits. So you&apos;re not just playing; you&apos;re embarking on a learning adventure into the world of fungi.
            </p>

            <h2 className={styles.sectionTitle}>Gaming for a Better You</h2>

            <p className={styles.paragraph}>
                We&apos;re passionate about weaving education into entertainment. With the Mushroom Survival Game, every second you play enriches your knowledge, possibly inspiring you to make healthier food choices. Dive in, have fun, and let mushrooms shape a healthier you!
            </p>

            <Link className={styles.link} to="/game-instructions">Ready to embark? Dive into the full game instructions here.</Link>
            <UserFeedback pageId="the-mushroom" />
        </main>

    );
};

export default TheMushroom;
