import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function InstagramIcon() {
    return (
        <FontAwesomeIcon icon={['fab', 'instagram']} color="#FAFAFA" size="2x" />
    );
}

export default InstagramIcon;
