import React from 'react';
import { Link } from 'react-router-dom';
import styles from './PrivacyPolicy.module.css';
import { Helmet } from 'react-helmet';

function PrivacyPolicy() {
    return (
        <main className={styles.container}>
            <Helmet>
                <title>Privacy Policy</title>
                <meta name="description" content="The website privacy policy" />
                <meta name="keywords" content="privacy policy" />
            </Helmet>
            <h1 className={styles.title}>Privacy Policy</h1>

            <p className={styles.paragraph}>
                Last updated: August 2023
            </p>

            <p className={styles.paragraph}>
                Welcome to Mind Nourish Tech. This Privacy Policy describes how your personal information is collected, used, and shared when you visit or make a purchase from our website.
            </p>

            <h2 className={styles.sectionTitle}>Personal Information We Collect</h2>

            <p className={styles.paragraph}>
                When you visit our site or sign up for our services, we collect the following types of personal information from you:
            </p>

            <p className={styles.paragraph}>
                - Email Address<br />
                - Name<br />
                - Age<br />
                - Gender<br />
                - Weight<br />
                - Height<br />
                - Dietary Preferences and Restrictions<br />
                - Activity Level<br />
            </p>

            <p className={styles.paragraph}>
                We also automatically collect certain information about your device, including information about your web browser, IP address, time zone, and some of the cookies that are installed on your device.
            </p>

            <h2 className={styles.sectionTitle}>How We Use Your Information</h2>

            <p className={styles.paragraph}>
                We use the information that we collect for the following purposes:
            </p>

            <p className={styles.paragraph}>
                - To create and manage your account<br />
                - To provide and improve our services<br />
                - For communication and customer support<br />
                - To screen for potential risk and fraud (particularly, your IP address)<br />
                - To optimize our website and services<br />
            </p>

            <h2 className={styles.sectionTitle}>Sharing Your Personal Information</h2>

            <p className={styles.paragraph}>
                We do not share your personal information with third parties outside Mind Nourish Tech except to the extent necessary to provide you with a requested service, fulfill a requested transaction, or in other specific situations that comply with legal requirements.
            </p>

            <h2 className={styles.sectionTitle}>Your Rights</h2>

            <p className={styles.paragraph}>
                If you are a resident of the European Union, you have the right under the General Data Protection Regulation (GDPR) to access personal information we hold about you and to ask that your personal information be corrected, updated, or deleted. To exercise this right, please contact us through the information below.
            </p>

            <h2 className={styles.sectionTitle}>Contact Us</h2>

            <p className={styles.paragraph}>
                For more information about our privacy practices, if you have questions, or if you would like to make a complaint, please contact us at <Link className={styles.emailLink} to="mailto:info@mindnourishtech.com">info@mindnourishtech.com</Link> or send us a message on our <Link className={styles.link} to="/contact">Contact Page</Link>
            </p>

        </main>
    );
}

export default PrivacyPolicy;
