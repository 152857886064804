import React, { createContext, useState } from 'react';

// Create the context
export const QuestionsContext = createContext();

export function QuestionsProvider({ children }) {
    // State for storing the fetched questions data
    const [questionsData, setQuestionsData] = useState({ questions: [] });

    // State to determine if the questions have been fetched
    const [questionsFetched, setQuestionsFetched] = useState(false);

    // Provide both the data and the functions to the components
    return (
        <QuestionsContext.Provider value={{
            questionsData,
            setQuestionsData,
            questionsFetched,
            setQuestionsFetched
        }}>
            {children}
        </QuestionsContext.Provider>
    );
}
