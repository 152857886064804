import React from 'react';
import styles from './CheckoutCancel.module.css';
import { useNavigate } from 'react-router-dom';

const CheckoutCancel = () => {
    const navigate = useNavigate();

    return (
        <main className={styles.container}>
            <h1 className={styles.title}>Checkout Cancelled</h1>
            <button className={styles.button} onClick={() => navigate(-1)}>Back to Basket</button>
        </main>
    );
};

export default CheckoutCancel;
