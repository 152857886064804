import React from 'react';
import styles from './AboutUs.module.css';
import { Helmet } from 'react-helmet';
import UserFeedback from './common/UserFeedback';

function AboutUs() {
    return (
        <main className={styles.container}>
            <Helmet>
                <title>Mind Nourish Tech: About Us</title>
                <meta name="description" content="A description of who Mind Nourish Tech are and what we do" />
                <meta name="keywords" content="about us" />
            </Helmet>

            <section className={styles.section}>
                <h1 className={styles.title}><strong>Mind Nourish Tech:</strong> Where technology meets wellness.</h1>
                <p className={styles.description}>Imagine harnessing the power of advanced artificial intelligence seamlessly blended with genuine human expertise. At Mind Nourish Tech, we&apos;re revolutionising the way you approach personal wellness.
                    Our platform is tailored to offer personalized advice on health, lifestyle, and fitness - all molded to fit the unique contours of your life.</p>
                <p className={styles.description}><span role="img" aria-label="plant">🌱</span> <strong>Personalised Insight:</strong> Our cutting-edge AI evolves with every interaction, crafting advice that&apos;s uniquely yours. Whether you're looking to improve your diet, enhance your fitness routine, or find balance in your daily life, our AI provides insights that are tailored just for you.</p>
                <p className={styles.description}><span role="img" aria-label="handshake">🤝</span> <strong>The Human Touch:</strong> Beyond algorithms, connect with a community that values genuine human understanding and warmth. Our experts and community members are here to support and guide you, ensuring that you never feel alone on your wellness journey.</p>
                <p className={styles.description}><span role="img" aria-label="lightbulb">💡</span> <strong>Transformative Advice:</strong> Whether it&apos;s a small tweak in your daily routine or a comprehensive lifestyle change, we&apos;re your beacon of guidance. From personalised fitness plans to mental health tips, our advice is designed to transform your life in meaningful ways.</p>
                <p className={styles.description}>Embark on a journey to a brighter, healthier, and more fulfilled tomorrow. Dive in and let Mind Nourish Tech illuminate your path!</p>
            </section>

            <div className={styles.feedback}>
                <UserFeedback pageId="about" />
            </div>
        </main>
    );
}

export default AboutUs;
