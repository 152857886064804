import React, { useState, useEffect } from 'react';
import styles from './NewsLetterSignupModal.module.css';
import { useUser } from '../contexts/UserContext';
import axios from 'axios';
import config from '../config';
import { useSnackbar } from 'notistack';

function NewsletterSignupModal() {
    const [email, setEmail] = useState('');
    const [showModal, setShowModal] = useState(false);
    const { isLoggedIn } = useUser();
    const [errors, setErrors] = useState({});
    const { enqueueSnackbar } = useSnackbar();
    const BACKEND_URL = process.env.REACT_APP_BACKEND_URL || 'http://localhost:8080';

    useEffect(() => {
        const emailSubmitted = localStorage.getItem(config.emailSubmitted) === 'true';
        const dismissalDate = new Date(localStorage.getItem(config.newsletterModalNextShowDate));
        const now = new Date();

        // Only display if user is not logged in, hasn't submitted email, and either hasn't dismissed the modal or the dismissal date has passed.
        if (!isLoggedIn && !emailSubmitted && (!dismissalDate || now > dismissalDate)) {
            const timer = setTimeout(() => {
                setShowModal(true);
            }, config.newsletterModalDelay);
            return () => clearTimeout(timer);
        }
    }, [isLoggedIn]);

    const validateEmail = (email) => {
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

        if (!emailRegex.test(email)) {
            setErrors({ email: "Email not valid" });
            return false;
        }
        setErrors({});
        return true;
    }


    const handleSubmit = async () => {
        await axios.post(`${BACKEND_URL}/email`, { email: email },  { withCredentials: true })
            .then(response => {
                localStorage.setItem(config.emailSubmitted, 'true');
                enqueueSnackbar('Thanks for signing up!', { variant: 'success' });
            })
            .catch(error => {
                if (error.response) {
                    enqueueSnackbar(`Error: ${error.response.data.error || error.response.status}`, { variant: 'error' });
                } else if (error.request) {
                    enqueueSnackbar('Error: No response from server.', { variant: 'error' });
                } else {
                    enqueueSnackbar(`Error: ${error.message}`, { variant: 'error' });
                }
            });
        setShowModal(false)
    };

    const handleNotNowSubmit = async () => {
        const currentDate = new Date();
        currentDate.setMonth(currentDate.getMonth() + config.newsletterNotNowDelay); // Set to 1 month later
        localStorage.setItem(config.newsletterModalNextShowDate, currentDate.toISOString());
        setShowModal(false);
    };

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            handleSubmit();
        }
    }

    if (!showModal) return null;

    return (
        <div className={styles.overlay}>
            <div className={styles.container}>
                <h2 className={styles.title}>Join Our Newsletter</h2>
                <p className={styles.paragraph}>
                    Get the latest updates and insights straight to your inbox.
                </p>
                <div className={styles.formContainer}>
                    <input
                        className={styles.input}
                        type="email"
                        id="emailModal"
                        name="emailModal"
                        value={email}
                        onChange={e => setEmail(e.target.value)}
                        onBlur={() => validateEmail(email)}
                        onKeyPress={handleKeyPress}
                        placeholder="Enter your email"
                        autoComplete="email"
                    />
                    <button className={styles.button} onClick={handleSubmit}>Subscribe</button>
                    <button className={styles.secondaryButton} onClick={handleNotNowSubmit}>Not Now</button>
                    {errors.email && <div className={styles.errorMessage}>{errors.email}</div>}
                </div>
            </div>
        </div>
    );
}

export default NewsletterSignupModal;
