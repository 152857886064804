import React, { useEffect, useState, useContext } from 'react';
import styles from './Achievements.module.css';
import axios from 'axios';
import { UserContext } from '../../contexts/UserContext';
import { devLog } from '../../helpers/HelperFunctions';
import { useSnackbar } from 'notistack';

const Achievements = () => {
  const [achievements, setAchievements] = useState([]);
  const [newAchievement, setNewAchievement] = useState('');
  const { user } = useContext(UserContext);
  const { enqueueSnackbar } = useSnackbar();
  const BACKEND_URL = process.env.REACT_APP_BACKEND_URL || 'http://localhost:8080';

  useEffect(() => {
    axios.get(`${BACKEND_URL}/achievements/${user.id}`, { withCredentials: true })
      .then(response => {
        // Check if response.data is an array, if not set it to an empty array.
        const achievementsData = Array.isArray(response.data) ? response.data : [];
        setAchievements(achievementsData);
        devLog('Retrieved user achievements from server: ', achievementsData);
      })
      .catch(error => {
        devLog('Error retrieving achievements:', error);
        enqueueSnackbar(error, { variant: 'error' });
        setAchievements([]);
      });

  }, [user.id, BACKEND_URL]);

  const handleAdd = () => {
    const newAchievementEntry = {
      userId: user.id,
      description: newAchievement,
    };

    axios.post(`${BACKEND_URL}/addAchievement`, newAchievementEntry, { withCredentials: true })
      .then(response => {
        //devLog('Achievement added: ', response.data);
        const newAchievementData = response.data;
        setAchievements([...achievements, newAchievementData]);
        setNewAchievement('');
        //devLog('Added new achievement: ', newAchievementData);
        enqueueSnackbar('New Achievement Added!', { variant: 'success' });
      })
      .catch(error => {
        if (error.response && error.response.status === 400) {
          devLog('Error adding achievement (Bad Request):', error.response.data);
          enqueueSnackbar(error.response.data.error, { variant: 'error' });
        } else {
          devLog('Error adding achievement:', error);
          enqueueSnackbar(error, { variant: 'error' });
        }
      });
  };

  const handleDelete = (achievementId) => {
    axios.delete(`${BACKEND_URL}/deleteAchievement/${achievementId}`, { withCredentials: true })
      .then(response => {
        const updatedAchievements = achievements.filter(a => a.id !== achievementId);
        setAchievements(updatedAchievements);
        enqueueSnackbar('Achievement Deleted.', { variant: 'success' });
      })
      .catch(error => {
        devLog('Error deleting achievement:', error);
        enqueueSnackbar(error, { variant: 'error' });
      });
  };

  return (
    <section className={styles.container}>
      <h2 className={styles.title}>Personal Achievements</h2>
      <ul className={styles.list}>
        {achievements.length === 0 && <li className={styles.noAchievements}>No achievements added</li>}
        {achievements.map(achievement => (
          <li className={styles.listItem} key={achievement.id}>
            <span className={styles.achievementTitle}>{achievement.description}</span><span className={styles.achievementDate}>{achievement.updated ? new Date(achievement.updated).toLocaleDateString() : new Date(achievement.created).toLocaleDateString()}</span>
            <button className={styles.actionButton} onClick={() => handleDelete(achievement.id)}>Delete</button>
          </li>
        ))}
      </ul>
      <div className={styles.addAchievementArea}>
        <input
          type="text"
          value={newAchievement}
          onChange={e => setNewAchievement(e.target.value)}
          className={styles.styledInput}
          placeholder="Add new achievement here"
        />
        <button className={styles.actionButton} onClick={handleAdd}>Add</button>
      </div>
    </section>
  );
};

export default Achievements;
