import React from 'react';
import styles from './Counters.module.css';
import ReportCounter from './ReportCounter';
import HappyCustomers from './HappyCustomers';

const Counters = () => {
    return (
        <div className={styles.countersContainer}>
            <ReportCounter />
            <HappyCustomers />
        </div>
    );
}

export default Counters;
