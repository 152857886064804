import React, { useState } from 'react';
import LogoutComponent from './LogoutComponent';
import styles from './LoginComponent.module.css';
import { useUser } from '../../contexts/UserContext';

/**
 * Login Component.
 * 
 * Allows a user to log in by providing username and password fields. The server returns a cookie.
 * It uses UserContext for the actual business logic.
 * 
 */
function LoginComponent() {

    const [credentials, setCredentials] = useState({
        email: '',
        password: ''
    });
    const [showMobileLoginFields, setShowMobileLoginFields] = useState(false);


    const { user, login } = useUser();

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setCredentials(prevState => ({
            ...prevState,
            [name]: value
        }));
    }

    const handleLoginClick = async () => {
        login(credentials);
    };

    const isLoggedIn = user && user.id;

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            handleLoginClick();
        }
    }

    if (isLoggedIn) {
        return (
            <div className={styles.loginContainer}>
                <div className={styles.logoutContainer}>
                    <LogoutComponent />
                </div>
            </div>
        );
    } else {
        return (
            <div className={styles.loginContainer}>

                <button
                    className={`${styles.loginLink} ${showMobileLoginFields ? styles.hideFields : styles.showFields}`}
                    onClick={() => setShowMobileLoginFields(true)}
                >
                    Login
                </button>

                <div className={`${showMobileLoginFields ? styles.showFields : styles.hideFields}`}>
                    <input className={styles.inputField}
                        name="email"
                        id="email"
                        value={credentials.email}
                        onChange={handleInputChange}
                        onKeyPress={handleKeyPress}
                        placeholder="Email"
                        autoComplete="email" 
                    />
                    <input className={styles.inputField}
                        name="password"
                        type="password"
                        value={credentials.password}
                        onChange={handleInputChange}
                        onKeyPress={handleKeyPress}
                        placeholder="Password"
                    />
                    <button className={styles.loginButton} onClick={handleLoginClick}>Login</button>
                </div>
            </div>
        );
    };
}

export default LoginComponent;
