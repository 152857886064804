import React, { useState, useEffect } from 'react';
import styles from './LeaderBoard.module.css';
import { useSnackbar } from 'notistack';
import axios from 'axios';
import { formatTimestampShort, countThousands } from '../helpers/HelperFunctions';
import { Helmet } from 'react-helmet';

function LeaderBoard({ limit }) {
    const [leaderBoard, setLeaderBoard] = useState([]);
    const { enqueueSnackbar } = useSnackbar();
    let index = 1;

    const BACKEND_URL = process.env.REACT_APP_BACKEND_URL || 'http://localhost:8080';

    useEffect(() => {
        const url = limit ? `${BACKEND_URL}/leaderboard?limit=${limit}` : `${BACKEND_URL}/leaderboard`;
        axios.get(url)
            .then(response => {
                setLeaderBoard(response.data || []);
            })
            .catch(error => {
                console.error('Error fetching blogs:', error);
                setLeaderBoard([])
                enqueueSnackbar(`Error fetching leaderboard: ${error}`, { variant: 'error' });
            });

    }, []);

    return (
        <main className={styles.container}>
            <Helmet>
                <title>Mushroom Game Leaderboard</title>
                <meta name="description" content="Shows the Mushroom Game top scorers on a leaderboard" />
                <meta name="keywords" content="leaderboard, mushroom game leaderboard, game ranking, game top score" />
            </Helmet>
            <h1 className={styles.title}>Mushroom&apos;s Adventure - Top 10 Players</h1>
            <p className={styles.paragraph}>
                Check out the achievement of the best players. Don&apos;t forget to keep attending to Mushroom and you too could be on this board.
            </p>
            <table className={styles.table}>
                <thead>
                    <tr className={styles.tableRow}>
                        <th className={styles.tableHeader}>Rank</th>
                        <th className={styles.tableHeader}>Name</th>
                        <th className={styles.tableHeader}>Score</th>
                        <th className={styles.tableHeader}>Level</th>
                        <th className={styles.tableHeader}>Date</th>
                    </tr>
                </thead>
                <tbody>
                    {leaderBoard
                        .slice(0, limit)
                        .map(lb => (
                            <React.Fragment key={lb.id}>
                                {/* Traditional table row for larger screens */}
                                <tr className={styles.tableRow}>
                                    <td className={styles.tableCell}>{index}</td>
                                    <td className={styles.tableCell}>{lb.name}</td>
                                    <td className={styles.tableCell}>{lb.score}</td>
                                    <td className={styles.tableCell}>{countThousands(lb.score)}</td>
                                    <td className={styles.tableCell}>{formatTimestampShort(lb.created)}</td>
                                </tr>

                                {/* Card for smaller screens */}
                                <div className={styles.card}>
                                    <div className={styles.cardItem}><strong>Rank:</strong> {index++}</div>
                                    <div className={styles.cardItem}><strong>Name:</strong> {lb.name}</div>
                                    <div className={styles.cardItem}><strong>Score:</strong> {lb.score}</div>
                                    <div className={styles.cardItem}><strong>Level:</strong> {countThousands(lb.score)}</div>
                                    <div className={styles.cardItem}><strong>Date:</strong> {formatTimestampShort(lb.created)}</div>
                                </div>
                            </React.Fragment>
                        ))
                    }
                </tbody>
            </table>
        </main>
    );
};

export default LeaderBoard;
