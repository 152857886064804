import React, { useState, useEffect } from 'react';
import styles from './QuestionGame.module.css';
import { useUser } from '../contexts/UserContext';
import axios from 'axios';
import { useSnackbar } from 'notistack';
import { GAME_BRONZE_MUSHROOM_REWARD } from '../helpers/Constants';
import { devLog, sendAuditLog } from '../helpers/HelperFunctions'
import { ACTION_TYPE_CLICK_BUTTON, ENTITY_ID_QUESTION_GAME_RESPONSE, ENTITY_ID_QUESTION_GAME_ERROR, ENTITY_TYPE_QUESTION_GAME } from '../helpers/Constants';

const mockQuestions = [
    { question: 'Is cardio important for weight loss?', answer: 'Yes' },
    { question: 'Can you gain muscle while losing fat?', answer: 'Yes' },
    { question: 'Is yoga a replacement for weight training?', answer: 'No' },
    { question: 'Is it recommended to drink at least 8 glasses of water a day?', answer: 'Yes' },
    { question: 'Is walking considered a low-impact exercise?', answer: 'Yes' },
    { question: 'Can eating late at night lead to weight gain?', answer: 'Yes' },
    { question: 'Should you always stretch before exercising?', answer: 'Yes' },
    { question: 'Is yoga good for improving flexibility?', answer: 'Yes' },
    { question: 'Can smoking lead to lung cancer?', answer: 'Yes' },
    { question: 'Do avocados contain good fats?', answer: 'Yes' },
    { question: 'Is it healthy to eat fried foods regularly?', answer: 'No' },
    { question: 'Can too much sugar lead to diabetes?', answer: 'Yes' },
    { question: 'Is swimming a full-body workout?', answer: 'Yes' },
    { question: 'Can you target fat loss in specific areas of your body through exercise?', answer: 'No' },
    { question: 'Is it okay to skip breakfast?', answer: 'No' },
    { question: 'Do you need a gym membership to stay fit?', answer: 'No' },
    { question: 'Can poor sleep lead to weight gain?', answer: 'Yes' },
    { question: 'Is it possible to be overweight and still be fit?', answer: 'Yes' },
    { question: 'Is green tea good for weight loss?', answer: 'Yes' },
    { question: 'Is it necessary to take supplements for a balanced diet?', answer: 'No' },
    { question: 'Can stress lead to weight gain?', answer: 'Yes' },
    { question: 'Is it healthy to consume processed foods?', answer: 'No' },
    { question: 'Is it recommended to eat five portions of fruit and vegetables a day?', answer: 'Yes' },
    { question: 'Is it safe to do cardio every day?', answer: 'Yes' },
    { question: 'Can protein shakes replace meals?', answer: 'No' },
    { question: 'Is it necessary to change workout routines regularly?', answer: 'Yes' },
    { question: 'Can weightlifting cause high blood pressure?', answer: 'No' },
    { question: 'Is it beneficial to work out twice a day?', answer: 'No' },
    { question: 'Does caffeine before a workout improve performance?', answer: 'Yes' },
    { question: 'Is it okay to work out on an empty stomach?', answer: 'Yes' },
    { question: 'Does lifting weights stunt growth in teenagers?', answer: 'No' },
    { question: 'Is running on a treadmill as effective as running outside?', answer: 'No' },
    { question: 'Does swimming build muscle?', answer: 'Yes' },
    { question: 'Is it okay to eat junk food if you exercise regularly?', answer: 'No' },
    { question: 'Is it advisable to take a multivitamin every day?', answer: 'No' },
    { question: 'Do you need to drink sports drinks during exercise?', answer: 'No' },
    { question: 'Is it possible to be fit but not healthy?', answer: 'Yes' },
    { question: 'Do crunches help you lose belly fat?', answer: 'No' },
    { question: 'Is it okay to do strength training every day?', answer: 'No' },
    { question: 'Is it important to have a rest day in your workout routine?', answer: 'Yes' },
    { question: 'Does sweating more mean you are burning more calories?', answer: 'No' },
    { question: 'Is it necessary to eat protein after a workout?', answer: 'Yes' },
    { question: 'Is it beneficial to take short breaks during cardio?', answer: 'Yes' },
    { question: 'Is it important to consult a doctor before starting a new workout routine?', answer: 'Yes' },
    { question: 'Can yoga help you lose weight?', answer: 'Yes' },
    { question: 'Does walking 10,000 steps a day promote weight loss?', answer: 'Yes' },
    { question: 'Is cycling better than running for knee health?', answer: 'Yes' },
    { question: 'Does exercise improve mental health?', answer: 'Yes' },
    { question: 'Is it possible to be healthy on a vegetarian diet?', answer: 'Yes' },
    { question: 'Is white meat healthier than red meat?', answer: 'Yes' },
    { question: 'Is it better to work out in the morning?', answer: 'No' },
    { question: 'Does sugar cause hyperactivity?', answer: 'No' },
    { question: 'Is it harmful to skip meals?', answer: 'Yes' }
];


function QuestionGame() {
    const [currentQuestion, setCurrentQuestion] = useState(null);
    const [userAnswer, setUserAnswer] = useState(null);
    const [correctAnswer, setCorrectAnswer] = useState(null);
    const [token, setToken] = useState('');
    const { user } = useUser();
    const [showNextQuestion, setShowNextQuestion] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const BACKEND_URL = process.env.REACT_APP_BACKEND_URL || 'http://localhost:8080';


    useEffect(() => {
        if (showNextQuestion || !currentQuestion) {
            const randomIndex = Math.floor(Math.random() * mockQuestions.length);
            const selectedQuestion = mockQuestions[randomIndex];
            setCurrentQuestion(selectedQuestion);
            setUserAnswer(null);
            setCorrectAnswer(null);
            setShowNextQuestion(false);
        }
    }, [showNextQuestion]);

    useEffect(() => {
        loadNextQuestion();
    }, []);

    const loadNextQuestion = () => {
        axios.post(`${BACKEND_URL}/token`, user, { withCredentials: true })
            .then(response => {
                devLog('Lifestyle Tips - Server Response: ', response.data);
                setToken(response.data);
            })
            .catch(error => {
                if (error.response) {
                    enqueueSnackbar(`Error: ${error.response.data.error || error.response.status}`, { variant: 'error' });
                } else if (error.request) {
                    enqueueSnackbar('Error: No response from server.', { variant: 'error' });
                } else {
                    enqueueSnackbar(`Error: ${error.message}`, { variant: 'error' });
                }
            });

        const randomIndex = Math.floor(Math.random() * mockQuestions.length);
        const selectedQuestion = mockQuestions[randomIndex];
        setCurrentQuestion(selectedQuestion);
        setUserAnswer(null);
        setCorrectAnswer(null);
    }

    const handleAnswer = async (answer) => {
        setUserAnswer(answer);
        setCorrectAnswer(currentQuestion.answer);

        if (user && (currentQuestion.answer === answer)) {

            const collectible = {
                user_id: user.id,
                bronze_mushrooms: GAME_BRONZE_MUSHROOM_REWARD,
                silver_mushrooms: '',
                gold_mushrooms: '',
                token_id: token.token_id
            }

            if (user.id) {

                await axios.put(`${BACKEND_URL}/collectible`, collectible, { withCredentials: true })
                    .then(response => {
                        devLog('Server Response: ', response.data)
                        sendAuditLog(user.id, ACTION_TYPE_CLICK_BUTTON, ENTITY_TYPE_QUESTION_GAME, ENTITY_ID_QUESTION_GAME_RESPONSE, null);
                        user.bronze_mushrooms += response.data.bronze_mushrooms;
                        user.silver_mushrooms += response.data.silver_mushrooms;
                        user.gold_mushrooms += response.data.gold_mushrooms;
                        enqueueSnackbar('You earned 1 bronze mushroom!', { variant: 'success' });
                    })
                    .catch(error => {
                        sendAuditLog(user.id, ACTION_TYPE_CLICK_BUTTON, ENTITY_TYPE_QUESTION_GAME, ENTITY_ID_QUESTION_GAME_ERROR, null);
                        console.error('Error updating collectibles:', error);
                    });
                    
            }
        }

        setTimeout(() => {
            loadNextQuestion();
        }, 5000);
    };

    return (
        <div className={styles.container}>
            <h2 className={styles.title}>Quick Question!</h2>
            <p className={styles.description}>Test your knowledge on health and fitness. Select &apos;Yes&apos; or &apos;No&apos; below:</p>
            {currentQuestion && <p className={styles.questionText}>{currentQuestion.question}</p>}
            {userAnswer ? (
                <>
                    <p className={styles.correctAnswer}>Correct answer: {correctAnswer}</p>
                </>
            ) : (
                <>
                    <button className={styles.answerButton} onClick={() => handleAnswer('Yes')}>Yes</button>
                    <button className={styles.answerButton} onClick={() => handleAnswer('No')}>No</button>
                </>
            )}
        </div>
    );
}

export default QuestionGame;
