import React from 'react';
import styles from './LogoutComponent.module.css';
import { useUser } from '../../contexts/UserContext';

/**
 * Logout Component.
 * 
 * Provides a Logout button which allows a user to log out by deleting the cookie.
 * It uses UserContext for the actual business logic.
 * 
 */
function LogoutComponent() {
    const { logout } = useUser();

    return (
        <button className={styles.styledButton} onClick={logout}>Logout</button>
    );
}

export default LogoutComponent;

