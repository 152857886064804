import React, { useState, useEffect } from 'react';
import styles from './UserStarFeedback.module.css';
import config from '../../config';
import { useSnackbar } from 'notistack';
import axios from 'axios';
import { devLog } from '../../helpers/HelperFunctions';

const UserStarFeedback = ({ pageId }) => {
    const [rating, setRating] = useState(0);
    const [hasFeedback, setHasFeedback] = useState(false);
    const { enqueueSnackbar } = useSnackbar();

    // Generate a key for the current page using the pageId
    const pageFeedbackKey = `${config.feedbackSubmitted}_${pageId}`;

    const BACKEND_URL = process.env.REACT_APP_BACKEND_URL || 'http://localhost:8080';

    useEffect(() => {
        // On component mount, check if feedback has been provided for this page
        const feedbackGiven = localStorage.getItem(pageFeedbackKey);
        if (feedbackGiven) {
            setHasFeedback(true);
            setRating(+feedbackGiven);  // Convert to number and set
        }
    }, [pageFeedbackKey]);

    const handleStarClick = (value) => {
        if (hasFeedback) return;  // Don't allow changing feedback if already given

        setRating(value);
    };

    const handleSubmit = () => {

        const feedback = {
            reply: '',
            source: ''
        }

        if (rating > 0) {
            devLog("Feedback rating:", rating);

            feedback.source = pageId;
            feedback.reply = (rating).toString();

            axios.post(`${BACKEND_URL}/feedback`, feedback, { withCredentials: true })
                .then(response => {
                    localStorage.setItem(pageFeedbackKey, "true");
                    setHasFeedback(true);
                    enqueueSnackbar('Thanks for the feedback!', { variant: 'success' });
                })
                .catch(error => {
                    if (error.response) {
                        enqueueSnackbar(`Error: ${error.response.data.error || error.response.status}`, { variant: 'error' });
                    } else if (error.request) {
                        enqueueSnackbar('Error: No response from server.', { variant: 'error' });
                    } else {
                        enqueueSnackbar(`Error: ${error.message}`, { variant: 'error' });
                    }
                });
        }
    };

    return (
        <div className={styles.feedbackContainer}>
            <p className={styles.feedbackText}>How would you rate this page?</p>
            {[1, 2, 3, 4, 5].map((value) => (
                <span
                    key={value}
                    onClick={() => handleStarClick(value)}
                    className={`${styles.star} ${rating >= value ? styles.active : ''}`}
                >
                    {rating >= value ? '★' : '☆'}
                </span>


            ))}
            <div>
                <button className={styles.submitButton} onClick={handleSubmit}>Submit</button>
            </div>
        </div>
    );
}

export default UserStarFeedback;
