import React, { useState, useEffect } from 'react';
import { useBasket } from '../../contexts/BasketContext';
import styles from './Checkout.module.css';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import { devLog } from '../../helpers/HelperFunctions';
import { useUser } from '../../contexts/UserContext';
import CountrySelect from '../common/CountrySelect';
import TitleSelect from '../common/TitleSelect';
import { Helmet } from 'react-helmet';

const Checkout = () => {
    const { clearBasket, isBasketEmpty, placeOrder, updateOrder, basketItems } = useBasket();
    const CLIENT_ID = process.env.REACT_APP_PAYPAL_CLIENT_ID;
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const { user } = useUser();
    const [selectedCountry, setSelectedCountry] = useState('');
    const [selectedTitle, setSelectedTitle] = useState('');

    const [userInfo, setUserInfo] = useState({
        title: '',
        name: '',
        email: '',
        phone_number: '',
        house_number: '',
        street: '',
        city_town: '',
        county: '',
        country: '',
        postcode: ''
    });

    const [errors, setErrors] = useState({
        name: '',
        email: '',
        phone_number: '',
        house_number: '',
        street: '',
        city_town: '',
        county: '',
        country: '',
        postcode: ''
    });

    //const [isFormValid, setIsFormValid] = useState(false);
    const [isFormSubmitted, setIsFormSubmitted] = useState(false);

    const checkAllFieldsValid = () => {
        return (
            validateTitle(userInfo.title) &&
            validateName(userInfo.name) &&
            validateEmail(userInfo.email) &&
            validatePhoneNumber(userInfo.phone_number) &&
            validateHouseNumber(userInfo.house_number) &&
            validateStreet(userInfo.street) &&
            validateCityTown(userInfo.city_town) &&
            validatePostcode(userInfo.postcode) &&
            validateCounty(userInfo.county) &&
            validateCountry(userInfo.country)
        );
    };

    useEffect(() => {
        var isValid = checkAllFieldsValid();
        console.log('isValid: ', isValid)
        //setIsFormValid(isValid);
    }, [userInfo]);

    useEffect(() => {
        setUserInfo(prevState => ({
            ...prevState,
            'country': selectedCountry
        }));
    }, [selectedCountry]);

    useEffect(() => {
        setUserInfo(prevState => ({
            ...prevState,
            'title': selectedTitle
        }));
    }, [selectedTitle]);


    const handlePaypalSuccess = (details, data) => {
        devLog('>> handlePaymentSuccess');
        devLog('handlePaypalSuccess details: ', details);
        devLog('handlePaypalSuccess data: ', data);
        
        updateOrder('paid', details.orderID, details.payerID, details.paymentID, details.paymentSource);

        const databaseUpdateSuccess = true; // Assume database update is successful; replace with real logic
        
        if (databaseUpdateSuccess) {
            // Step 5: Clear the basket
            clearBasket();

            // Step 6: Navigate to a confirmation page
            navigate('/shop/checkout-success');
        } else {
            updateOrder('error');
            console.error('Could not complete your purchase at this time.');
            enqueueSnackbar('Could not complete your purchase at this time.', { variant: 'error' });
            navigate('/shop/checkout-failure');
        }

    };

    const handlePaypalCancel = (data) => {
        devLog('handlePaymentCancel: ', data)
        updateOrder('cancelled');
        navigate('/shop/checkout-cancel');
    }

    const handlePaypalError = (data) => {
        devLog('handlePaymentError: ', data)
        updateOrder('error');
        navigate('/shop/checkout-failure');
    }

    const [paypalLoaded, setPaypalLoaded] = useState(false);

    useEffect(() => {
        if (window.paypal) {
            setPaypalLoaded(true);
            return;
        }

        const script = document.createElement('script');
        script.src = `https://www.paypal.com/sdk/js?client-id=${CLIENT_ID}`;
        script.onload = () => {
            setPaypalLoaded(true);
        };
        document.body.appendChild(script);
    }, []); // Load PayPal SDK on component mount

    useEffect(() => {
        //if (basketItems.length > 0 && paypalLoaded && isFormSubmitted) {
        if (paypalLoaded && isFormSubmitted) {
            // Clear previous PayPal button
            const container = document.getElementById("paypal-button-container");
            container.innerHTML = "";

            window.paypal.Buttons({
                createOrder: (data, actions) => {
                    return actions.order.create({
                        purchase_units: [
                            {
                              reference_id: "d9f80740-38f0-11e8-b467-0ed5f89f718b",
                              amount: {
                                currency_code: "USD",
                                value: "100.00"
                              }
                            }
                          ],
                    });
                },
                
                onApprove: handlePaypalSuccess,
                onCancel: handlePaypalCancel,
                onError: handlePaypalError,
            }).render('#paypal-button-container');
        }
    }, [basketItems, paypalLoaded, isFormSubmitted]);



    const validateEmail = (email) => {
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

        if (!emailRegex.test(email)) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                email: "Please enter a valid email address",
            }));
            return false;
        }
        setErrors((prevErrors) => ({
            ...prevErrors,
            email: '',
        }));
        return true;
    }

    const validateTitle = (title) => {
        return true;
    }

    const validateName = (name) => {
        if (name === '') {
            setErrors((prevErrors) => ({
                ...prevErrors,
                name: 'Name is required',
            }));
            return false;
        }

        setErrors((prevErrors) => ({
            ...prevErrors,
            name: '',
        }));
        return true;
    }

    const validatePhoneNumber = (phoneNumber) => {
        return true;
    }

    const validateHouseNumber = (houseNumber) => {
        if (houseNumber === '') {
            setErrors((prevErrors) => ({
                ...prevErrors,
                house_number: 'House number is required',
            }));
            return false;
        }

        setErrors((prevErrors) => ({
            ...prevErrors,
            house_number: '',
        }));
        return true;
    }

    const validateStreet = (street) => {
        if (street === '') {
            setErrors((prevErrors) => ({
                ...prevErrors,
                street: 'Street is required',
            }));
            return false;
        }

        setErrors((prevErrors) => ({
            ...prevErrors,
            street: '',
        }));
        return true;
    }

    const validateCityTown = (cityTown) => {
        if (cityTown === '') {
            setErrors((prevErrors) => ({
                ...prevErrors,
                city_town: 'City or town is required',
            }));
            return false;
        }

        setErrors((prevErrors) => ({
            ...prevErrors,
            city_town: '',
        }));
        return true;
    }

    const validatePostcode = (postcode) => {
        if (postcode === '') {
            setErrors((prevErrors) => ({
                ...prevErrors,
                postcode: 'Postcode is required',
            }));
            return false;
        }

        setErrors((prevErrors) => ({
            ...prevErrors,
            postcode: '',
        }));
        return true;
    }

    const validateCounty = (county) => {
        return true;
    }

    const validateCountry = (country) => {
        if (country === '') {
            setErrors((prevErrors) => ({
                ...prevErrors,
                country: 'Country is required',
            }));
            return false;
        }

        setErrors((prevErrors) => ({
            ...prevErrors,
            country: '',
        }));
        return true;
    }

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        if (name === 'name') {
            setUserInfo(prevState => ({
                ...prevState,
                [name]: value
            }));
            validateName(value);
        } else if (name === 'email') {
            setUserInfo(prevState => ({
                ...prevState,
                [name]: value
            }));
            validateEmail(value);
        } else if (name === 'phone_number') {
            setUserInfo(prevState => ({
                ...prevState,
                [name]: value
            }));
            validatePhoneNumber(value);
        } else if (name === 'house_number') {
            setUserInfo(prevState => ({
                ...prevState,
                [name]: value
            }));
            validateHouseNumber(value);
        } else if (name === 'street') {
            setUserInfo(prevState => ({
                ...prevState,
                [name]: value
            }));
            validateStreet(value);
        } else if (name === 'city_town') {
            setUserInfo(prevState => ({
                ...prevState,
                [name]: value
            }));
            validateCityTown(value);
        } else if (name === 'postcode') {
            setUserInfo(prevState => ({
                ...prevState,
                [name]: value
            }));
            validatePostcode(value);
        } else if (name === 'county') {
            setUserInfo(prevState => ({
                ...prevState,
                [name]: value
            }));
            validateCounty(value);
        } else {
            setUserInfo(prevState => ({
                ...prevState,
                [name]: value
            }));
        }
    }


    const handleSubmit = async (event) => {
        event.preventDefault();

        // Step 1: Validate the form data
        if (Object.values(errors).every(x => !x)) {

            if (isBasketEmpty()) {
                enqueueSnackbar('Basket is empty', { variant: 'info' });
                return;
            }
            devLog('Shiipping details submitted:', userInfo);

            // Step 2: Place the order
            const orderId = await placeOrder(userInfo, user && user.id ? user.id : undefined);

            if (orderId) {
                setIsFormSubmitted(true);
            } else {
                devLog('Error: No order id returned');
                enqueueSnackbar('An error occured while placing an order. Please try again', { variant: 'error' });
            }
        }
    };

    return (
        <main className={styles.container}>
            <Helmet>
                <title>Checkout</title>
                <meta name="description" content="Allows you to add shipping details and pay" />
                <meta name="keywords" content="shopping basket checkout, shopping cart checkout, checkout" />
            </Helmet>

            <h1 className={styles.title}>Checkout</h1>
            <h2 className={styles.subTitle}>Delivery Details</h2>
            <form className={styles.form} onSubmit={handleSubmit}>

                <div className={styles.inputGroup}>
                    <div className={styles.styledLabel}>Title </div>
                    <TitleSelect selectedTitle={selectedTitle} setSelectedTitle={setSelectedTitle} />
                </div>

                <div className={styles.inputGroup}>
                    <div className={styles.styledLabel}>Name <span className={styles.mandatoryMarker}>*</span></div>
                    <input className={styles.styledInput}
                        name="name"
                        value={userInfo.name}
                        onChange={handleInputChange}
                        onBlur={() => validateName(userInfo.name)}
                        placeholder="Name"
                    />
                    {errors.name && <div className={styles.validationBlock}>{errors.name}</div>}
                </div>

                <div className={styles.inputGroup}>
                    <div className={styles.styledLabel}>Email <span className={styles.mandatoryMarker}>*</span></div>
                    <input className={styles.styledInput}
                        name="email"
                        value={userInfo.email}
                        onChange={handleInputChange}
                        onBlur={() => validateEmail(userInfo.email)}
                        placeholder="Email"
                    />
                    {errors.email && <div className={styles.validationBlock}>{errors.email}</div>}
                </div>

                <div className={styles.inputGroup}>
                    <div className={styles.styledLabel}>Phone Number </div>
                    <input className={styles.styledInput}
                        name="phone_number"
                        value={userInfo.phone_number}
                        onChange={handleInputChange}
                        onBlur={() => validateHouseNumber(userInfo.phone_number)}
                        placeholder="Phone Number"
                    />
                    {errors.phone_number && <div className={styles.validationBlock}>{errors.phone_number}</div>}
                </div>

                <div className={styles.inputGroup}>
                    <div className={styles.styledLabel}>House Number <span className={styles.mandatoryMarker}>*</span></div>
                    <input className={styles.styledInput}
                        name="house_number"
                        value={userInfo.house_number}
                        onChange={handleInputChange}
                        onBlur={() => validateHouseNumber(userInfo.house_number)}
                        placeholder="House Number"
                    />
                    {errors.house_number && <div className={styles.validationBlock}>{errors.house_number}</div>}
                </div>

                <div className={styles.inputGroup}>
                    <div className={styles.styledLabel}>Street <span className={styles.mandatoryMarker}>*</span></div>
                    <input className={styles.styledInput}
                        name="street"
                        value={userInfo.street}
                        onChange={handleInputChange}
                        onBlur={() => validateStreet(userInfo.street)}
                        placeholder="Street"
                    />
                    {errors.street && <div className={styles.validationBlock}>{errors.street}</div>}
                </div>

                <div className={styles.inputGroup}>
                    <div className={styles.styledLabel}>City/Town <span className={styles.mandatoryMarker}>*</span></div>
                    <input className={styles.styledInput}
                        name="city_town"
                        value={userInfo.city_town}
                        onChange={handleInputChange}
                        onBlur={() => validateCityTown(userInfo.city_town)}
                        placeholder="City"
                    />
                    {errors.city_town && <div className={styles.validationBlock}>{errors.city_town}</div>}
                </div>

                <div className={styles.inputGroup}>
                    <div className={styles.styledLabel}>Postcode <span className={styles.mandatoryMarker}>*</span></div>
                    <input className={styles.styledInput}
                        name="postcode"
                        value={userInfo.postcode}
                        onChange={handleInputChange}
                        onBlur={() => validatePostcode(userInfo.postcode)}
                        placeholder="Postcode"
                    />
                    {errors.postcode && <div className={styles.validationBlock}>{errors.postcode}</div>}
                </div>

                <div className={styles.inputGroup}>
                    <div className={styles.styledLabel}>County </div>
                    <input className={styles.styledInput}
                        name="county"
                        value={userInfo.county}
                        onChange={handleInputChange}
                        onBlur={() => validateCounty(userInfo.county)}
                        placeholder="County"
                    />
                    {errors.county && <div className={styles.validationBlock}>{errors.county}</div>}
                </div>

                <div className={styles.inputGroup}>
                    <div className={styles.styledLabel}>Country <span className={styles.mandatoryMarker}>*</span></div>
                    <CountrySelect selectedCountry={selectedCountry} setSelectedCountry={setSelectedCountry} />
                    {errors.country && <div className={styles.validationBlock}>{errors.country}</div>}
                </div>

                <button disabled={isFormSubmitted} className={styles.submitButton} type="submit">Save Shipping Details</button>
            </form>

            <div className={styles.paymentNote}>We currently only accept payments through PayPal</div>
            {isFormSubmitted && (
                <div className={styles.paypalButtonContainer} id="paypal-button-container"></div>
            )}
            <p className={styles.mandatoryNote}><span className={styles.mandatoryMarker}>*</span> indicates mandatory fields</p>
        </main>
    );
};

export default Checkout;
