import React, { useEffect, useState } from 'react';
import styles from './HappyCustomers.module.css';

const HappyCustomers = () => {
    // This value can be fetched dynamically from your backend in the future
    const totalCustomerCount = 1127;
    const [displayedCount, setDisplayedCount] = useState(0);

    useEffect(() => {
        const incrementInterval = 5;
        const step = 10; 

        if (displayedCount < totalCustomerCount) {
            const interval = setInterval(() => {
                setDisplayedCount(prevCount => Math.min(prevCount + step, totalCustomerCount));
            }, incrementInterval);

            return () => clearInterval(interval);
        }
    }, [displayedCount, totalCustomerCount]);

    return (
        <div className={styles.counterContainer}>
            <span className={styles.customerCount}>{displayedCount}</span>
            <span className={styles.description}>Happy Customers</span>
        </div>
    );
}

export default HappyCustomers;
