import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styles from './AdminAddBlog.module.css';
import axios from 'axios';
import { useSnackbar } from 'notistack';
import { devLog } from '../../helpers/HelperFunctions';
import Helmet from 'react-helmet';

const SNIPPET_LENGTH = 100; // Number of characters for the snippet

/**
 * Used by admin to add a new blog or edit an existing one to the database.
 * 
 * Note: Any css class names used in the code snippets come from index.css.
 * 
 */
const AdminAddBlogComponent = () => {
    const [title, setTitle] = useState('');
    const [slug, setSlug] = useState('');
    const [content, setContent] = useState('');
    const [topic, setTopic] = useState('');
    const [preview, setPreview] = useState('');
    const [snippet, setSnippet] = useState('');
    const [errorMessage, setErrorMessage] = useState(null);
    const [slugEdited, setSlugEdited] = useState(false);
    const [metaTitle, setMetaTitle] = useState('');
    const [metaKeywords, setMetaKeywords] = useState('');
    const [metaDescription, setMetaDescription] = useState('');
    const [author, setAuthor] = useState('');
    const [imageUrl, setImageUrl] = useState('');
    const [videoKey1, setVideoKey1] = useState('');
    const [videoKey2, setVideoKey2] = useState('');
    const [videoKey3, setVideoKey3] = useState('');
    const [videoKey4, setVideoKey4] = useState('');
    const [videoTitle1, setVideoTitle1] = useState('');
    const [videoTitle2, setVideoTitle2] = useState('');
    const [videoTitle3, setVideoTitle3] = useState('');
    const [videoTitle4, setVideoTitle4] = useState('');
    const textAreaRef = useRef(null);
    const navigate = useNavigate();
    const { id } = useParams();
    const [isPublished, setIsPublished] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const [wordCount, setWordCount] = useState(0);

    // Converts a blog title to a slug
    const stringToSlug = (str) => {
        return str
            .toLowerCase()
            .trim()                      // Remove leading and trailing whitespace
            .replace(/[^a-z0-9\s-]/g, '') // Remove non-alphanumeric chars
            .replace(/[\s-]+/g, '-');     // Replace spaces and multiple dashes with a single dash
    }

    const handleContentChange = (e) => {
        const newContent = e.target.value;
        setContent(newContent);
        setPreview(`<article>${newContent}</article>`);

        const count = e.target.value.split(/\s+/).filter(Boolean).length;
        setWordCount(count);
    };

    const BACKEND_URL = process.env.REACT_APP_BACKEND_URL || 'http://localhost:8080';

    useEffect(() => {
        if (id) {
            devLog('Fetching blog to edit');
            axios.get(`${BACKEND_URL}/blog/${id}`)
                .then(response => {
                    const fetchedBlog = response.data;

                    // Remove <article> tags before setting content
                    const cleanedContent = fetchedBlog.content.replace('<article>', '').replace('</article>', '');

                    // Populate the form fields
                    setTitle(fetchedBlog.title || '');
                    setSlug(fetchedBlog.slug || '');
                    setContent(cleanedContent);
                    setTopic(fetchedBlog.topic || '');
                    setPreview(`<article>${cleanedContent}</article>`);
                    setMetaTitle(fetchedBlog.metaTitle || '');
                    setMetaKeywords(fetchedBlog.metaKeywords || '');
                    setMetaDescription(fetchedBlog.metaDescription || '');
                    setAuthor(fetchedBlog.author || '');
                    setImageUrl(fetchedBlog.imageUrl || '');
                    setIsPublished(fetchedBlog.isPublished || false);
                    setVideoKey1(fetchedBlog.videoKey1 || '');
                    setVideoKey2(fetchedBlog.videoKey2 || '');
                    setVideoKey3(fetchedBlog.videoKey3 || '');
                    setVideoKey4(fetchedBlog.videoKey4 || '');
                    setVideoTitle1(fetchedBlog.videoTitle1 || '');
                    setVideoTitle2(fetchedBlog.videoTitle2 || '');
                    setVideoTitle3(fetchedBlog.videoTitle3 || '');
                    setVideoTitle4(fetchedBlog.videoTitle4 || '');

                    const count = cleanedContent.split(/\s+/).filter(Boolean).length;
                    setWordCount(count);
                })
                .catch(error => {
                    console.error('Error fetching blog:', error);
                });
        } else {
            setTitle('');
            setSlug('');
            setContent('');
            setTopic('');
            setMetaTitle('');
            setMetaKeywords('');
            setMetaDescription('');
            setAuthor('');
            setImageUrl('');
            setIsPublished(false);
            setVideoKey1('');
            setVideoKey2('');
            setVideoKey3('');
            setVideoKey4('');
            setVideoTitle1('');
            setVideoTitle2('');
            setVideoTitle3('');
            setVideoTitle4('');
        }
    }, [id]);

    useEffect(() => {
        // Removing HTML tags using regex
        const strippedContent = content.replace(/<\/?[^>]+(>|$)/g, "");

        // Get the snippet from the stripped content
        const newSnippet = createSnippet(strippedContent);

        setSnippet(newSnippet);
    }, [content]);

    const createSnippet = (content) => {
        let strippedContent = content.replace(/(<([^>]+)>)/gi, ""); // Stripping HTML tags
        if (strippedContent.length <= SNIPPET_LENGTH) {
            return strippedContent; // Return content if it's shorter than the snippet length
        }

        let trimmedContent = strippedContent.substring(0, SNIPPET_LENGTH);
        let lastSpaceIndex = trimmedContent.lastIndexOf(" ");

        if (lastSpaceIndex > 0) {
            // Cut the snippet at the last space and append '...'
            return strippedContent.substring(0, lastSpaceIndex) + '...';
        } else {
            // If no space found, return the snippet with '...' anyway
            return trimmedContent + '...';
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setErrorMessage(null);


        if (!title || !content || !topic) {
            setErrorMessage("Title, content and topic are mandatory!");
            return;
        }

        const articleContent = `<article>${content}</article>`;

        const blog = {
            title,
            slug,
            content: articleContent,
            snippet,
            topic,
            metaTitle,
            metaKeywords,
            metaDescription,
            author,
            imageUrl,
            isPublished,
            videoKey1,
            videoKey2,
            videoKey3,
            videoKey4,
            videoTitle1,
            videoTitle2,
            videoTitle3,
            videoTitle4
        };

        try {
            devLog('Sending to server: ', blog)
            devLog('The ID: ', id)
            /*if (id) {
                await axios.put(`${BACKEND_URL}/admin/updateBlog/${id}`, blog, { withCredentials: true })
                    .then(response => {
                        enqueueSnackbar('Blog Updated Successfully!', { variant: 'success' });
                        navigate('/admin/list-blogs')
                    })
                    .catch(error => {
                        if (error.response) {
                            enqueueSnackbar(`Error: ${error.response.data.error || error.response.status}`, { variant: 'error' });
                        } else if (error.request) {
                            enqueueSnackbar('Error: No response from server.', { variant: 'error' });
                        } else {
                            enqueueSnackbar(`Error: ${error.message}`, { variant: 'error' });
                        }
                    });
            } else {
                await axios.post(`${BACKEND_URL}/admin/addBlog`, blog, { withCredentials: true })
                    .then(response => {
                        enqueueSnackbar('Blog Created Successfully!', { variant: 'success' });
                        navigate('/admin/list-blogs')
                    })
                    .catch(error => {
                        if (error.response) {
                            enqueueSnackbar(`Error: ${error.response.data.error || error.response.status}`, { variant: 'error' });
                        } else if (error.request) {
                            enqueueSnackbar('Error: No response from server.', { variant: 'error' });
                        } else {
                            enqueueSnackbar(`Error: ${error.message}`, { variant: 'error' });
                        }
                    });
            }*/

            if (id) {
                // Use PUT for updating an existing blog
                console.log('PUT')
                await axios.put(`${BACKEND_URL}/admin/upsertBlog/${id}`, blog, { withCredentials: true })
                    .then(response => {
                        enqueueSnackbar('Blog Updated Successfully!', { variant: 'success' });
                        navigate('/admin/list-blogs');
                    })
                    .catch(error => {
                        if (error.response) {
                            enqueueSnackbar(`Error: ${error.response.data.error || error.response.status}`, { variant: 'error' });
                        } else if (error.request) {
                            enqueueSnackbar('Error: No response from server.', { variant: 'error' });
                        } else {
                            enqueueSnackbar(`Error: ${error.message}`, { variant: 'error' });
                        }
                    });
            } else {
                console.log('POST')
                // Use POST for creating a new blog
                await axios.post(`${BACKEND_URL}/admin/upsertBlog`, blog, { withCredentials: true })
                    .then(response => {
                        enqueueSnackbar('Blog Created Successfully!', { variant: 'success' });
                        navigate('/admin/list-blogs');
                    })
                    .catch(error => {
                        if (error.response) {
                            enqueueSnackbar(`Error: ${error.response.data.error || error.response.status}`, { variant: 'error' });
                        } else if (error.request) {
                            enqueueSnackbar('Error: No response from server.', { variant: 'error' });
                        } else {
                            enqueueSnackbar(`Error: ${error.message}`, { variant: 'error' });
                        }
                    });
            }

        } catch (error) {
            console.error("Error processing blog", error);
        }
    };

    // Insert a html tag and place the cursor in the middle of it
    const insertTag = (openingTag, closingTag = '') => {
        if (textAreaRef.current) {
            const start = textAreaRef.current.selectionStart;
            const end = textAreaRef.current.selectionEnd;
            const prevValue = content;
            const toInsert = openingTag + prevValue.substring(start, end) + closingTag;
            const newValue = prevValue.substring(0, start) + toInsert + prevValue.substring(end);

            // Update the content state
            setContent(newValue);

            // Update the cursor position after inserting the tag
            setTimeout(() => {
                textAreaRef.current.focus();
                const newCursorPos = start + openingTag.length;
                textAreaRef.current.selectionStart = newCursorPos;
                textAreaRef.current.selectionEnd = newCursorPos;
            }, 0);
        }
    };


    const handleGoBack = () => {
        navigate('/admin/list-blogs');
    };

    const handleClearForm = () => {
        setTitle('');
        setSlug('');
        setContent('');
        setTopic('');
        setSnippet('');
        setPreview('');
        setMetaTitle('');
        setMetaKeywords('');
        setMetaDescription('');
        setAuthor('');
        setImageUrl('');
        setIsPublished(false);
        setVideoKey1('');
        setVideoKey2('');
        setVideoKey3('');
        setVideoKey4('');
        setVideoTitle1('');
        setVideoTitle2('');
        setVideoTitle3('');
        setVideoTitle4('');
    }

    // Handle slug changing
    useEffect(() => {
        if (!slugEdited) {
            setSlug(stringToSlug(title));
        }
    }, [title]);

    const [showDropdown, setShowDropdown] = useState(false);
    // Creates the image html snippet.
    const handleInsertImage = (size) => {
        let className;
        switch (size) {
            case 'sml':
                className = 'blog-imageSmall';
                break;
            case 'med':
                className = 'blog-imageMedium';
                break;
            case 'lge':
                className = 'blog-imageLarge';
                break;
            default:
                className = '';
        }

        const tag = `<div class="blog-imageContainer">
                        <a href="${process.env.PUBLIC_URL}/assets/blog/ImagePlaceholder.jpg" data-lightbox="UNIQUE_IMAGE_NAME" data-title="IMAGE_TITLE">
                          <img src="${process.env.PUBLIC_URL}/assets/blog/ImagePlaceholder.jpg" alt="blog image" class="blog-alignLeft ${className}" />
                        </a>
                        <p>Your text goes here</p>
                        <div class="blog-imageTitleCenter">IMAGE_TITLE - change to correct alignment</div>
                    </div>`;

        insertTag(tag);
        setShowDropdown(false);
    };

    return (
        <main className={styles.container}>
            <Helmet>
                <title>Admin Add/Edit Blog</title>
                <meta name="description" content="Add and edit blog" />
                <meta name="keywords" content="blogs, add blog, edit blog" />
            </Helmet>

            <h1 className={styles.title}>Admin Add/Edit Blog</h1>

            {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
            <form className={styles.form} onSubmit={handleSubmit}>
                <div className={styles.formField}>
                    <label className={styles.label}>Title (H1): *</label>
                    <input className={styles.input}
                        type="text"
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                        required
                    />
                </div>
                <div className={styles.formField}>
                    <label className={styles.label}>Slug:</label>
                    <input className={styles.input}
                        type="text"
                        value={slug}
                        onChange={(e) => {
                            setSlug(e.target.value);
                            setSlugEdited(true);
                        }}
                        required
                        disabled // Don't want to be able to edit this as it causes problems 
                    />
                </div>
                <div className={styles.formField}>
                    <button type="button" className={styles.button} onClick={() => insertTag('<p class="blog-textAlignLeft">', '</p>')}>Paragraph</button>
                    <button type="button" className={styles.button} onClick={() => insertTag('<strong>', '</strong>')}>Bold</button>
                    <button type="button" className={styles.button} onClick={() => insertTag('<em>', '</em>')}>Italic</button>
                    <button type="button" className={styles.button} onClick={() => insertTag('<s>', '</s>')}>Strikethrough</button>
                    <button type="button" className={styles.button} onClick={() => insertTag('<a class="blog-styledLink" href="">', '</a>')}>Hyperlink</button>
                    <button type="button" className={styles.button} onClick={() => insertTag('<blockquote>', '</blockquote>')}>Blockquote</button>
                    <button type="button" className={styles.button} onClick={() => insertTag('<ul>\n<li>', '</li>\n</ul>')}>Unordered List</button>
                    <button type="button" className={styles.button} onClick={() => insertTag('<ol>\n<li>', '</li>\n</ol>')}>Ordered List</button>


                    <div className={styles.secondBtnRow}>
                        <button type="button" className={styles.button} onClick={() => setShowDropdown(!showDropdown)}>Image</button>
                        <button type="button" className={styles.button} onClick={() => insertTag('<h2>', '</h2>')}>Heading 2</button>
                        <button type="button" className={styles.button} onClick={() => insertTag('<h3>', '</h3>')}>Heading 3</button>
                        <button type="button" className={styles.button} onClick={() => insertTag('<div>', '</div>')}>Div</button>
                        <button type="button" className={styles.button} onClick={() => insertTag('<span>', '</span>')}>Span</button>

                        {showDropdown && (
                            <div className={styles.dropdown}>
                                <button onClick={() => handleInsertImage('sml')}>Small</button>
                                <button onClick={() => handleInsertImage('med')}>Medium</button>
                                <button onClick={() => handleInsertImage('lge')}>Large</button>
                            </div>
                        )}
                    </div>
                </div>
                <div className={styles.formField}>
                    <label className={styles.label}>Content (Word Count: {wordCount}): *</label>
                    <textarea className={styles.textarea}
                        ref={textAreaRef}
                        value={content}
                        onChange={handleContentChange}
                        required
                    />
                </div>

                <div className={styles.formField}>
                    <label className={styles.label}>HTML Preview:</label>
                    <div className={styles.previewArea} dangerouslySetInnerHTML={{ __html: preview }}></div>
                </div>

                <div className={styles.formField}>
                    <label className={styles.label}>Snippet Preview:</label>
                    <div className={styles.previewArea} dangerouslySetInnerHTML={{ __html: snippet }}></div>
                </div>

                <div className={styles.formField}>
                    <label className={styles.label}>Topic: *</label>
                    <select className={styles.select}
                        value={topic}
                        onChange={(e) => setTopic(e.target.value)}
                    >
                        <option value="" disabled>Choose a topic</option>
                        <option value="Breast Cancer Awareness">Breast Cancer Awareness</option>
                        <option value="Cancer Prevention">Cancer Prevention</option>
                        <option value="Nutrition & Diet">Nutrition & Diet</option>
                        <option value="Women's Health">Women's Health</option>
                        <option value="Self-Care">Self-Care</option>
                        <option value="Alternative & Complementary Therapies">Alternative & Complementary Therapies</option>
                        <option value="Breast Health">Breast Health</option>
                        <option value="Medical Research & Innovations">Medical Research & Innovations</option>
                        <option value="Survivor Stories">Survivor Stories</option>
                        <option value="Healthcare Navigation">Healthcare Navigation</option>
                        <option value="Digital Health & Wellness">Digital Health & Wellness</option>
                        <option value="Fitness">Fitness</option>
                        <option value="Health">Health</option>
                        <option value="Holistic Health & Wellness">Holistic Health & Wellness</option>
                        <option value="Lifestyle">Lifestyle</option>
                        <option value="Mental Awareness">Mental Awareness</option>
                        <option value="Mind">Mind</option>
                        <option value="Other">Other</option>
                    </select>
                </div>
                <div className={styles.formField}>
                    <label className={styles.label}>Meta Title:</label>
                    <input className={styles.input}
                        type="text"
                        value={metaTitle}
                        onChange={(e) => setMetaTitle(e.target.value)}
                    />
                </div>
                <div className={styles.formField}>
                    <label className={styles.label}>Meta Keywords (comma separated):</label>
                    <input className={styles.input}
                        type="text"
                        value={metaKeywords}
                        onChange={(e) => setMetaKeywords(e.target.value)}
                    />
                </div>
                <div className={styles.formField}>
                    <label className={styles.label}>Meta Description:</label>
                    <input className={styles.input}
                        type="text"
                        value={metaDescription}
                        onChange={(e) => setMetaDescription(e.target.value)}
                    />
                </div>
                <div className={styles.formField}>
                    <label className={styles.label}>Author:</label>
                    <input className={styles.input}
                        type="text"
                        value={author}
                        onChange={(e) => setAuthor(e.target.value)}
                    />
                </div>
                <div className={styles.formField}>
                    <label className={styles.label}>Featured Image URL (assets/blog/ImageName.png):</label>
                    <input className={styles.input}
                        type="text"
                        value={imageUrl}
                        onChange={(e) => setImageUrl(e.target.value)}
                    />
                </div>
                <div className={styles.formField}>
                    <label className={styles.label}>Video Key 1:</label>
                    <input className={styles.input}
                        type="text"
                        value={videoKey1}
                        onChange={(e) => setVideoKey1(e.target.value)}
                    />
                </div>
                <div className={styles.formField}>
                    <label className={styles.label}>Video Title 1:</label>
                    <input className={styles.input}
                        type="text"
                        value={videoTitle1}
                        onChange={(e) => setVideoTitle1(e.target.value)}
                    />
                </div>
                <div className={styles.formField}>
                    <label className={styles.label}>Video Key 2:</label>
                    <input className={styles.input}
                        type="text"
                        value={videoKey2}
                        onChange={(e) => setVideoKey2(e.target.value)}
                    />
                </div>
                <div className={styles.formField}>
                    <label className={styles.label}>Video Title 2:</label>
                    <input className={styles.input}
                        type="text"
                        value={videoTitle2}
                        onChange={(e) => setVideoTitle2(e.target.value)}
                    />
                </div>
                <div className={styles.formField}>
                    <label className={styles.label}>Video Key 3:</label>
                    <input className={styles.input}
                        type="text"
                        value={videoKey3}
                        onChange={(e) => setVideoKey3(e.target.value)}
                    />
                </div>
                <div className={styles.formField}>
                    <label className={styles.label}>Video Title 3:</label>
                    <input className={styles.input}
                        type="text"
                        value={videoTitle3}
                        onChange={(e) => setVideoTitle3(e.target.value)}
                    />
                </div>
                <div className={styles.formField}>
                    <label className={styles.label}>Video Key 4:</label>
                    <input className={styles.input}
                        type="text"
                        value={videoKey4}
                        onChange={(e) => setVideoKey4(e.target.value)}
                    />
                </div>
                <div className={styles.formField}>
                    <label className={styles.label}>Video Title 4:</label>
                    <input className={styles.input}
                        type="text"
                        value={videoTitle4}
                        onChange={(e) => setVideoTitle4(e.target.value)}
                    />
                </div>

                <div className={styles.buttonGroup}>
                    <button className={styles.button} type="submit">Save Blog</button>
                    <button className={styles.button} type="button" onClick={handleClearForm}>Clear Form</button>
                    <button className={styles.button} type="button" onClick={handleGoBack}>Blog List</button>
                    <div className={styles.formField}>
                        <label className={styles.checkboxLabel}>
                            <input className={styles.input}
                                type="checkbox"
                                checked={isPublished}
                                onChange={(e) => setIsPublished(e.target.checked)}
                            />
                            Publish
                        </label>
                    </div>
                </div>
            </form>
        </main>
    )
}

export default AdminAddBlogComponent;
