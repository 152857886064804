import React, { useEffect, useState, useContext } from 'react';
import styles from './HealthTips.module.css';
import axios from 'axios';
import { UserContext } from '../../contexts/UserContext';
import { devLog } from '../../helpers/HelperFunctions';

const HealthTips = () => {
  const [healthTips, setHealthTips] = useState([]);
  const { user } = useContext(UserContext);
  const BACKEND_URL = process.env.REACT_APP_BACKEND_URL || 'http://localhost:8080';

  useEffect(() => {
    axios.get(`${BACKEND_URL}/userReports/${user.id}`, { withCredentials: true })
      .then(response => {
        devLog('Retrieved user reports from server: ', response.data);
        const tips = Array.isArray(response.data) ? response.data : [];
        setHealthTips(tips);
      })
      .catch(error => {
        devLog('Error: retrieving tips', error);
        setHealthTips([]); 
      });

  }, [user.id, BACKEND_URL]);


  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Month is 0-indexed
    const year = date.getFullYear().toString().slice(-2);
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');

    return `${day}-${month}-${year} ${hours}:${minutes}`;
  };

  return (
    <section className={styles.container}>
      <h2 className={styles.title}>Lifestyle Report Health Tips &amp; Advice</h2>
      <ul className={styles.list}>
        {healthTips.length === 0
          ? <li className={styles.noTips}>No health tips stored yet. Create a lifestyle report to get some.</li>
          : healthTips.map(tip => (
            <li className={styles.listItem} key={tip.id}>
              <strong>{formatDate(tip.created)} -</strong> &nbsp;<span className={styles.advice}>{tip.advice}</span>
            </li>
          ))
        }
      </ul>
    </section>
  );
};

export default HealthTips;
