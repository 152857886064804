import React, { useState, useEffect } from 'react';
import * as tf from '@tensorflow/tfjs';
import styles from './HelloTensor.module.css';

function HelloTensor() {
    const [result, setResult] = useState(null);

    useEffect(() => {
        // Define two tensors
        const tensor1 = tf.tensor2d([[1, 2], [3, 4]]);
        const tensor2 = tf.tensor2d([[10, 20], [30, 40]]);

        // Multiply the tensors
        const output = tensor1.mul(tensor2);

        // Extract the output data and update state
        output.array().then(arrayData => {
            setResult(arrayData);
        });
    }, []); // Empty dependency array ensures this effect only runs once

    return (
        <main className={styles.container}>
            <h1>Hello Tensor!</h1>
            {result && <pre>{JSON.stringify(result, null, 2)}</pre>}
        </main>
    );
}

export default HelloTensor;
