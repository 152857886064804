import React from 'react';
import styles from './PartialReportResponse.module.css';
import { useUser } from '../../contexts/UserContext';

function PartialReportResponse({ reportData }) {
    const { user } = useUser();
   
    const titleMessage = [
        `Hello ${user && user.name}, our AI system is busy crunching the numbers to offer you personalised health tips. Sit tight, great insights are on the way! While you wait, have a look at some preliminary findings.`,
        `Hey ${user && user.name}, our smart algorithms are going the extra mile to provide you with actionable health advice. It'll just be a moment! Meanwhile, here's a sneak peek at your initial results.`,
        `Hi there ${user && user.name}, our advanced analytics are working at full throttle to decipher your answers for the best health guidance. It won't be long now! Here's something to ponder while you wait.`,
        `Greetings ${user && user.name}, our intelligent engine is diligently analysing your responses to craft the perfect wellness plan for you. Hang tight, the best is yet to come! Take a glance at some initial thoughts in the meantime.`,
        `Howdy ${user && user.name}, our AI-powered engine is currently generating personalised recommendations for you. Please bear with us for a second. Check out some initial insights while you wait!`,
        `Hi ${user && user.name}, our AI is hard at work, diving deep into your responses to offer you the most effective health tips. Just a moment more! Here are some initial findings to consider.`,
        `Welcome back ${user && user.name}, our smart engine is busy tailoring the finest health advice for you. Don't go too far, we're almost there! Here are some preliminary insights for you to explore.`,
        `What's up, ${user && user.name}? Our analytical engine is doing its magic to bring you the most fitting health advice. Hang on, we're almost done! Have a look at these initial pointers while you wait.`,
        `Heya ${user && user.name}, our intelligent system is working overtime to analyse your data for the best possible health advice. Stay tuned! In the meantime, here are some preliminary results to chew on.`,
        `Salutations ${user && user.name}, our AI is busy turning your answers into a custom-tailored health blueprint. Please hang on; we're finalising the details. For now, enjoy these initial insights.`
      ];

    const scoreExplanation = [
        "If your score is between 100-250, it means you're on the right track and making positive decisions. If it's below 100, there's room for improvement.",
        "A score under 100 suggests that you might want to take some actionable steps to improve your lifestyle. A score above 250? You're doing great!",
        "Scores below 100 are a wakeup call for a lifestyle change. Above 250, you're setting a good example!",
        "A low score indicates areas where you can improve. A high score? Keep doing what you're doing!",
        "Your score serves as a health barometer. Below 100: time to make changes. Above 250: you're on a healthy path.",
        "A high score signifies you're in tip-top shape! Low scores? Consider them a push towards healthier choices.",
        "The score is a mirror to your lifestyle. Low numbers indicate room for growth, while high numbers mean you're on a roll.",
        "Your score is a quick snapshot of your health. Keep pushing for higher numbers!",
        "If you're scoring below 100, don't worry, it's never too late to start making healthier choices.",
        "A score above 250 is excellent! Below 100 means there's significant room for improvement."
    ];

    const randomTitleMessageIndex = Math.floor(Math.random() * titleMessage.length);
    const randomTitleMessage = titleMessage[randomTitleMessageIndex];

    const randomScoreExplanationIndex = Math.floor(Math.random() * scoreExplanation.length);
    const randomScoreExplanation = scoreExplanation[randomScoreExplanationIndex];

    return (
        <div className={styles.container}>
            <h1 className={styles.title}>{randomTitleMessage}</h1>
            <h2 className={styles.score}>Your Score: {reportData.score}</h2>
            <p className={styles.summary}>{reportData.summary}</p>
            <h3>What does this score mean?</h3>
            <p>{randomScoreExplanation}</p>
        </div>
    );
};

export default PartialReportResponse;
