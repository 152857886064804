import React, { useState, useEffect } from 'react';
import styles from './Product.module.css';
import { useBasket } from '../../contexts/BasketContext';
import { useNavigate, useParams } from 'react-router-dom';
import { devLog } from '../../helpers/HelperFunctions';
import { Helmet } from 'react-helmet';
import { useSnackbar } from 'notistack';
import axios from 'axios';

const Product = () => {
  const { addToBasket } = useBasket();
  const { productName } = useParams();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const [selectedSize, setSelectedSize] = useState(null);
  const [selectedColour, setSelectedColour] = useState(null);
  const [selectedQuantity, setSelectedQuantity] = useState(0);

  const [product, setProduct] = useState(null);
  const BACKEND_URL = process.env.REACT_APP_BACKEND_URL || 'http://localhost:8080';

  useEffect(() => {
    axios.get(`${BACKEND_URL}/product/${productName}`, { withCredentials: true })
      .then(response => {
        devLog('Retrieved Product: ', response.data);
        setProduct(response.data);
      })
      .catch(error => {
        devLog('Error fetching product:', error);
        setProduct(null);
      });

  }, [productName, BACKEND_URL]);

  // TODO This is WIP. It needs looking at and not sure I even need it. 
  //const imageSource = process.env.REACT_APP_IMAGE_SOURCE;
  // It gets the url of the image from S3 in the prod 
  //const imageUrl = require('../../assets/Mushroom_gold_500.png').default;
  // environment and locally if in development.
  //
  // The backend needs to updload images to S3.
  // There is probably an aws library.
  /*let imageUrl;
  if (imageSource === 'local') {
    console.log(111);
    // Local image path (can use require, import, or a relative path)
    imageUrl = require('../../assets/Product.png').default;
    console.log('xx: ', imageUrl);
  } else if (imageSource === 's3') {
    // S3 image URL
    imageUrl = 'https://your-s3-bucket-url.com/path/to/image.png';
  } else {
    imageUrl = '';
  }
  devLog('imageUrl: ', imageUrl);*/
  // End of WIP */

  // Here you can fetch the product details based on the id
  /*const product = {
    id: productId,
    name: `Product ${productId}`,
    description: `This is product ${productId}. Its a really great product made from lots of plastic, oil and carbon.`,
    price: 19.99,
    imageUrl: '',

    size: ['XS', 'S', 'M', 'L', 'XL', '8', '10', '12', '14', '16'],
    mandatorySize: true,
    selectedSize: '',

    colour: ['White', 'Black', 'Red', 'Blue', 'Green', 'Yellow'],
    mandatoryColour: true,
    selectedColour: '',

    quantity: 0
  };

  const mushroomImage = `${process.env.PUBLIC_URL}/assets/shop/Product_${productName}.png`;
  console.log(mushroomImage);*/

  const handleProceedToBasket = async () => {
    navigate('/shop/basket');
  };

  const handleAddToBasket = (product, qty) => {
    devLog('Adding to Basket: ', product)
    
    if (product.size_mandatory && !selectedSize) {
      enqueueSnackbar('Please select size', { variant: 'warning' });
      return;
    }

    if (product.colour_mandatory && !selectedColour) {
      enqueueSnackbar('Please select colour', { variant: 'warning' });
      return;
    }

    if (selectedSize) {
      product.selectedSize = selectedSize;
      setSelectedSize(null);
    }
    if (selectedColour) {
      product.selectedColour = selectedColour;
      setSelectedColour(null);
    }

    if (selectedQuantity > 0) {
      addToBasket(product, selectedQuantity);
      setSelectedQuantity(0);
    } else {
      enqueueSnackbar('Please select quantity', { variant: 'warning' });
    }
  }

  const handleQuantityChange = (event) => {
    setSelectedQuantity(Number(event.target.value));
  };

  const increaseQuantity = () => {
    if (selectedQuantity < 9) {
      setSelectedQuantity(prevQuantity => prevQuantity + 1);
    }
  };

  const decreaseQuantity = () => {
    if (selectedQuantity > 0) {
      setSelectedQuantity(prevQuantity => prevQuantity - 1);
    }
  };

  const getUniqueSizes = () => {
    const sizes = product.variants.map(variant => variant.size);
    return [...new Set(sizes)]; // Removes duplicates
  };

  const getUniqueColours = () => {
    const colours = product.variants.map(variant => variant.colour);
    return [...new Set(colours)]; // Removes duplicates
  };

  return (

    <main className={styles.container}>
      {product && (
        <>
          <Helmet>
            <title>Product Description - {product.name}</title>
            <meta name="description" content="Describes a product available in the online shop" />
            <meta name="keywords" content="product, product description, product price" />
          </Helmet>
          <h1 className={styles.title}>{product.name}</h1>

          <img
            className={styles.productImage}
            src={product.image.url}
            alt={product.image.alt}
          />

          <p className={styles.description}>{product.description}</p>
          <p className={styles.price}>£{product.price}</p>

          {/*product.size && (
            <div>
              <strong>Size{product.mandatorySize && <span style={{ color: 'red' }}> *</span>}</strong>
              {product.size.map((s, index) => (
                <span className={styles.tooltip} key={index}>
                  <button
                    key={index}
                    className={`${styles.optionButton} ${selectedSize === s ? styles.selected : ''}`}
                    onClick={() => setSelectedSize(s)}
                  >
                    {s}
                  </button>
                  <span className={`${styles.tooltiptext}`}>{`${s}`}</span>
                </span>
              ))}
            </div>
          )*/}

          {/*product.colour && (
            <div>
              <strong>Colour{product.mandatorySize && <span style={{ color: 'red' }}> *</span>}</strong>
              {product.colour.map((c, index) => (
                <span className={styles.tooltip} key={index}>
                  <div
                    key={index}
                    style={{ backgroundColor: c }}
                    className={`${styles.colourSwatch} ${selectedColour === c ? styles.selected : ''}`}
                    onClick={() => setSelectedColour(c)}
                  />
                  <span className={`${styles.tooltiptext}`}>{`${c}`}</span>
                </span>
              ))}
            </div>
          )*/}

          {product.variants && (
            <div>
              <span className={styles.label}>Size: </span>
              {getUniqueSizes().map((size, index) => (
                <button
                  key={index}
                  className={`${styles.optionButton} ${selectedSize === size ? styles.selected : ''}`}
                  onClick={() => setSelectedSize(size)}
                >
                  {size}
                </button>
              ))}
            </div>
          )}

          {product.variants && (
            <div>
              <span className={styles.colourLabel}>Colour: </span>
              {getUniqueColours().map((colour, index) => (
                <div
                  key={index}
                  style={{ backgroundColor: colour }}
                  className={`${styles.colourSwatch} ${selectedColour === colour ? styles.selected : ''}`}
                  onClick={() => setSelectedColour(colour)}
                />
              ))}
            </div>
          )}

          <div className={styles.quantityContainer}>
            <span className={styles.label}>Quantity: {product.mandatorySize && <span style={{ color: 'red' }}> *</span>}</span>
            <div className={styles.quantityStepper}>
              <button onClick={decreaseQuantity}>-</button>
              <input
                type="number"
                min="0"
                max="10"
                value={selectedQuantity}
                onChange={handleQuantityChange}
                className={styles.quantityInput}
              />
              <button onClick={increaseQuantity}>+</button>
            </div>
          </div>

          <button className={styles.styledButton} onClick={() => handleAddToBasket(product)}>Add to Basket</button>
          <button className={styles.styledButton} onClick={() => handleProceedToBasket(product)}>Proceed to Basket</button>

          <div className={styles.mandatoryNote}>* indicates mandatory fields</div>
        </>
      )}
    </main>
  );
};

export default Product;
