import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import styles from './ReportResponse.module.css';
import { useUser } from '../../contexts/UserContext';
import { useLocation } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import { devLog } from '../../helpers/HelperFunctions';
import Helmet from 'react-helmet';

function ReportResponse() {
    const { user } = useUser();
    devLog(user)
    const location = useLocation();
    const navigate = useNavigate();
    const reportData = location.state && location.state.reportData;

    useEffect(() => {
        // If there's no reportData, navigate away on mount
        if (!reportData) {
            navigate('/'); // Redirect to home or other route
        }
    }, [reportData, navigate]);

    if (!reportData) {
        return null;
    }

    const scoreExplanation = [
        "If your score is between 100-250, it means you're on the right track and making positive decisions. If it's below 100, there's room for improvement.",
        "A score under 100 suggests that you might want to take some actionable steps to improve your lifestyle. A score above 250? You're doing great!",
        "Scores below 100 are a wakeup call for a lifestyle change. Above 250, you're setting a good example!",
        "A low score indicates areas where you can improve. A high score? Keep doing what you're doing!",
        "Your score serves as a health barometer. Below 100: time to make changes. Above 250: you're on a healthy path.",
        "A high score signifies you're in tip-top shape! Low scores? Consider them a push towards healthier choices.",
        "The score is a mirror to your lifestyle. Low numbers indicate room for growth, while high numbers mean you're on a roll.",
        "Your score is a quick snapshot of your health. Keep pushing for higher numbers!",
        "If you're scoring below 100, don't worry, it's never too late to start making healthier choices.",
        "A score above 250 is excellent! Below 100 means there's significant room for improvement."
    ];

    const endReportMessages = [
        "See the results of your hard work! Come back in a week to track your progress.",
        "Follow our advice and witness the transformation. Don't forget to check back in a week for your updated report!",
        "Ready to level up? Implement these changes and return in a week to measure the difference.",
        "Your journey to a healthier life is just beginning. Mark your calendar to return in a week for your next report.",
        "Want more frequent updates? Our monthly subscription lets you check in as often as you'd like.",
        "We're excited to be part of your wellness journey! Return in one week to find out how much you've improved.",
        "It's always good to measure your progress. Set a reminder to revisit us in a week for a fresh new report.",
        "Improvement takes time and effort. Come back in a week to see how your hard work is paying off!",
        "Don't miss out on your chance to improve your score! See you in a week for your next personalised report.",
        "Your next report could be even better! Make sure to return in a week to discover your improvements."
    ];

    const randomScoreExplanationIndex = Math.floor(Math.random() * scoreExplanation.length);
    const randomScoreExplanation = scoreExplanation[randomScoreExplanationIndex];

    const randomEndReportMessageIndex = Math.floor(Math.random() * endReportMessages.length);
    const randomEndReportMessage = endReportMessages[randomEndReportMessageIndex];

    return (
        <main className={styles.container}>
            <Helmet>
                <title>Customised Lifestyle Report Response</title>
                <meta name="description" content="Customised lifestyle report response" />
                <meta name="keywords" content="lifestyle report, health report" />

                <meta property="og:title" content="Customised Lifestyle Report" />
                <meta property="og:description" content="Your customised lifestyle report generated just for you." />
            </Helmet>

            <h1 className={styles.title}>Lifestyle Report</h1>
            <h2 className={styles.header}>Hi {user && user.name}, here&apos;s your report!</h2>
            <h3 className={styles.header}>Your Score: {reportData.score}</h3>
            <p className={styles.summary}>{reportData.summary}</p>
            <h3>What does this score mean?</h3>
            <p className={styles.randomExplanation}>{randomScoreExplanation}</p>

            {reportData.advice && reportData.advice.length > 0 && (
                <>
                    <h3>Our AI&apos;s Wisdom for Your Journey:</h3>
                    <ul className={styles.adviceList}>
                        {reportData.advice.map((adviceText, index) => (
                            <li className={styles.adviceItem} key={index}>{adviceText}</li>
                        ))}
                    </ul>
                </>
            )}
            <p>{randomEndReportMessage}</p>
            <Link className={styles.link} to="/disclaimer">* Please read disclaimer</Link>
        </main>
    );
}

export default ReportResponse;
