import React, { createContext, useContext, useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { useGameContext } from './GameContext';
import config from '../config';
import { devLog, sendAuditLog } from '../helpers/HelperFunctions';
import { ACTION_TYPE_CLICK_BUTTON, ENTITY_ID_LOGIN, ENTITY_ID_LOGIN_ERROR_UNAUTHORISED, ENTITY_ID_LOGGED_IN, ENTITY_ID_LOGOUT, ENTITY_ID_LOGGED_OUT, ENTITY_ID_ADMIN_LOGGED_IN, ENTITY_ID_LOGIN_ERROR, ENTITY_TYPE_USER } from '../helpers/Constants';

export const UserContext = createContext();

export const UserProvider = ({ children, currentPath }) => {
    const [user, setUser] = useState(null);
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();

    const BACKEND_URL = process.env.REACT_APP_BACKEND_URL || 'http://localhost:8080';

    // The initial state of Mushroom
    const initialGameState = {
        id: null,
        name: 'Mushroom',
        user_id: null,
        health: 100,
        mood: 'happy',
        score: 0,
        co2: 0.1,
        humidity: 0.1,
        satiety: 0.1,
        can_harvest: false,
        size: 'small',
        armour_class: 0,
        created: null,
        updated: null,
    };


    // Uses the game context to store state
    const { resetGame, setResetGame } = useGameContext();

    const login = async (credentials) => {

        sendAuditLog(null, ACTION_TYPE_CLICK_BUTTON, ENTITY_TYPE_USER, ENTITY_ID_LOGIN, null);

        try {
            const response = await axios.post(`${BACKEND_URL}/login`, credentials, { withCredentials: true });
            if (response.data && response.data.isLoggedIn) {
                localStorage.setItem(config.emailSubmitted, 'true');
                setIsLoggedIn(true)
                setUser(response.data.user);
                devLog('Login Response: ', response.data);
                if (response.data.user.is_admin) {
                    sendAuditLog(response.data.user.id, ACTION_TYPE_CLICK_BUTTON, ENTITY_TYPE_USER, ENTITY_ID_ADMIN_LOGGED_IN, null);
                }
                else {
                    sendAuditLog(response.data.user.id, ACTION_TYPE_CLICK_BUTTON, ENTITY_TYPE_USER, ENTITY_ID_LOGGED_IN, null);
                }
                enqueueSnackbar('Logged In Successfully!', { variant: 'success' });
                //console.log(123456);    
                //console.log('LAST LOGGED IN: ', response.data.user);

                // Create the game
                axios.post(`${BACKEND_URL}/game/createGame/${response.data.user.id}`, initialGameState, { withCredentials: true })
                    .then(response => {
                        console.log('Created game: ', response)
                    })
                    .catch(error => {
                        console.error('Error creating game: ', error);
                    });


                //navigate('/');
            } else {
                enqueueSnackbar(`Login Error: ${response.data.error}`, { variant: 'error' });
            }
        } catch (error) {
            devLog(error);
            if (error && error.response && (error.response.status === 401 || error.response.status === 404)) {
                setIsLoggedIn(false);
                sendAuditLog(null, ACTION_TYPE_CLICK_BUTTON, ENTITY_TYPE_USER, ENTITY_ID_LOGIN_ERROR_UNAUTHORISED, null);
                enqueueSnackbar('Unauthorised!', { variant: 'error' });
            } else {
                setIsLoggedIn(false);
                devLog(error.message)
                if (error && error.code === 'ERR_NETWORK') {
                    sendAuditLog(null, ACTION_TYPE_CLICK_BUTTON, ENTITY_TYPE_USER, ENTITY_ID_LOGIN_ERROR, null);
                    enqueueSnackbar('Error connecting to login server. Try again later', { variant: 'error' })
                } else {
                    sendAuditLog(null, ACTION_TYPE_CLICK_BUTTON, ENTITY_TYPE_USER, ENTITY_ID_LOGIN_ERROR, null);
                    enqueueSnackbar(`Login Error: ${error.message}`, { variant: 'error' });
                }
            };
        };
    };

    const logout = () => {
        axios.get(`${BACKEND_URL}/logout`, {
            withCredentials: true
        })
            .then(response => {
                sendAuditLog(user.id, ACTION_TYPE_CLICK_BUTTON, ENTITY_TYPE_USER, ENTITY_ID_LOGOUT, null);
                devLog('Logout Response: ', response.status)
                if (response.status === 204) {
                    setIsLoggedIn(false)
                    sendAuditLog(user.id, ACTION_TYPE_CLICK_BUTTON, ENTITY_TYPE_USER, ENTITY_ID_LOGGED_OUT, null);
                    setUser(null);
                    enqueueSnackbar('Logged Out Successfully!', { variant: 'success' });
                    devLog('Logging out so resetting game');
                    devLog('WAS: ', resetGame);
                    setResetGame(true);
                    navigate('/');
                } else {
                    setIsLoggedIn(false);
                    console.error(`Logout Error: ${response.data.error}`);
                    enqueueSnackbar(`Logout Error: ${response.data.error}`, { variant: 'error' });
                }
            })
            .catch(error => {
                setIsLoggedIn(false);
                enqueueSnackbar(`Logout Error: ${error.message}`, { variant: 'error' });
            });
    };

    const updateUser = (userInfo, messageToUser, navigateTo) => {



        axios.put(`${BACKEND_URL}/updateUser`, userInfo, { withCredentials: true })
            .then(response => {
                setUser(response.data.user);
                devLog('Update Response: ', response.data);
                if (messageToUser !== undefined && messageToUser !== '') {
                    enqueueSnackbar(messageToUser, { variant: 'success' });
                }

                if (navigateTo !== undefined && navigateTo !== '') {
                    navigate(navigateTo);
                }
            })
            .catch(error => {
                if (error.response) {
                    setIsLoggedIn(false);
                    enqueueSnackbar(`Error: ${error.response.data.error || error.response.status}`, { variant: 'error' });
                } else if (error.request) {
                    setIsLoggedIn(false);
                    enqueueSnackbar('Error: No response from server.', { variant: 'error' });
                } else {
                    setIsLoggedIn(false);
                    enqueueSnackbar(`Error: ${error.message}`, { variant: 'error' });
                }
            });
    };


    useEffect(() => {
        devLog("UserContext useEffect");

        // Skip validation for the /lifefiend route
        if (currentPath === '/lifefiend') {
            devLog("Skipping validation for /lifefiend route.");
            return;
        }

        // Make an API call to check if user is logged in
        axios.get(`${BACKEND_URL}/validate`, { withCredentials: true })
            .then(response => {
                if (response.data && response.data.isLoggedIn) {
                    devLog('User is logged in')
                    setIsLoggedIn(true)
                    setUser(response.data.user);
                    devLog('Validate Response: ', response.data);
                } else {
                    devLog('User is not logged in')
                }
            })
            .catch(error => {
                if (error.response && error.response.status === 401) {
                    setIsLoggedIn(false);
                    devLog('Anonymous user');
                } else {
                    setIsLoggedIn(false);
                    console.error('Error validating:', error);
                }
            });
        return () => {
            devLog("UserProvider unmounted.");
        };
    }, [currentPath]);

    return (
        <UserContext.Provider value={{ user, setUser, login, logout, updateUser, isLoggedIn }}>
            {children}
        </UserContext.Provider>
    );
};

UserContext.whyDidYouRender = true;

export const useUser = () => {
    return useContext(UserContext);
};
