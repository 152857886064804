import React, { useState } from 'react';
import styles from './ChatBotContent.module.css';

function ChatBotContent() {
  const [messages, setMessages] = useState([
    {
      text: "Hi there! How can I help you today?",
      sender: "bot"
    }
  ]);
  const [userMessage, setUserMessage] = useState('');

  const handleSendMessage = () => {
    if (userMessage.trim() === '') return;

    setMessages([...messages, { text: userMessage, sender: "user" }]);
    setUserMessage('');
    setTimeout(() => {
        setMessages([...messages, { sender: 'user', text: userMessage.trim() }, { sender: 'bot', text: 'Thank you for your message!' }]);
      }, 1000); // The bot replies after 1 second.
  };

  return (
    <div className={styles.container}>
      <div className={styles.messageList}>
        {messages.map((message, index) => (
          <div className={`${styles.container} ${message.sender === 'bot' ? styles.bot : styles.user}`} key={index}>
            {message.text}
          </div>
        ))}
      </div>
      <div className={styles.inputContainer}>
        <input className={styles.chatInput}
          value={userMessage}
          onChange={(e) => setUserMessage(e.target.value)}
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              handleSendMessage();
            }
          }}
        />
        <button className={styles.sendButton} onClick={handleSendMessage}>Send</button>
      </div>
    </div>
  );
}

export default ChatBotContent;
