import React, { useState } from 'react';
import sudoku from 'sudoku';
import styles from './Sudoku.module.css';

function Sudoku() {
  const [board, setBoard] = useState(generateSudokuBoard());

  function generateSudokuBoard() {
    const rawBoard = sudoku.makepuzzle();
    const formattedBoard = [];
    for (let i = 0; i < 9; i++) {
      const row = [];
      for (let j = 0; j < 9; j++) {
        const value = rawBoard[i * 9 + j];
        row.push(value === null ? '' : value + 1);
      }
      formattedBoard.push(row);
    }
    return formattedBoard;
  }

  const handleChange = (row, col, value) => {
    const newBoard = board.map(row => [...row]);
    newBoard[row][col] = value;
    setBoard(newBoard);
  };

  return (
    <div className={styles.container}>
      <h2 className={styles.title}>Sudoku</h2>
      <p className={styles.description}>Test your Sudoku skills!</p>
      <div className={styles.board}>
        {board.map((row, rowIndex) => (
          <div key={rowIndex} className={styles.row}>
            {row.map((cell, colIndex) => (
              <input
                key={colIndex}
                type="number"
                min="1"
                max="9"
                value={cell}
                onChange={(e) => handleChange(rowIndex, colIndex, e.target.value)}
                className={styles.cell}
              />
            ))}
          </div>
        ))}
      </div>
    </div>
  );
}

export default Sudoku;
