import React from 'react';
import { useSnackbar } from 'notistack';
import VoteSnackbarContent from './common/VoteSnackbarContent';
import { devLog } from '../helpers/HelperFunctions';
import Sudoku from './Sudoku';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

import Achievements from './dashboard/Achievements';
import Goals from './dashboard/Goals';


/**
 * This component is used for testing any snippets of code to see how it looks or functions.
 */
function MyComponent() {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const handleClick = () => {
        enqueueSnackbar('', {
            content: (key) => (
                <VoteSnackbarContent
                    message="Do you like the game?"
                    onThumbUp={() => {
                        // Handle thumbs up action here
                        devLog('Thumbs up!');
                        closeSnackbar(key);
                    }}
                    onThumbDown={() => {
                        // Handle thumbs down action here
                        devLog('Thumbs down!');
                        closeSnackbar(key);
                    }}
                />
            ),
            anchorOrigin: {
                vertical: 'top',
                horizontal: 'right',
            },
            preventDuplicate: true,
            autoHideDuration: 5000,
        });
    };

    const percentage = 9 * 10;

    return (
        <>
            <div>
                <Goals />
            </div>
            <div>
                <Achievements />
            </div>
            <div>
                <button onClick={handleClick}>
                    Show Feedback Snackbar
                </button>
            </div>

            <div>
                <Sudoku />
            </div>

            <div style={{ width: '100px', height: '100px' }}>
                <CircularProgressbar
                    value={percentage}
                    text={`${percentage}%`}
                    styles={buildStyles({
                        rotation: 0.75,
                        strokeLinecap: 'butt',
                        textSize: '16px',
                        pathTransitionDuration: 0.5,
                        pathColor: `rgba(62, 152, 199, ${percentage / 100})`,
                        textColor: '#f88',
                        trailColor: '#d6d6d6',
                        backgroundColor: '#3e98c7',
                    })}
                />
            </div>
        </>
    );
}

export default MyComponent;

