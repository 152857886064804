import React, { useState } from 'react';
import styles from './NewsLetterSignup.module.css';
import axios from 'axios';
import { useSnackbar } from 'notistack';
import config from '../config';
import { Helmet } from 'react-helmet';

function NewsletterSignup() {
    const [email, setEmail] = useState('');
    const { enqueueSnackbar } = useSnackbar();
    const BACKEND_URL = process.env.REACT_APP_BACKEND_URL || 'http://localhost:8080';

    const handleSubmit = async () => {
        await axios.post(`${BACKEND_URL}/email`, { email: email },  { withCredentials: true })
            .then(response => {
                localStorage.setItem(config.emailSubmitted, 'true');
                enqueueSnackbar('Thanks for signing up!', { variant: 'success' });
                setEmail('');
            })
            .catch(error => {
                if (error.response) {
                    enqueueSnackbar(`Error: ${error.response.data.error || error.response.status}`, { variant: 'error' });
                } else if (error.request) {
                    enqueueSnackbar('Error: No response from server.', { variant: 'error' });
                } else {
                    enqueueSnackbar(`Error: ${error.message}`, { variant: 'error' });
                }
            });
    };

    return (
        <main className={styles.container}>
            <Helmet>
                <title>Mind Nourish Tech Newsletter</title>
                <meta name="description" content="Alows you to sign up to the newsletter" />
                <meta name="keywords" content="lifestyle newsletter" />
            </Helmet>

            <h1 className={styles.title}><span role="img" aria-label="padlock">🔓</span> Unlock the Secrets to a Vibrant Life with Mind Nourish Tech <span role="img" aria-label="brain">🧠</span><span role="img" aria-label="lightbulb">💡</span></h1>

            <p className={styles.paragraph}>
                Are you ready to be part of a select community that&apos;s always one step ahead? With our newsletter,
                you&apos;ll not only gain access to cutting-edge insights for a better lifestyle, but also discover handpicked products that 
                align with your journey. Each recommendation is a <strong>golden key</strong> <span role="img" aria-label="key">🔑</span> tailored for your well-being, unlocking doors you didn't even know existed.
            </p>
            <p className={styles.paragraph}>
                By signing up, you&apos;re treating yourself:
            </p>
            <ul>
                <li><strong>Exclusive Discounts:</strong>Access special deals on products that align with your health and wellness goals.</li>
                <li><strong>Curated Knowledge:</strong>Dive deep into content tailored to optimise your physical and mental well-being. This isn&apos;t just any advice;
                    it&apos;s your blueprint for thriving.</li>
                <li><strong>First Look:</strong> Be ahead of the curve with updates, new game challenges <span role="img" aria-label="game controller">🎮</span>, and exclusive content from our Mushroom Survival Game <span role="img" aria-label="mushroom">🍄</span>.</li>
            </ul>
            <p className={styles.paragraph}>
                Your next breakthrough is just an email away. Are you ready to transform with us? Invest in yourself today and reap the rewards of a better tomorrow.
            </p>


            <div>
                <input className={styles.input}
                    type="email"
                    id="emailNewsletter"
                    name="emailNewsletter"
                    value={email}
                    onChange={e => setEmail(e.target.value)}
                    placeholder="Enter your email address"
                    autoComplete="email" 
                />
                <button className={styles.button} onClick={handleSubmit}>
                    Sign Up
                </button>
            </div>
        </main>
    );
}

export default NewsletterSignup;
