import React from 'react';
import styles from './Home.module.css';
import { useNavigate, Link } from 'react-router-dom';
import { useUser } from '../contexts/UserContext';
import BlogList from '../components/blogs/BlogList';
import QuestionGame from '../components/QuestionGame';
import LifestyleTips from '../components/LifestyleTips';
import GameComponent from '../components/GameComponent';
import Counters from '../components/Counters';
import Testimonial from '../components/Testimonial';
import UserFeedback from '../components/common/UserFeedback';
import { GameProvider } from '../contexts/GameContext';
import PeterAvatar from '../dynamicAssets/SelfPortrait_sm.jpg';
import ReactGA from 'react-ga4';
import Helmet from 'react-helmet';
import { sendTelegramMessage, sendAuditLog } from '../helpers/HelperFunctions';
import { ACTION_TYPE_CLICK_BUTTON, ENTITY_TYPE_HOME, ENTITY_ID_GET_LIFESTYLE_REPORT } from '../helpers/Constants';

function Home() {
    const navigate = useNavigate();
    const { user } = useUser();

    const handleGetReportClick = () => {

        sendTelegramMessage('Home - "Get Lifestyle Report" button clicked');

        const userID = user ? user.id : null;
        sendAuditLog(userID, ACTION_TYPE_CLICK_BUTTON, ENTITY_TYPE_HOME, ENTITY_ID_GET_LIFESTYLE_REPORT, null);

        ReactGA.event({
            name: 'clicked_get_your_lifestyle_report',
            params: {
                content_type: 'button'
                //another_param: 'This is used to add other info'
            }
        });

        navigate('/journey');
    };

    return (
        <>
            <main className={styles.content}>

                <Helmet>
                    <title>Unlock Your Best Self | Mind Nourish Tech</title>
                    <meta name="description" content="Discover your unique health score with Mind Nourish Tech. Harness the power of AI and personalised advice to embark on your wellness journey today." />
                    <meta name="keywords" content="lifestyle report, health score, AI wellness advice, daily wellness tips, lifestyle evaluation, health rating, personalised health advice" />
                    <meta name="robots" content="index, follow" />

                    <meta property="og:title" content="Unlock Your Best Self | Mind Nourish Tech" />
                    <meta property="og:description" content="Discover your unique health score with Mind Nourish Tech. Embark on your wellness journey with AI-driven advice today." />
                    <meta property="og:url" content="https://mindnourishtech.com" />
                    <meta property="og:type" content="website" />
                    <meta property="og:site_name" content="Peter Thomas's Official Site" />
                    <meta property="og:image" content="https://mindnourishtech.com/PinkRibbonHeart.jpg" />

                    <meta property="fb:app_id" content="1473679949921166" />

                    <meta name="twitter:card" content="summary_large_image" />
                    <meta name="twitter:title" content="Peter Thomas - Empowering Breast Cancer Awareness" />
                    <meta name="twitter:description" content="Join Peter Thomas in promoting breast cancer awareness and empowering through storytelling. Discover personalied lifestyle and health advice tailored to your journey." />
                    <meta name="twitter:image" content="https://mindnourishtech.com/PinkRibbonHeart.jpg" />
                </Helmet>

                <div className={`${styles.introContainer} ${!user ? styles.centered : ''}`}>
                    <h1 className={styles.title}><strong>Discover a Healthier You </strong> with AI-Powered Insights</h1>
                    <button className={styles.startButton} onClick={handleGetReportClick}>Get Your Free Lifestyle Report</button>
                    <p className={styles.introText}>
                        Harness the power of AI and human expertise to navigate your health and lifestyle journey.
                    </p>
                    <p className={styles.introText}>
                        Whether you&apos;re seeking daily wellness tips or a transformative lifestyle overhaul, we personalise every piece of advice for you.
                    </p>
                    <p className={styles.introText}>
                        <span role="img" aria-label="flower">🌱</span> <strong>Discover Your Unique Health Score:</strong> Understand where you stand and get clear, actionable steps to elevate your well-being.
                    </p>
                    <p className={styles.introText}>
                        <span role="img" aria-label="handshake">🤝</span> <strong>Beyond Algorithms:</strong> You&apos;re not just a user. You&apos;re part of a community enriched with genuine expertise and human connection.
                    </p>
                    <p className={styles.introText}>
                        <span role="img" aria-label="lightbulb">💡</span> <strong>Tailored Recommendations:</strong> Navigate life&apos;s challenges with bespoke advice designed around your unique lifestyle and aspirations.
                    </p>
                    <p className={styles.introText}>
                        Dive in today for free, and let Mind Nourish Tech illuminate your path to a brighter, healthier, and more fulfilled tomorrow.
                        <br /><br />
                        <Link className={styles.styledLink} to="/report-info">Why Start Your Journey with Us?</Link>
                    </p>
                </div>

                <div className={styles.sidebar}>
                    {user && <QuestionGame />}
                    {user && <LifestyleTips />}
                    {user && <UserFeedback pageId="home" />}
                </div>

                <div className={styles.sidebar}>
                    {user &&
                        <GameProvider>
                            <GameComponent />
                        </GameProvider>
                    }
                </div>

            </main>

            <div className={styles.centeredContainer}>
                <Counters />
                <BlogList limit={2} isEmbedded={true} />
            </div>

            <div className={styles.centeredContainer}>
                <Testimonial
                    data={{
                        //image: PeterAvatar,
                        name: "Gary",
                        testimony: "Mind Nourish Tech has truly transformed the way I approach health and lifestyle. Their tailored advice has made a significant impact on my daily routine!"
                    }}
                />
                <Testimonial
                    data={{
                        name: "Alisha",
                        testimony: "The lifestyle report is like my personal diary that talks back with cool tips! With the help of AI and some super smart people behind it, it's like having a friendly guide for my well-being journey. It's super fun and helpful!"
                    }}
                />
                <button className={styles.startButton} onClick={handleGetReportClick}>Embark on a Health Adventure</button>

                <UserFeedback pageId="home" />

            </div>
        </>
    )
}

export default Home
