import React, { createContext, useContext, useState, useEffect } from 'react';
import ReactPixel from 'react-facebook-pixel';

const FacebookPixelContext = createContext();

export const useFacebookPixel = () => {
    return useContext(FacebookPixelContext);
};

export const FacebookPixelProvider = ({ children }) => {
    const [isPixelInitialized, setIsPixelInitialized] = useState(false);

    useEffect(() => {
        try {
            //console.log('Starting Facebook Pixel initialization');
            const isFacebookPixelEnabled = process.env.REACT_APP_ENABLE_FACEBOOK_PIXEL === 'true';

            if (isFacebookPixelEnabled) {
                const advancedMatching = {};
                const options = {
                    autoConfig: true,
                    debug: process.env.REACT_APP_FACEBOOK_PIXEL_DEBUG,
                };

                ReactPixel.init('1245358512496965', advancedMatching, options);
                ReactPixel.pageView();
                setIsPixelInitialized(true);
                //console.log('Facebook Pixel initialised');
            }
        } catch (error) {
            console.error('Error during Facebook Pixel initialization', error);
        }
    }, []);

    return (
        <FacebookPixelContext.Provider value={isPixelInitialized}>
            {children}
        </FacebookPixelContext.Provider>
    );
};
