import React from 'react';
import Select from 'react-select';
import styles from './TitleSelect.module.css';

const titles = [
    "Mr",
    "Mrs",
    "Miss",
    "Ms",
    "Mx",
    "Sir",
    "Dr",
    "Lord",
    "Lady",
    "Prof",
    "Rev"
];

const TitleSelect = ({ selectedTitle, setSelectedTitle }) => {

    const handleTitleChange = (selectedOption) => {
        setSelectedTitle(selectedOption ? selectedOption.value : null);
    };

    const options = titles.map(title => ({ value: title, label: title }));

    const customStyles = {
        control: (provided) => ({
            ...provided,
            fontSize: '14px', 
        }),
        menu: (provided) => ({
            ...provided,
            fontSize: '14px', 
        }),
        option: (provided, state) => ({
            ...provided,
            fontSize: '14px', 
            backgroundColor: state.isSelected ? 'lightgray' : 'white',
            color: 'black',
        }),
    };

    return (
        <div className={styles.titleSelectContainer}>
            <Select
                name="title"
                value={options.find(option => option.value === selectedTitle) || null}
                onChange={handleTitleChange}
                options={options}
                styles={customStyles}
                placeholder="Select title"
                isClearable={true} 
            />
        </div>
    );
};

export default TitleSelect;
