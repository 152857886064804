import React from 'react';
import { useBasket } from '../../contexts/BasketContext';
import styles from './Basket.module.css';
import { useNavigate } from 'react-router-dom';
import { devLog } from '../../helpers/HelperFunctions';
import { Helmet } from 'react-helmet';

const Basket = () => {
    const { basketItems, removeFromBasket, basketTotal, itemCount } = useBasket();
    const navigate = useNavigate();
    /*const CLIENT_ID = process.env.REACT_APP_PAYPAL_CLIENT_ID;

    //devLog(CLIENT_ID);

    const handlePaypalSuccess = (details, data) => {
        devLog("Transaction completed by ", details.payer.name.given_name);

        // Here you can handle post-payment logic, e.g.:
        // - Update your database
        // - Clear the basket
        // - Show a confirmation message
        // - Navigate to a success page

        clearBasket();
        navigate('/shop/checkout-success');
    };

    const [paypalLoaded, setPaypalLoaded] = useState(false);

    useEffect(() => {
        if (window.paypal) {
            setPaypalLoaded(true);
            return;
        }

        const script = document.createElement('script');
        script.src = `https://www.paypal.com/sdk/js?client-id=${CLIENT_ID}`;
        script.onload = () => {
            setPaypalLoaded(true);
        };
        document.body.appendChild(script);
    }, []); // Load PayPal SDK on component mount

    useEffect(() => {
        if (basketItems.length > 0 && paypalLoaded) {
            // Render the PayPal button only if the SDK has loaded and there are items in the basket
            if (!window.paypal) return;

            // Clear previous PayPal button
            const container = document.getElementById("paypal-button-container");
            container.innerHTML = "";

            window.paypal.Buttons({
                createOrder: (data, actions) => {
                    return actions.order.create({
                        purchase_units: [{
                            amount: {
                                value: basketTotal()
                            }
                        }]
                    });
                },
                onApprove: handlePaypalSuccess
            }).render('#paypal-button-container');
        }
    }, [basketItems, paypalLoaded]);*/


    const handleCheckout = async () => {
        navigate('/shop/checkout');
    };

    devLog('Basket: ', basketItems);
    devLog('Basket Total: £', basketTotal());

    return (
        <main className={styles.container}>
            <Helmet>
                <title>Shopping Basket</title>
                <meta name="description" content="Shows the contents of the shopping basket" />
                <meta name="keywords" content="shopping basket, shopping cart" />
            </Helmet>

            <h1 className={styles.title}>Shopping Basket</h1>
            {basketItems.length === 0 ? (
                <div className={styles.emptyBasketMessage}>Your basket is empty. Add some products to continue!</div>
            ) : (
                <>
                    <div className={styles.basketHeader}>
                        <div className={styles.label}><strong>Name</strong></div>
                        <div className={styles.label}><strong>Unit Price</strong></div>
                        <div className={styles.label}><strong>Size</strong></div>
                        <div className={styles.label}><strong>Colour</strong></div>
                        <div className={styles.label}><strong>Quantity</strong></div>
                        <div className={styles.label}><strong>Total Price</strong></div>
                        <div className={styles.label}>&nbsp;</div> {/* Placeholder for remove button alignment */}
                    </div>

                    {basketItems.map((item, index) => (
                        <div className={styles.basketItem} key={index}>
                            <div className={styles.itemName}>
                                <span className={styles.inlineLabel}><strong>Name:</strong></span>
                                <span className={styles.itemDetail}>{item.product.name}</span>
                            </div>
                            <div className={styles.itemUnitPrice}>
                                <span className={styles.inlineLabel}><strong>Price:</strong></span>
                                <span className={styles.itemDetail}>£{item.product.price}</span>
                            </div>
                            <div className={styles.itemSize}>
                                <span className={styles.inlineLabel}><strong>Size:</strong></span>
                                <span className={styles.itemDetail}>{item.product.selectedSize}</span>
                            </div>
                            <div className={styles.itemColour}>
                                <span className={styles.inlineLabel}><strong>Colour:</strong></span>
                                <span className={styles.itemDetail}>{item.product.selectedColour}</span>
                            </div>
                            <div className={styles.itemQuantity}>
                                <span className={styles.inlineLabel}><strong>Quantity:</strong></span>
                                <span className={styles.itemDetail}>{item.quantity}</span>
                            </div>
                            <div className={styles.itemTotalPrice}>
                                <span className={styles.inlineLabel}><strong>Total:</strong></span>
                                <span className={styles.itemDetail}>£{item.product.price * item.quantity}</span>
                            </div>
                            <button className={styles.removeButton} onClick={() => removeFromBasket(item.product)}>Remove</button>
                        </div>
                    ))}

                    <div className={styles.basketSummary}>
                        <span className={styles.totalItems}>Total Items: {itemCount()}</span>
                        <span className={styles.totalPrice}>Total: £{basketTotal()}</span>
                    </div>
                    <button className={styles.checkoutButton} onClick={handleCheckout}>
                        Proceed to Checkout
                    </button>
                    {/*<div className={styles.paymentNote}>We currently only accept payments through PayPal.</div>
                    <div className={styles.paypalButtonContainer} id="paypal-button-container"></div>*/}
                </>
            )}
        </main>
    );
};

export default Basket;
