import React from 'react';
import styles from './YesNoModal.module.css';

function YesNoModal({ isOpen, onClose, onYes, onNo }) {
  if (!isOpen) return null;

  return (
    <div className={styles.modalOverlay}>
      <div className={styles.modal}>
        <p>Save Profile?</p>
        <div className={styles.buttons}>
          <button className={styles.button} onClick={onYes}>Yes</button>
          <button className={styles.button} onClick={onNo}>No</button>
        </div>
        <button className={`${styles.button} ${styles.closeButton}`} onClick={onClose}>✕</button>
      </div>
      
    </div>

  );
}

export default YesNoModal
