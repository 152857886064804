import React, { useState, useEffect } from 'react';
import axios from 'axios';
import styles from './EditProfile.module.css';
import { useUser } from '../../contexts/UserContext';
import { useSnackbar } from 'notistack';
import { devLog } from '../../helpers/HelperFunctions';
import YesNoModal from '../common/YesNoModal';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';

function EditProfile() {
    const [userInfo, setUserInfo] = useState({
        name: '',
        age: '',
        gender: '',
        email: '',
        weight: '',
        height: '',
        activity_level: '',
        password: ''
    });

    const { user, updateUser } = useUser();
    const [isLoading, setIsLoading] = useState(true);
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();

    const [errors, setErrors] = useState({
        name: '',
        age: '',
        email: '',
        weight: '',
        height: '',
        activity_level: '',
        password: ''
    });

    const BACKEND_URL = process.env.REACT_APP_BACKEND_URL || 'http://localhost:8080';

    useEffect(() => {
        devLog('Update User: ', user);
        if (!user || !user.id) return; // Ensure user and user's id are available before making the request

        // Fetch the user's current details when the component mounts and place in userInfo
        axios.get(`${BACKEND_URL}/user/${user.id}`, { withCredentials: true })
            .then(response => {
                devLog('Retrieved from server: ', response.data)

                const userData = response.data;

                // Transform null values to empty strings for specific fields
                const fieldsToCheck = ['age', 'weight', 'height'];
                fieldsToCheck.forEach(field => {
                    if (userData[field] === null) {
                        userData[field] = '';
                    } else if (userData[field] != null) {
                        // If not null, convert to string for controlled input compatibility
                        userData[field] = userData[field].toString();
                    }
                });

                setUserInfo(userData);
                setIsLoading(false);
            })
            .catch(error => {
                if (error.response) {
                    enqueueSnackbar(`Error: ${error.response.data.error || error.response.status}`, { variant: 'error' });
                } else if (error.request) {
                    enqueueSnackbar('Error: No response from server.', { variant: 'error' });
                } else {
                    enqueueSnackbar(`Error: ${error.message}`, { variant: 'error' });
                }
            });
    }, [user]);

    const validateAge = (age) => {
        if (isNaN(age) || age < 0) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                age: "Please enter a valid age",
            }));
            return false;
        }
        setErrors((prevErrors) => ({
            ...prevErrors,
            age: '',
        }));
        return true;
    }

    const validateEmail = (email) => {
        // Regular expression for email validation
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

        if (!emailRegex.test(email)) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                email: "Please enter a valid email address",
            }));
            return false;
        }
        setErrors((prevErrors) => ({
            ...prevErrors,
            email: '',
        }));
        return true;
    }

    const validateWeight = (weight) => {
        if (isNaN(weight) || weight < 0) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                weight: "Enter valid weight in KG"
            }));
            return false;
        }

        setErrors((prevErrors) => ({
            ...prevErrors,
            weight: ''
        }));
        return true;
    }

    const validateHeight = (height) => {
        if (isNaN(height) || height < 0) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                height: "Enter valid height in CM"
            }));
            return false;
        }

        setErrors((prevErrors) => ({
            ...prevErrors,
            height: ''
        }));
        return true;
    }

    const validatePassword = (password) => {
        // Regular expression for password validation
        const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;

        if (!passwordRegex.test(password)) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                password: "Password needs 8+ characters with an uppercase, lowercase, number, and special symbol",
            }));
            return false;
        }

        setErrors((prevErrors) => ({
            ...prevErrors,
            password: '',
        }));
        return true;
    }

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        /*let formattedValue = value;
        if ((name === 'age' || name === 'weight' || name === 'height') && value !== '') {
            console.log(111);
            formattedValue = (name === 'age' ? parseInt(value, 10) : parseFloat(value));
        } else if (value === '') {
            console.log(222);
            formattedValue = '';
        }
        setUserInfo(prevState => ({
            ...prevState,
            [name]: formattedValue
        }));*/

        if (name === 'name') {
            setUserInfo(prevState => ({
                ...prevState,
                [name]: value
            }));
            validateName(value);
        } else if (name === 'email') {
            setUserInfo(prevState => ({
                ...prevState,
                [name]: value
            }));
            validateEmail(value);
        } else if (name === 'password') {
            setUserInfo(prevState => ({
                ...prevState,
                [name]: value
            }));
            validatePassword(value);
        } else if (name === 'activity_level') {
            setUserInfo(prevState => ({
                ...prevState,
                [name]: value
            }));
            validateActivityLevel(value);
        } else {
            setUserInfo(prevState => ({
                ...prevState,
                [name]: value
            }));
        }
    }

    const validateName = (name) => {
        if (name === '') {
            setErrors((prevErrors) => ({
                ...prevErrors,
                name: 'Name is required',
            }));
            return false;
        }

        setErrors((prevErrors) => ({
            ...prevErrors,
            name: '',
        }));
        return true;
    }

    const validateActivityLevel = (name) => {
        if (name === '' || name === '-') {
            setErrors((prevErrors) => ({
                ...prevErrors,
                activity_level: 'Activity Level is required',
            }));
            return false;
        }

        setErrors((prevErrors) => ({
            ...prevErrors,
            activity_level: '',
        }));
        return true;
    }

    const [isModalOpen, setModalOpen] = useState(false);

    const handleYes = () => {

        // Convert any empty strings to null so they can bind in the backend
        const submissionData = {
            ...userInfo,
            age: userInfo.age === '' ? null : parseInt(userInfo.age, 10),
            weight: userInfo.weight === '' ? null : parseFloat(userInfo.weight),
            height: userInfo.height === '' ? null : parseFloat(userInfo.height)
        };
        devLog(submissionData);

        updateUser(submissionData, 'Profile updated!', '/');
        setModalOpen(false);
    };

    const handleNo = () => {
        setModalOpen(false);
    };

    const handleClose = () => {
        setModalOpen(false);
    };

    const handleUpdateClick = () => {
        devLog('Sending for update: ', userInfo)
        setModalOpen(true);
    }

    const handleCancelClick = () => {
        navigate('/');
    }

    const handleKeyUp = (e) => {
        if (e.key === 'Enter') {
            handleUpdateClick();
        }
    };

    return (
        <main className={styles.container}>
            <Helmet>
                <title>Edit Profile</title>
                <meta name="description" content="Edit your mind nourish profile" />
                <meta name="keywords" content="edit profile, edit mind nourish profile" />
            </Helmet>
            {isLoading ? (
                <div>Loading...</div>
            ) : (
                <>
                    <h1 className={styles.title}>
                        Edit your Mind Nourish Profile
                    </h1>

                    <div className={styles.inputGroup}>
                        <label className={styles.styledLabel}>Name <span className={styles.mandatoryMarker}>*</span></label>
                        <input className={styles.styledInput}
                            name="name"
                            type="text"
                            value={userInfo.name}
                            onChange={handleInputChange}
                            onBlur={() => validateName(userInfo.name)}
                            onKeyUp={handleKeyUp}
                            placeholder="Name"
                        />
                        {errors.name && <div className={styles.validationBlock}>{errors.name}</div>}
                    </div>
                    <div className={styles.inputGroup}>
                        <label className={styles.styledLabel}>Email <span className={styles.mandatoryMarker}>*</span></label>
                        <input className={styles.styledInput}
                            name="email"
                            type="text"
                            value={userInfo.email}
                            onChange={handleInputChange}
                            onBlur={() => validateEmail(userInfo.email)}
                            onKeyUp={handleKeyUp}
                            placeholder="Email"
                        />
                        {errors.email && <div className={styles.validationBlock}>{errors.email}</div>}
                    </div>
                    <div className={styles.inputGroup}>
                        <label className={styles.styledLabel}>Password <span className={styles.mandatoryMarker}>*</span></label>
                        <input className={styles.styledInput}
                            name="password"
                            type="password"
                            value={userInfo.password}
                            onChange={handleInputChange}
                            onBlur={() => validatePassword(userInfo.password)}
                            onKeyUp={handleKeyUp}
                            placeholder="Password"
                        />
                        {errors.password && <div className={styles.validationBlock}>{errors.password}</div>}
                    </div>
                    <div className={styles.inputGroup}>
                        <label className={styles.styledLabel}>Age</label>
                        <input className={styles.styledInput}
                            name="age"
                            type="text"
                            value={userInfo.age}
                            onChange={handleInputChange}
                            onBlur={() => validateAge(userInfo.age)}
                            onKeyUp={handleKeyUp}
                            placeholder="Age"
                        />
                        {errors.age && <div className={styles.validationBlock}>{errors.age}</div>}
                        <label className={styles.styledLabel}>Weight (kgs)</label>
                        <input className={styles.styledInput}
                            name="weight"
                            type="number"
                            step="0.01"
                            value={userInfo.weight}
                            onChange={handleInputChange}
                            onBlur={() => validateWeight(userInfo.weight)}
                            onKeyUp={handleKeyUp}
                            placeholder="Weight in KG"
                        />
                        {errors.weight && <div className={styles.validationBlock}>{errors.weight}</div>}
                        <label className={styles.styledLabel}>Height (cm)</label>
                        <input className={styles.styledInput}
                            name="height"
                            type="number"
                            step="0.01"
                            value={userInfo.height}
                            onChange={handleInputChange}
                            onBlur={() => validateHeight(userInfo.height)}
                            onKeyUp={handleKeyUp}
                            placeholder="Height in CM"
                        />
                        {errors.height && <div className={styles.validationBlock}>{errors.height}</div>}
                    </div>
                    <div className={styles.inputGroup}>
                        <label className={styles.styledLabel}>Gender</label>
                        <select className={styles.styledSelect} name="gender" value={userInfo.gender} onChange={handleInputChange}>
                            <option value="-">-</option>
                            <option value="male">Male</option>
                            <option value="female">Female</option>
                            <option value="other">Other</option>
                            <option value="prefer_not_to_say">Prefer not to say</option>
                        </select>

                        <label className={styles.styledLabel}>Activity Level <span className={styles.mandatoryMarker}>*</span></label>
                        <select className={styles.styledSelect} name="activity_level" value={userInfo.activity_level} onChange={handleInputChange} onBlur={() => validateActivityLevel(userInfo.activity_level)}>
                            <option value="-">-</option>
                            <option value="none">None</option>
                            <option value="lightly_active">Lightly Active</option>
                            <option value="moderately_active">Moderately Active</option>
                            <option value="very_active">Very Active</option>
                            <option value="super_active">Super Active</option>
                        </select>
                        {errors.activity_level && <div className={styles.validationBlock}>{errors.activity_level}</div>}
                    </div>

                    <div className={styles.inputGroup}>
                        <label className={styles.styledLabel}>Diet Preference</label>
                        <select className={styles.styledSelect} name="diet_preference" value={userInfo.diet_preference} onChange={handleInputChange}>
                            <option value="none">None</option>
                            <option value="vegetarian">Vegetarian</option>
                            <option value="vegan">Vegan</option>
                            <option value="carnivore">Carnivore</option>
                            <option value="pescatarian">Pescatarian</option>
                            <option value="omnivore">Omnivore</option>
                            <option value="paleo">Paleo</option>
                            <option value="keto">Keto</option>
                            <option value="mediterranean">Mediterranean</option>
                            <option value="low_carb">Low-Carb</option>
                            <option value="high_protein">High-Protein</option>
                            <option value="gluten_free">Gluten-Free</option>
                            <option value="halal">Halal</option>
                            <option value="kosher">Kosher</option>
                            <option value="other">Other</option>
                        </select>

                        <label className={styles.styledLabel}>Dietary Restrictions</label>
                        <select className={styles.styledSelect} name="diet_restriction" value={userInfo.diet_restriction} onChange={handleInputChange} onBlur={() => validateName(userInfo.activity_level)}>
                            <option value="none">None</option>
                            <option value="no_processed_foods">No Processed Foods</option>
                            <option value="lactose_intolerant">Lactose Intolerant</option>
                            <option value="nut_allergy">Nut Allergy</option>
                            <option value="gluten_free">Gluten Free</option>
                            <option value="shellfish_allergy">Shellfish Allergy</option>
                            <option value="soy_allergy">Soy Allergy</option>
                            <option value="egg_allergy">Egg Allergy</option>
                            <option value="fish_allergy">Fish Allergy</option>
                            <option value="wheat_allergy">Wheat Allergy</option>
                            <option value="low_sodium">Low Sodium</option>
                            <option value="diabetic">Diabetic</option>
                            <option value="other">Other</option>
                        </select>
                    </div>

                    <button className={styles.updateButton} onClick={handleUpdateClick}>Update Profile</button>
                    <button className={styles.cancelButton} onClick={handleCancelClick}>Cancel</button>
                    <YesNoModal
                        isOpen={isModalOpen}
                        onYes={handleYes}
                        onNo={handleNo}
                        onClose={handleClose}
                    />
                    <p className={styles.mandatoryNote}><span className={styles.mandatoryMarker}>*</span> indicates mandatory fields</p>
                </>
            )}
        </main>
    );
}
export default EditProfile;
