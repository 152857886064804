import React, { useEffect } from 'react';
import styles from './Dashboard.module.css';
import GraphComponent from './ProgressReportGraph';
import AchievementsComponent from './Achievements';
import GoalsComponent from './Goals';
import HealthTipsComponent from './HealthTips';
import KeyMetricsComponent from './KeyMetrics';
import { Helmet } from 'react-helmet';
import config from '../../config';
import UserFeedback from '../common/UserFeedback';

const Dashboard = () => {
  useEffect(() => {
    // Fetch data here
  }, []);

  return (
    <main className={styles.container}>
      <Helmet>
        <title>Health Dashboard</title>
        <meta name="description" content="A display of various health and lifestyle stats and report history. Allows you to set yourself some personal goals with target dates and any personal achievements you have accomplished" />
        <meta name="keywords" content="health dashboard, report history, lifestyle dashboard, personal goals, personal achievements" />
      </Helmet>

      <h1 className={styles.title}>Your Health Dashboard</h1>
      <div className={styles.dashboardSection}>
        <GraphComponent />
      </div>

      <div className={styles.dashboardSection}>
        <GoalsComponent />
      </div>

      <div className={styles.dashboardSection}>
        <AchievementsComponent />
      </div>

      <div className={styles.dashboardSection}>
        <HealthTipsComponent />
      </div>

      {config.enableDevFeatures && <div className={styles.dashboardSection}>
        <KeyMetricsComponent />
      </div>}

      <div className={styles.userFeedback}>
        <UserFeedback pageId={"dashboard"} />
      </div>
    </main>
  );
};

export default Dashboard;
