import React from 'react';
import { Link } from 'react-router-dom';
import styles from './GameInstructions.module.css';
import UserFeedback from './common/UserFeedback';
import { Helmet } from 'react-helmet';

function GameInstructions() {

    return (
        <main className={styles.container}>
            <Helmet>
                <title>Mushroom Game Instructions</title>
                <meta name="description" content="Provides the instructions for the mushroom game" />
                <meta name="keywords" content="mushroom game instructions" />
            </Helmet>

            <h1 className={styles.title}>Mushroom Game Instructions</h1>

            <p className={styles.paragraph}>
                Welcome to &quot;Mushroom&apos;s Adventure&quot;! You play the guardian of our hero, Mushroom. Your goal is to help Mushroom thrive in its environment while avoiding harmful contaminations.
            </p>

            <h2 className={styles.sectionTitle}>Movement</h2>

            <p className={styles.paragraph}>
                The mushroom moves randomly throughout the game. It&apos;s up to you to help it by using the actions available to you!
            </p>

            <h2 className={styles.sectionTitle}>Interactions</h2>

            <p className={styles.paragraph}></p>
            You have several interactions to assist the mushroom:
            <ul>
                <li><strong>Water:</strong> Helps the mushroom stay hydrated.</li>
                <li><strong>Feed:</strong> Gives the mushroom nutrition.</li>
                <li><strong>Ventilate:</strong> Reduces humidity and contamination in the area.</li>
                <li><strong>Harvest:</strong> Reap the rewards of a well-cared-for mushroom.</li>
            </ul>


            <h2 className={styles.sectionTitle}>Environment</h2>

            <p className={styles.paragraph}>
                Keep an eye on the CO2 and humidity levels. These affect the mushroom&apos;s health and your overall score. Adjust them using the interactions mentioned above.
            </p>

            <h2 className={styles.sectionTitle}>Contamination</h2>

            <p className={styles.paragraph}>
                Contaminations are the threats in the game. They move randomly and can harm the mushroom upon contact.
            </p>

            <h2 className={styles.sectionTitle}>Score and Health</h2>

            <p className={styles.paragraph}>
                As you care for the mushroom, your score increases. However, if the mushroom encounters contaminants or lacks proper care, its health can decline. Aim for a high score while keeping the mushroom alive!
            </p>

            <Link className={styles.link} to="/leader-board">Why not check out the Leader Board to see the top players.</Link>

            <UserFeedback pageId="game-instructions" />
        </main>
    );
}

export default GameInstructions;
