import React, { useState, useEffect, useRef, useContext } from 'react';
import axios from 'axios';
import { QuestionsContext } from '../../contexts/QuestionsContext';
import PartialReportResponse from './PartialReportResponse';
import { UserContext } from '../../contexts/UserContext';
import styles from './GetReport.module.css';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import config from '../../config';
import { devLog, sendTelegramMessage, sendAuditLog } from '../../helpers/HelperFunctions';
import { Helmet } from 'react-helmet';
import { RiseLoader } from 'react-spinners';
import { ACTION_TYPE_CLICK_BUTTON, ENTITY_TYPE_JOURNEY, ENTITY_ID_GENERATE_REPORT } from '../../helpers/Constants';

function GetReport() {

    const pollingAttemptCountRef = useRef(0);
    const maxReportRetrievalAttempts = config.reportRetryNoOfTimes;
    const questionsPerPage = config.questionsPerPage;
    const [loading, setLoading] = useState(false);

    const { questionsData, questionsFetched, setQuestionsData, setQuestionsFetched } = useContext(QuestionsContext);
    const { user } = useContext(UserContext);
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();

    const initialAnswers = questionsData && questionsData.questions ? questionsData.questions.map(question => ({
        question_id: question.id,
        answer: "",
        question
    })) : [];

    const [serverResponse, setServerResponse] = useState(null);
    const [serverError, setServerError] = useState(null);
    const pollingIntervalRef = useRef(null);
    const reportIdRef = useRef(null);
    const [data, setData] = useState({
        answers: initialAnswers,
        created: "2023-08-01T00:00:00Z",
        updated: "2023-08-01T00:00:00Z"
    });

    const [currentPage, setCurrentPage] = useState(1);
    const totalPages = data.answers ? Math.ceil(data.answers.length / questionsPerPage) : 0;

    const [reportVisible, setReportVisible] = useState(false);

    const BACKEND_URL = process.env.REACT_APP_BACKEND_URL || 'http://localhost:8080';

    const handleInputChange = (index, event) => {
        const updatedAnswers = [...data.answers];
        updatedAnswers[index].answer = event.target.value;
        setData(prevData => ({ ...prevData, answers: updatedAnswers }));
        devLog("Updated value for index:", index, "is", event.target.value);
    };

    const handleSubmit = () => {
        const answeredQuestions = data.answers.filter(answer => answer.answer && answer.answer !== '');
        const filteredData = {
            ...data,
            user: user,
            answers: answeredQuestions
        };

        sendTelegramMessage('Journey - "Generate Report" button clicked');

        const userID = user ? user.id : null;
        sendAuditLog(userID, ACTION_TYPE_CLICK_BUTTON, ENTITY_TYPE_JOURNEY, ENTITY_ID_GENERATE_REPORT, null);

        devLog("Data being sent to the server:", filteredData);
        setLoading(true);

        axios.post(`${BACKEND_URL}/response`, filteredData, {
            withCredentials: true
        })
            .then(response => {
                setServerResponse(response.data);
                setReportVisible(true);
                reportIdRef.current = response.data.id;

                pollingAttemptCountRef.current = 0; // Reset the counter
                pollingIntervalRef.current = setInterval(fetchFullReport, config.reportRetryFrequency);
            })
            .catch(error => {
                if (error.response && error.response.status === 429) {
                    setServerError(error.response.data.error);
                    setLoading(false);
                } else {
                    handleError(error);
                }
            });
    };

    const fetchFullReport = async () => {
        try {
            pollingAttemptCountRef.current++; // Increment the counter

            const response = await axios.get(`${BACKEND_URL}/fullResponse/${reportIdRef.current}`, { withCredentials: true });
            if (response.data.isComplete) {
                enqueueSnackbar('Full Report Loaded!', { variant: 'success' });
                clearInterval(pollingIntervalRef.current);

                // Clear the questions after a report is generated
                setQuestionsData(null);
                setQuestionsFetched(false);

                setLoading(false);

                // Add the report to the state and navigate to report page
                navigate('/report', { state: { reportData: response.data.report } });
            }

            // Check if maximum attempts reached
            if (pollingAttemptCountRef.current >= maxReportRetrievalAttempts) {
                clearInterval(pollingIntervalRef.current);
                setLoading(false);
                enqueueSnackbar('Max attempts reached, stopping report fetch.', { variant: 'error' });
                navigate('/report-error', { state: 'Max attempts reached, stopping report fetch.' });
            }

        } catch (error) {
            handleError(error);
            setLoading(false);
            clearInterval(pollingIntervalRef.current);
        }
    };

    const handleError = error => {
        if (error.response) {
            enqueueSnackbar(`Error: ${error.response.data.error || error.response.status}`, { variant: 'error' });
        } else if (error.request) {
            enqueueSnackbar('Error: No response from server. Try again later.', { variant: 'error' });
        } else {
            enqueueSnackbar(`Error: ${error.message}`);
        }
    }

    const handleStartAgain = () => {
        // Reload the page to clear the questions
        window.location.reload();
    };

    // Cleanup polling on unmount
    useEffect(() => {
        return () => {
            if (pollingIntervalRef.current) {
                clearInterval(pollingIntervalRef.current);
            }
        };
    }, []);

    useEffect(() => {
        if (questionsData && questionsData.questions) {
            const updatedAnswers = questionsData.questions.map(question => ({
                question_id: question.id,
                answer: "",
                question
            }));
            setData(prevData => ({ ...prevData, answers: updatedAnswers }));
        }
    }, [questionsData]);

    const getPaginatedQuestions = () => {
        const start = (currentPage - 1) * questionsPerPage;
        const end = start + questionsPerPage;
        return data.answers.slice(start, end);
    };

    // Calculate percentage completed
    const percentageCompleted = data.answers && data.answers.length > 0 ? ((currentPage - 1) * questionsPerPage / data.answers.length) * 100 : 0;

    const offset = (currentPage - 1) * questionsPerPage;

    function getRandomMessage() {
        const messages = [
            "Chatting with our AI, just a sec...",
            "Our AI is thinking... 🤖",
            "Hang tight! Our AI is on it.",
            "The AI magic is happening!",
            "Processing your data with our AI.",
            "Analysing input with artificial intelligence.",
            "Awaiting response from AI analytics.",
            "Did you know? Our AI analyses thousands of data points in seconds!",
            "Hold on! Our AI is tailoring the best advice for you.",
            "Our AI is busy crafting personalised insights!",
            "Analysing...",
            "Processing...",
            "Computing insights...",
            "Waking up the brainy bots!",
            "Our AI is brewing some wisdom!",
            "Rolling the AI dice... Let's see what comes up!"
        ];

        const randomIndex = Math.floor(Math.random() * messages.length);
        return messages[randomIndex];
    }

    return (
        <main className={styles.questionsContainer}>
            <Helmet>
                <title>Partial Lifestyle Report</title>
                <meta name="description" content="Customised partial lifestyle report driven by AI" />
                <meta name="keywords" content="ai lifestyle report, partial lifestyle report, health report" />
            </Helmet>

            {!reportVisible && <div className={styles.progressBarContainer}>
                <div className={styles.progressBarFiller} style={{ width: `${percentageCompleted}%` }}>
                    <span className={styles.progressText}>{Math.round(percentageCompleted)}%</span>
                </div>
            </div>}

            {!reportVisible && getPaginatedQuestions().map((answer, index) => {
                const questionObj = answer.question;
                return (
                    <div className={styles.questionRow} key={questionObj.id}>
                        <label className={styles.questionLabel}>
                            <span className={styles.questionText}>
                                {index + 1 + offset}. {questionObj.question}
                            </span>
                            <select className={styles.questionAnswer}
                                value={answer.answer || ''}
                                onChange={e => handleInputChange(index + offset, e)}
                            >
                                <option value="">Select an answer</option>
                                <option value={questionObj.ideal_response}>{questionObj.ideal_response}</option>
                                <option value={questionObj.neutral_response}>{questionObj.neutral_response}</option>
                                <option value={questionObj.bad_response}>{questionObj.bad_response}</option>
                            </select>
                        </label>
                    </div>
                );
            })}

            {!reportVisible && <div className={styles.paginationContainer}>
                <button className={styles.pageButton}
                    onClick={() => setCurrentPage(prev => Math.max(1, prev - 1))}
                    disabled={currentPage === 1}
                >
                    Previous
                </button>
                <div className={styles.pageIndicatorContainer}>
                    Page {currentPage} of {totalPages}
                </div>
                <button className={styles.pageButton}
                    onClick={() => setCurrentPage(prev => Math.min(totalPages, prev + 1))}
                    disabled={currentPage === totalPages}
                >
                    Next
                </button>
            </div>}

            <div className={styles.buttonGroup}>
                {!reportVisible && <button className={styles.generateReportButton} disabled={!user || !questionsFetched} onClick={handleSubmit}>Generate Report</button>}
                {!reportVisible && <button className={styles.startAgainButton} onClick={handleStartAgain}>Start Again</button>}
            </div>

            {!user && <p className={styles.infoMessage}>Please log in to access the questions and reveal your personalised report.</p>}

            <div className={styles.serverResponse}>
                {loading &&
                    <span className={styles.spinnerContainer}>
                        <RiseLoader color="#4A90E2" loading={loading} size={30} />
                        <p className={styles.randomMessage}>{getRandomMessage()}</p>
                    </span>
                }
                {serverResponse !== null && serverError == null && <PartialReportResponse reportData={serverResponse} />}
                {serverError && (
                    <div className={styles.serverErrorMessage}>
                        <p>{serverError}</p>
                    </div>
                )}
            </div>

        </main>
    );
};

export default GetReport;
