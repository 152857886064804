import axios from 'axios';

/**
 * Takes a timestamp and calculates how many whole day have elapsed since.
 * 
 * @param {timestamp} timestamp 
 * @returns The number of whole days since the timestamp.
 */
function daysSince(timestamp) {
    if (!timestamp) {
        return 0;
    }
    const then = new Date(timestamp);
    const now = new Date();

    // Set the time of both dates to midnight to only calculate full days
    then.setHours(0, 0, 0, 0);
    now.setHours(0, 0, 0, 0);

    const oneDay = 24 * 60 * 60 * 1000; // Number of milliseconds in one day

    const daysElapsed = Math.round((now - then) / oneDay);

    return daysElapsed;
}

/**
 * Formats a timestamp into DD/MM/YY HH:MM:SS
 * 
 * @param {timestamp} timestamp 
 * @returns The formatted timestamp 
 */
function formatTimestamp(timestamp) {
    if (!timestamp) {  // Checks for null, undefined, and empty string
        return 'Unknown';
    }

    const date = new Date(timestamp);

    // Extract date parts
    const year = String(date.getFullYear()).slice(-2);  // Get the last 2 digits of the year
    const month = String(date.getMonth() + 1).padStart(2, '0');  // months are 0-based in JS
    const day = String(date.getDate()).padStart(2, '0');

    // Extract time parts
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');

    // Combine into desired format
    return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
}

/**
 * Formats a timestamp into DD/MM/YY
 * 
 * @param {timestamp} timestamp 
 * @returns The formatted timestamp 
 */
function formatTimestampShort(timestamp) {
    if (!timestamp) {  // Checks for null, undefined, and empty string
        return 'Unknown';
    }

    const date = new Date(timestamp);

    // Extract date parts
    const year = String(date.getFullYear()).slice(-2);  // Get the last 2 digits of the year
    const month = String(date.getMonth() + 1).padStart(2, '0');  // months are 0-based in JS
    const day = String(date.getDate()).padStart(2, '0');

    // Combine into desired format
    return `${day}/${month}/${year}`;
}

function boolToString(boolValue) {
    if (typeof boolValue !== 'boolean') {
        throw new Error('The provided value is not a boolean.');
    }
    return boolValue ? 'True' : 'False';
}

/**
 * Capitalises the first letter of a string.
 * 
 * @param {string} str 
 * @returns Capitalised string
 */
function capitaliseFirstLetter(str) {
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
}

/**
 * Takes a users score and calculates their level. 0 to 99 == level 0, 1000 to 1999 == lvl 1, 2000 to 2999 == lvl 2 etc.
 * 
 * @param {number} num 
 * @returns The level of the user
 */
function countThousands(num) {
    return Math.floor(num / 1000);
}

/**
 * Takes two numbers and comes up with a random number in between them.
 * 
 * @param {number} min 
 * @param {number} max 
 * @returns a random number in between min and max.
 */
function getRandomInt(min, max) {
    // Make sure min and max are integers
    min = Math.ceil(min);
    max = Math.floor(max);

    return Math.floor(Math.random() * (max - min + 1)) + min;
}

/**
 * Overrides console.log to only execute if not in production.
 * 
 * @param  {...any} args 
 */
const devLog = (...args) => {
    if (process.env.NODE_ENV !== 'production') {
        console.log(...args);
    }
};

/**
 * Overrides console.log so it can log in production.
 * 
 * @param  {...any} args 
 */
const prodLog = (...args) => {
    console.log(...args);
};

/**
 * Sends a message to telegram.
 * 
 * @param {string} message 
 */
const sendTelegramMessage = async (message = 'Default message') => {
    const telegram_message = {
        message: message
    };

    if (process.env.REACT_APP_ENV === 'production') {
        const BACKEND_URL = process.env.REACT_APP_BACKEND_URL || 'http://localhost:8080';

        try {
            axios.post(`${BACKEND_URL}/message`, telegram_message, { withCredentials: true })
        } catch (error) {
            prodLog("Error sending telegram message:", error);
        }

    } else {
        devLog('Dev env so not sending Telegram');
    }
}

/**
 * Sends an audit log message.
 * Gathers user agent and ip address.
 * 
 * @param {string} message 
 */
const sendAuditLog = async (userID, actionType, entityType, entityID, description) => {
    const userAgent = navigator.userAgent;
    const BACKEND_URL = process.env.REACT_APP_BACKEND_URL || 'http://localhost:8080';

    let ipAddress = '';
    try {
        const ipResponse = await fetch('https://api.ipify.org?format=json');
        const ipData = await ipResponse.json();
        ipAddress = ipData.ip;
    } catch (error) {
        devLog("Error fetching IP address:", error);
    }

    // Construct audit data
    const auditData = {
        userID,
        actionType,
        entityType,
        entityID,
        description,
        userAgent,
        ipAddress,
    };

    devLog('Audit: ', auditData);

    try {
        await axios.post(`${BACKEND_URL}/audit`, auditData, { withCredentials: true });
    } catch (error) {
        prodLog("Error sending audit log:", error);
    }
};

/**
 * Converts snake-case strings to capitalised.
 * 
 * @param {string} string 
 * @returns 
 */
function toCapitalisedWords(string) {
    if (string == null || typeof string !== 'string') {
        return '';
    }
    
    return string
        .split('_')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
        .join(' ');
}


export {
    daysSince,
    formatTimestamp,
    formatTimestampShort,
    capitaliseFirstLetter,
    boolToString,
    countThousands,
    devLog,
    prodLog,
    getRandomInt,
    sendTelegramMessage,
    sendAuditLog,
    toCapitalisedWords
};
