import React, { useState, useEffect } from 'react';
import styles from './AdminListComments.module.css';
import axios from 'axios';
import { useSnackbar } from 'notistack';
import { devLog } from '../../helpers/HelperFunctions';
import Helmet from 'react-helmet';

/**
 * Used by admin to list all the comments. 
 */
const AdminListCommentsComponent = () => {
    const [comments, setComments] = useState([]);
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const BACKEND_URL = process.env.REACT_APP_BACKEND_URL || 'http://localhost:8080';

    useEffect(() => {
        const fetchComments = async () => {
            try {
                const response = await axios.get(`${BACKEND_URL}/admin/comments`, { withCredentials: true })
                devLog('Retrieved from Server: ', response.data);
                setComments(response.data);
            } catch (error) {
                console.error("Error fetching comments", error);
            }
        };

        fetchComments();
    }, []);

    const comment = {
        id: ''
    }

    const handleApprove = async (commentId) => {
        comment.id = commentId;

        try {
            const response = await axios.put(`${BACKEND_URL}/admin/updateComment`, comment, { withCredentials: true });
            const updatedComment = response.data;
            devLog('Retrieved from server: ', updatedComment);

            // Update comments list
            setComments(prevComments => prevComments.map(c => c.id === updatedComment.id ? updatedComment : c));

            enqueueSnackbar('Comment Updated Successfully!', { variant: 'success' });
        } catch (error) {
            let errorMessage = 'Error: An unexpected error occurred.';
            if (error.response && error.response.data && error.response.data.error) {
                errorMessage = `Error: ${error.response.data.error}`;
            } else if (error.response && error.response.status) {
                errorMessage = `Error: ${error.response.status}`;
            } else if (error.message) {
                errorMessage = `Error: ${error.message}`;
            }
            enqueueSnackbar(errorMessage, { variant: 'error' });
            console.error("Error updating comment", error);
        }
    };



    const askForDeletionConfirmation = (commentId) => {

        enqueueSnackbar('Are you sure you want to delete this comment?', {
            variant: 'warning',
            action: (key) => {

                const handleDelete = () => {
                    try {
                        axios.delete(`${BACKEND_URL}/admin/deleteComment/${commentId}`, { withCredentials: true })
                            .then(response => {
                                setComments(prevComments => prevComments.filter(comment => comment.id !== commentId));
                                enqueueSnackbar('Comment Deleted Successfully!', { variant: 'success' });
                                closeSnackbar(key);
                            })
                            .catch(error => {
                                if (error.response) {
                                    enqueueSnackbar(`Error: ${error.response.data.error || error.response.status}`, { variant: 'error' });
                                } else if (error.request) {
                                    enqueueSnackbar('Error: No response from server.', { variant: 'error' });
                                } else {
                                    enqueueSnackbar(`Error: ${error.message}`, { variant: 'error' });
                                }
                            });
                    } catch (error) {
                        console.error("Error deleting comment", error);
                    }
                };

                return (
                    <>
                        <button className={styles.actionButton} onClick={() => handleDelete(key, commentId)}>Delete</button>
                        <button className={styles.actionButton} onClick={() => closeSnackbar(key)}>Cancel</button>
                    </>
                );
            },
        });
    }

    return (
        <main className={styles.container}>
            <Helmet>
                <title>Admin List Comments</title>
                <meta name="description" content="List of all comments" />
                <meta name="keywords" content="comments, list comments, admin list comments" />
            </Helmet>

            <h1 className={styles.title}>Admin List Comments</h1>

            <div className={styles.commentHeader}>
                <span className={styles.commentHeaderTitle}>Text</span>
                <span className={styles.commentHeaderAttrib}>Created</span>
                <span className={styles.commentHeaderAttrib}>Updated</span>
                <span className={styles.commentHeaderAttrib}>Approved</span>
                <span className={styles.commentHeaderAttrib}>&nbsp;</span>
            </div>
            {Array.isArray(comments) && comments.map(comment => (
                <div className={styles.commentItem} key={comment.id}>
                    <span className={styles.commentTitle}>{comment.text}</span>
                    <span className={styles.commentAttrib}>{comment.created ? new Date(comment.created).toLocaleDateString() : new Date(comment.created).toLocaleDateString()}</span>
                    <span className={styles.commentAttrib}>{comment.updated ? new Date(comment.updated).toLocaleDateString() : null}</span>
                    <span className={styles.commentAttrib}>{comment.isApproved ? 'Yes' : 'No'}</span>
                    <div>
                        <button className={styles.actionButton} onClick={() => askForDeletionConfirmation(comment.id)}>Delete</button>
                        <button className={styles.actionButton} onClick={() => handleApprove(comment.id)}>Approve</button>
                    </div>
                </div>
            ))}
        </main>
    );
};

export default AdminListCommentsComponent;
