import React, { useState, useEffect } from 'react';
import styles from './Blog.module.css';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import Helmet from 'react-helmet';
import UserStarFeedback from '../common/UserStarFeedback';
import { useUser } from '../../contexts/UserContext';
import { devLog } from '../../helpers/HelperFunctions';
import CommentsList from './CommentsList';
import CommentForm from './CommentForm';
import { useSnackbar } from 'notistack';
import VideoWrapper from '../media/VideoWrapper';

/**
 * Blog Component.
 * 
 * Used to display one blog. It retrieves a blog (and its comments) from the server based on its blog slug.
 * 
 */
const Blog = () => {
  const [blog, setBlog] = useState({
    id: "",
    title: "",
    content: "",
    metaTitle: "",
    metaKeywords: "",
    metaDescription: "",
    author: "",
    slug: "",
    imageUrl: "",
    videoKey1: "",
    videoKey2: "",
    videoKey3: "",
    videoKey4: "",
    created: "",
    updated: ""
  });
  const { slug } = useParams();
  const { user } = useUser();
  const [comments, setComments] = useState([]);
  const { enqueueSnackbar } = useSnackbar();

  const BACKEND_URL = process.env.REACT_APP_BACKEND_URL || 'http://localhost:8080';

  useEffect(() => {
    axios.get(`${BACKEND_URL}/blogBySlug/${slug}`)
      .then(response => {
        devLog('Retrieved from server :', response.data)
        setBlog(response.data)
        setComments(response.data.comments)
      })
      .catch(error => {
        console.error('Error fetching blog:', error);
      });
  }, [slug]);

  const displayAuthor = blog.author || 'Unknown';
  const displayDate = blog.updated ? new Date(blog.updated).toLocaleDateString() : new Date(blog.created).toLocaleDateString();

  const addComment = async (comment) => {
    if (user) {
      comment.blogId = blog.id;
      comment.userId = user.id;
      comment.author = user.name;

      await axios.post(`${BACKEND_URL}/addComment`, comment, { withCredentials: true })
        .then(response => {
          enqueueSnackbar('Comment Pending Approval!', { variant: 'success' });
        })
        .catch(error => {
          if (error.response) {
            enqueueSnackbar(`Error: ${error.response.data.error || error.response.status}`, { variant: 'error' });
          } else if (error.request) {
            enqueueSnackbar('Error: No response from server.', { variant: 'error' });
          } else {
            enqueueSnackbar(`Error: ${error.message}`, { variant: 'error' });
          }
        });
    }
  };

  return (
    <main className={styles.blogContainer}>
      <Helmet>
        <title>{blog.metaTitle}</title>
        <meta name="description" content={blog.metaDescription} />
        <meta name="keywords" content={blog.metaKeywords} />
        <meta name="author" content={blog.author} />
        <meta property="og:url" content={`https://mindnourishtech.com/blog-title/${blog.slug}/`} />
        <meta property="og:title" content={blog.metaTitle} />
        <meta property="og:description" content={blog.metaDescription} />
        <meta property="og:image" content={`https://mindnourishtech.com/${blog.imageUrl}`} />
        <meta property="og:type" content="article" />

        <meta property="fb:app_id" content="1473679949921166" />
      </Helmet>
      <h1 className={styles.blogTitle}>{blog.title}</h1>
      <p className={styles.blogContent} dangerouslySetInnerHTML={{ __html: blog.content }}></p>

      {blog.videoKey1 && <div>
        <VideoWrapper videoId={blog.videoKey1} />
        {blog.videoTitle1 && <p className={styles.videoTitle}>{blog.videoTitle1}</p>}
      </div>}

      {blog.videoKey2 && <div>
        <VideoWrapper videoId={blog.videoKey2} />
        {blog.videoTitle2 && <p className={styles.videoTitle}>{blog.videoTitle2}</p>}
      </div>}

      {blog.videoKey3 && <div>
        <VideoWrapper videoId={blog.videoKey3} />
        {blog.videoTitle3 && <p className={styles.videoTitle}>{blog.videoTitle3}</p>}
      </div>}

      {blog.videoKey4 && <div>
        <VideoWrapper videoId={blog.videoKey4} />
        {blog.videoTitle4 && <p className={styles.videoTitle}>{blog.videoTitle4}</p>}
      </div>}

      <UserStarFeedback pageId={blog.slug} />

      <div className={styles.blogFooter}>
        Written by {displayAuthor} | Last updated on {displayDate}
      </div>

      <div className={styles.commentSection}>
        <CommentsList comments={comments} />
        {user ? (
          <CommentForm addComment={addComment} />
          ) : (
            <p className={styles.loginPrompt}>Please <a href="/signup" className={styles.styledLink}>sign up</a> or <a href="/" className={styles.styledLink}>log in</a> to post a comment.</p>
        )}


      </div>
    </main>
  );
};

export default Blog;
