import React from 'react';
import styles from './MushroomIcon.module.css';
import Mushroom from '../../dynamicAssets/Mushroom_v1_500.png';

function MushroomIcon({ variant }) {
    let className;

    if (variant === 'small') {
        className = styles.small;
    } 

    if (variant === 'default') {
        className = styles.default;
    } 

    return (
        <img src={Mushroom} alt="Gold Mushroom" className={className} />
    );
}


export default MushroomIcon;
