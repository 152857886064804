import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function YouTubeIcon() {
    return (
        <FontAwesomeIcon icon={['fab', 'youtube']} color="#FAFAFA" size="2x" />
        
    );
}

export default YouTubeIcon;
