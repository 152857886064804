import React from 'react';
import styles from './Subscriptions.module.css';
import { useUser } from '../contexts/UserContext';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import UserFeedback from './common/UserFeedback';

function Subscriptions() {

    const { user } = useUser();
    const navigate = useNavigate();

    const handleFreeSubscribeClick = () => {
        navigate('/signup');
    };

    return (
        <main className={styles.container}>
            <Helmet>
                <title>Lifestyle Report Subscription Pricing</title>
                <meta name="description" content="Describes the various subscription packages" />
                <meta name="keywords" content="lifestyle report subscription" />
            </Helmet>

            <h1 className={styles.title}><strong>Your Wellness Passport:</strong> Pricing & Packages <span role="img" aria-label="pink bow">🎀</span></h1>

            <p className={styles.introParagraph}>
                <span role="img" aria-label="leaf">🌱</span> Welcome to Mind Nourish Tech, where we empower you with tools and resources tailored for your holistic well-being.
                Dive deep into the transformative journey of wellness <span role="img" aria-label="wellness">💆‍♂️</span> and discover offerings that elevate your experience.
                Every plan is designed with a blend of innovation and care <span role="img" aria-label="heart">❤️</span>, ensuring you receive the finest.
            </p>


            <div className={styles.pricingCardContainer}>
                <div className={styles.pricingCard}>
                    <h2 className={styles.tierTitle}>Discover with our Free Tier</h2>
                    <p className={styles.price}>£0</p>
                    <ul className={styles.featureList}>
                        <li className={styles.feature}><span role="img" aria-label="tick">✔️</span> One insightful report every week</li>
                        <li className={styles.feature}><span role="img" aria-label="tick">✔️</span> Exclusive monthly newsletters</li>
                        <li className={styles.feature}><span role="img" aria-label="tick">✔️</span> Round-the-clock basic customer support</li>
                    </ul>
                    {
                        user ? (
                            <p className={styles.note}>You&apos;ve already embarked on this journey with us!</p>
                        ) : (
                            <button className={styles.subscribeButton} onClick={handleFreeSubscribeClick}>Begin Your Adventure</button>
                        )
                    }
                </div>

                <div className={`${styles.pricingCard} ${styles.silverMonthlyPricingCard}`}>
                    <h2 className={styles.tierTitle}>Elevate with the Silver Monthly Subscription</h2>
                    <p className={`${styles.price} ${styles.priceStrike}`}>£2.99/month</p>
                    <ul className={styles.featureList}>
                        <li className={styles.feature}><span role="img" aria-label="tick">✔️</span> Tri-weekly detailed reports</li>
                        <li className={styles.feature}><span role="img" aria-label="tick">✔️</span> Earn 100 bronze mushrooms monthly</li>
                        <li className={styles.feature}><span role="img" aria-label="tick">✔️</span> Exclusive Mind Nourish Tech t-shirt</li>
                        <li className={styles.feature}><span role="img" aria-label="tick">✔️</span> Commemorative Mind Nourish Tech mug</li>
                    </ul>
                    {
                        user ? (
                            <button className={styles.subscribeButton} disabled>Anticipate the Upgrade</button>
                        ) : (
                            <>
                                <button className={styles.subscribeButton} disabled>Coming Soon</button>
                                <p className={styles.note}>Our enriched subscription is on the horizon. Stay tuned for an unparalleled experience!</p>
                            </>
                        )
                    }
                </div>

                <div className={`${styles.pricingCard} ${styles.goldMonthlyPricingCard}`}>
                    <h2 className={styles.tierTitle}>Transcend with the Gold Monthly Subscription</h2>
                    <p className={`${styles.price} ${styles.priceStrike}`}>£5.99/month</p>
                    <ul className={styles.featureList}>
                        <li className={styles.feature}><span role="img" aria-label="tick">✔️</span> Daily in-depth reports to guide you</li>
                        <li className={styles.feature}><span role="img" aria-label="tick">✔️</span> Receive 10 Gold mushrooms monthly</li>
                        <li className={styles.feature}><span role="img" aria-label="tick">✔️</span> Limited-edition Mind Nourish Tech t-shirt</li>
                        <li className={styles.feature}><span role="img" aria-label="tick">✔️</span> Premium Mind Nourish Tech mug</li>
                    </ul>
                    {
                        user ? (
                            <button className={styles.subscribeButton} disabled>Await the Pinnacle</button>
                        ) : (
                            <>
                                <button className={styles.subscribeButton} disabled>Launching Soon</button>
                                <p className={styles.note}>We&apos;re crafting a premium experience just for you. Ready for the gold standard in well-being?</p>
                            </>
                        )
                    }
                </div>
            </div>

            <p className={styles.concludingParagraph}>
                At Mind Nourish Tech, our commitment is to provide unparalleled value to our community.
                The introduction of our Silver <span role="img" aria-label="silver">🥈</span> and Gold <span role="img" aria-label="gold">🥇</span> monthly subscriptions is a testament to that promise.
                We&apos;re in the final stages of crafting these exclusive offerings. Sign up for notifications
                or follow us on our social channels to be among the first to know when these transformative plans are launched.
                The future <span role="img" aria-label="star">🌟</span> of holistic well-being awaits you.
            </p>

            <UserFeedback pageId="pricing" />

        </main>
    );
}

export default Subscriptions;
