import React from 'react';
import styles from './Disclaimer.module.css';
import { Helmet } from 'react-helmet';

function Disclaimer() {
    return (
        <main className={styles.container}>
            <Helmet>
                <title>Mind Nourish Tech: Disclaimer Information</title>
                <meta name="description" content="Disclaimer for the lifestyle report" />
                <meta name="keywords" content="lifestyle report disclaimer" />
            </Helmet>
            <h1 className={styles.title}>Important Insight</h1>

            <p className={styles.paragraph}>
                Updated: July 2024
            </p>

            <p className={styles.paragraph}>
                Imagine having access to the knowledge of qualified life and nutrition coaches right at your fingertips. That's what we provide here at Mind Nourish Tech. But, while our insights can guide you on a transformative journey, remember, we're an addition to, not a replacement for, qualified health professionals.
            </p>

            <h2 className={styles.sectionTitle}>Your Personal AI Guide</h2>

            <p className={styles.paragraph}>
                The advice and tips generated on this platform have the backing of advanced AI technology. Just think of it as an intelligent companion on your wellness journey. Yet, like any journey, sometimes it's essential to consult a map—or in this case, a healthcare professional—to ensure you're on the right path.
            </p>

            <h2 className={styles.sectionTitle}>Unlocking Knowledge, Wisely</h2>

            <p className={styles.paragraph}>
                Every piece of information here is a tool to empower and inform you. While it's exciting to explore new dimensions of wellness, it's equally important to engage with it wisely and ensure it aligns with your unique needs.
            </p>

            <h2 className={styles.sectionTitle}>Guidance, Not Gospel</h2>

            <p className={styles.paragraph}>
                We encourage you to embrace the wisdom shared here, but when in doubt, always prioritise the advice of qualified healthcare professionals. It's about complementing our insights with the best possible care for your well-being.
            </p>
        </main>
    );
};

export default Disclaimer;
