// Audit logs
export const ACTION_TYPE_CLICK_BUTTON = 'Click Button';
export const ACTION_TYPE_LOGIN = 'Login';
export const ACTION_TYPE_PAGE_VISIT = 'Page Visit';

export const ENTITY_TYPE_HOME = '/home';
export const ENTITY_TYPE_JOURNEY = '/journey';
export const ENTITY_TYPE_LIFEFIEND = '/lifefiend';
export const ENTITY_TYPE_REPORT_INSTRUCTIONS = '/report-instructions';
export const ENTITY_TYPE_SARAH = '/sarah';
export const ENTITY_TYPE_PRICING = '/pricing';
export const ENTITY_TYPE_GAME_INSTRUCTIONS = '/game-instructions';
export const ENTITY_TYPE_MUSHROOM = '/mushroom';
export const ENTITY_TYPE_COLLECTIBLES = '/collectibles';
export const ENTITY_TYPE_USER = 'User';
export const ENTITY_TYPE_LIFESTYLE_TIPS = 'Lifestyle Tips';
export const ENTITY_TYPE_QUESTION_GAME = 'Question Game';

export const ENTITY_ID_GET_LIFESTYLE_REPORT = 'Get Lifestyle Report';
export const ENTITY_ID_SIGNUP = 'Signup';
export const ENTITY_ID_GENERATE_REPORT = 'Generate Report';
export const ENTITY_ID_GET_STARTED = 'Get Started';
export const ENTITY_ID_GET_DAILY_COLLECTIBLES = 'Get Daily Collectibles';
export const ENTITY_ID_LOGIN = 'Login';
export const ENTITY_ID_LOGGED_IN = 'Logged In';
export const ENTITY_ID_ADMIN_LOGGED_IN = 'ADMIN LOGGED IN';
export const ENTITY_ID_LOGOUT = 'Logout';
export const ENTITY_ID_LOGGED_OUT = 'Logged Out';
export const ENTITY_ID_LOGIN_ERROR_UNAUTHORISED = 'LOGIN UNAUTHORISED';
export const ENTITY_ID_LOGIN_ERROR = 'Login Error';
export const ENTITY_ID_LIFESTYLE_TIPS_RESPONSE = 'Lifestyle Tips Response';
export const ENTITY_ID_QUESTION_GAME_RESPONSE = 'Question Game Response';
export const ENTITY_ID_LIFESTYLE_TIPS_ERROR = 'Lifestyle Tips Error';
export const ENTITY_ID_QUESTION_GAME_ERROR = 'Question Game Error';
export const ENTITY_ID_INSTAGRAM = 'Instagram';
export const ENTITY_ID_TIKTOK = 'TikTok';
export const ENTITY_ID_YOUTUBE = 'YouTube';
export const ENTITY_ID_FACEBOOK = 'Facebook';

// Collectibles
export const DAILY_BRONZE_MUSHROOM_REWARD = 'daily_bronze_mushroom_reward';
export const GAME_BRONZE_MUSHROOM_REWARD = 'game_bronze_mushroom_reward';
export const LOGIN_BRONZE_MUSHROOM_REWARD = 'login_bronze_mushroom_reward';
