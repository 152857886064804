import React from 'react';
import styles from './DonationsPage.module.css';
import { Helmet } from 'react-helmet';
import bitcoinQR from '../dynamicAssets/BtcQrCode.png'; 

function DonationsPage() {
    return (
        <main className= {styles.container}>
            <Helmet>
                <title>Support Mind Nourish Tech: Donations</title>
                <meta name="description" content="Support and donate to help us grow and provide even more fabulous features." />
                <meta name="keywords" content="donate, donations, support, support mind nourish tech" />
            </Helmet>

            <h1 className={styles.title}>Your Sparkle Makes Us Shine! <span role="img" aria-label="stars">✨</span></h1>

            <p className={styles.paragraph}>
                Hey there, we&apos;re so grateful you&apos;ve joined us on this transformative journey. Mind Nourish Tech is all about bringing a sprinkle of health, well-being, and tech magic to lovely folks like you. And guess what? We thrive on the love and support from our amazing community.
            </p>

            <p className={styles.paragraph}>
                Whether you&apos;ve found our tools and resources helpful, or simply adore what we stand for, consider showing some love. Your generous support will help us add even more sparkle to our platform, create dreamy features, and spread our mission to inspire and uplift.
            </p>

            <p className={styles.paragraph}>
                    If you&apos;d like to help us, please click the Donate button below:
            </p>

            <button className={styles.donateButton}>
                <form action="https://www.paypal.com/donate" method="post" target="_top">
                    <input type="hidden" name="hosted_button_id" value="P48UJ8VGHUQKL" />
                    <input type="image" src="https://www.paypalobjects.com/en_US/GB/i/btn/btn_donateCC_LG.gif" border="0" name="submit" title="PayPal - The safer, easier way to pay online!" alt="Donate with PayPal button" />
                    <img alt="" border="0" src="https://www.paypal.com/en_GB/i/scr/pixel.gif" width="1" height="1" />
                </form>
            </button>

            <p className={styles.paragraph}>
                Or, if you&apos;d prefer to support us with Bitcoin, you can send your BTC donations to the address below:
            </p>

            <div className={styles.bitcoinContainer}>
                <img className={styles.bitcoinQR} src={bitcoinQR} alt="Bitcoin Donation QR Code" />
                <p className={styles.paragraph}>
                    Please ensure you only send BTC to this address. Your support is deeply appreciated!
                </p>
            </div>

            <p className={styles.paragraph}>
                Every bit helps! Whether you choose to donate or simply share the site with your friends, know that we cherish and appreciate you. Together, let's make the world a bit more nourished, tech-savvy, and absolutely fabulous! <span role="img" aria-label="star">🌟</span>
            </p>
        </main>
    );
}

export default DonationsPage;
