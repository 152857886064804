import React, { useEffect, useState, useContext } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import styles from './ProgressReportGraph.module.css';
import { devLog } from '../../helpers/HelperFunctions';
import axios from 'axios';
import { UserContext } from '../../contexts/UserContext';
import moment from 'moment';

const CustomLegend = () => (
    <ul className={styles.customLegend}>
        <li><span className={styles.legendColourBox} style={{ backgroundColor: '#8884d8' }}></span>Date</li>
        <li><span className={styles.legendColourBox} style={{ backgroundColor: '#82ca9d' }}></span>Score</li>
    </ul>
);

const dateFormatter = (date) => {

    const dayOfMonth = moment(date).date();
    const restOfDate = moment(date).format("MMM 'YY");

    let ordinalSuffix;
    if (dayOfMonth === 1 || dayOfMonth === 21 || dayOfMonth === 31) {
        ordinalSuffix = 'st';
    } else if (dayOfMonth === 2 || dayOfMonth === 22) {
        ordinalSuffix = 'nd';
    } else if (dayOfMonth === 3 || dayOfMonth === 23) {
        ordinalSuffix = 'rd';
    } else {
        ordinalSuffix = 'th';
    }

    return `${dayOfMonth}${ordinalSuffix} ${restOfDate}`;
};

const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Month is 0-indexed
    const year = date.getFullYear().toString().slice(-2);
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');

    return `${day}-${month}-${year} ${hours}:${minutes}`;
};


const ProgressReportGraph = () => {
    const [data, setData] = useState([]);
    const { user } = useContext(UserContext);
    const BACKEND_URL = process.env.REACT_APP_BACKEND_URL || 'http://localhost:8080';

    useEffect(() => {
        axios.get(`${BACKEND_URL}/userReports/${user.id}`, { withCredentials: true })
            .then(response => {
                devLog('Retrieved user reports from server: ', response.data);
                setData(response.data);
            })
            .catch(error => {
                devLog('Error: ');
            })
    }, []);

    return (
        <div className={styles.container}>
            <h2 className={styles.title}>Lifestyle Report History</h2>
            <ResponsiveContainer width="100%" height={300}>
                <LineChart
                    width={600}
                    height={300}
                    data={data}
                    margin={{
                        top: 5, right: 30, left: 20, bottom: 5,
                    }}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis
                        dataKey="updated"
                        tickFormatter={dateFormatter}
                        label={{ value: 'Date', position: 'insideBottom', offset: -15 }}
                        tick={{
                            fontSize: '12px',
                            fontFamily: 'Arial',
                            fill: 'gray'
                        }}
                    />
                    <YAxis
                        label={{ value: 'Score', angle: -90, position: 'insideLeft', offset: -10 }}
                        tick={{
                            fontSize: '12px',
                            fontFamily: 'Arial',
                            fill: 'gray'
                        }}
                    />
                    <Tooltip
                        labelFormatter={(label) => formatDate(label)}
                        formatter={(value, name) => [value, name]}
                    />

                    <Legend content={<CustomLegend />} />
                    <Line type="monotone" dataKey="score" stroke="#8884d8" activeDot={{ r: 8 }} />
                </LineChart>
            </ResponsiveContainer>
        </div>
    );
};

export default ProgressReportGraph;
