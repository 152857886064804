import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function FacebookIcon() {
    return (
        <FontAwesomeIcon icon={['fab', 'facebook']} color="#FAFAFA" size="2x" />
    );
}

export default FacebookIcon;
