import React from 'react';
import styles from './CommentsList.module.css';

const CommentsList = ({ comments }) => {
    const approvedComments = comments.filter(comment => comment.isApproved === true);

    if (!approvedComments.length) {
        return <div className={styles.noComments}>No comments</div>;
    }

    return (
        <div className={styles.commentsList}>
            {approvedComments.map((comment) => (
                <div key={comment.id} className={styles.commentItem}>
                    <span className={styles.commentAuthor}>{comment.author}:</span>
                    <p className={styles.commentText}>{comment.text}</p>
                </div>
            ))}
        </div>
    );
};



export default CommentsList;
