import React from 'react';
import styles from './MushroomBronzeIcon.module.css';
import MushroomBronze from '../../dynamicAssets/Mushroom_bronze.png';

function MushroomBronzeIcon({ variant }) {
    let className;

    if (variant === 'small') {
        className = styles.small;
    } 
    if (variant === 'extra small') {
        className = styles.extraSmall;
    } 

    return (
        <img src={MushroomBronze} alt="Bronze Mushroom" className={className} />
    );
}

export default MushroomBronzeIcon;
