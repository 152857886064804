import React, { useState, useEffect } from 'react';
import styles from './AdminListBlogs.module.css';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Helmet from 'react-helmet';
import { useSnackbar } from 'notistack';

/**
 * Used by admin to list all the blogs. Has the ability to delete, edit or add a new blog
 * 
 */
const AdminListBlogsComponent = () => {
    const [blogs, setBlogs] = useState([]);
    const navigate = useNavigate();
    //const [editingBlog, setEditingBlog] = useState(null);
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const handleAddBlogClick = () => {
        navigate("/admin/add-blog");
    };

    const BACKEND_URL = process.env.REACT_APP_BACKEND_URL || 'http://localhost:8080';

    useEffect(() => {
        const fetchBlogs = async () => {
            try {
                const response = await axios.get(`${BACKEND_URL}/blogs`);
                setBlogs(response.data);
            } catch (error) {
                console.error("Error fetching blogs", error);
            }
        };

        fetchBlogs();
    }, []);

    const handleView = (slug) => {
        navigate(`/blog-title/${slug}/`);
    };

    const handleEdit = (id) => {
        navigate(`/admin/edit-blog/${id}`);
    };

    const askForDeletionConfirmation = (blogId) => {

        enqueueSnackbar('Are you sure you want to delete this blog?', {
            variant: 'warning',
            action: (key) => {

                const handleDelete = () => {
                    try {
                        axios.delete(`${BACKEND_URL}/admin/deleteBlog/${blogId}`, { withCredentials: true })
                            .then(response => {
                                setBlogs(prevBlogs => prevBlogs.filter(blog => blog.id !== blogId));
                                enqueueSnackbar('Blog Deleted Successfully!', { variant: 'success' });
                                closeSnackbar(key);
                            })
                            .catch(error => {
                                if (error.response) {
                                    enqueueSnackbar(`Error: ${error.response.data.error || error.response.status}`, { variant: 'error' });
                                } else if (error.request) {
                                    enqueueSnackbar('Error: No response from server.', { variant: 'error' });
                                } else {
                                    enqueueSnackbar(`Error: ${error.message}`, { variant: 'error' });
                                }
                            });
                    } catch (error) {
                        console.error("Error deleting blog", error);
                    }
                };

                return (
                    <>
                        <button className={styles.actionButton} onClick={() => handleDelete(key, blogId)}>Delete</button>
                        <button className={styles.actionButton} onClick={() => closeSnackbar(key)}>Cancel</button>
                    </>
                );
            },
        });
    }



    return (
        <main className={styles.container}>
            <Helmet>
                <title>Admin List Blogs</title>
                <meta name="description" content="List of all blogs" />
                <meta name="keywords" content="blogs, list blogs, admin list blogs" />
            </Helmet>

            <h1 className={styles.title}>Admin List Blogs</h1>

            {/*editingBlog ? (
                <AdminAddBlogComponent initialBlog={editingBlog} onUpdate={() => setEditingBlog(null)} />
            ) : (*/}
            <>
                <div className={styles.blogHeader}>
                    <span className={styles.blogHeaderTitle}>Title</span>
                    <span className={styles.blogHeaderAttrib}>Created</span>
                    <span className={styles.blogHeaderAttrib}>Updated</span>
                    <span className={styles.blogHeaderAttrib}>Published</span>
                    <span className={styles.blogHeaderAttrib}>&nbsp;</span>
                </div>
                {blogs.map(blog => (
                    <div className={styles.blogItem} key={blog.id}>
                        <span className={styles.blogTitle}>{blog.title}</span>
                        <span className={styles.blogAttrib}>{blog.created ? new Date(blog.created).toLocaleDateString() : new Date(blog.created).toLocaleDateString()}</span>
                        <span className={styles.blogAttrib}>{blog.updated ? new Date(blog.updated).toLocaleDateString() : null}</span>
                        <span className={styles.blogAttrib}>{blog.isPublished ? 'Yes' : 'No'}</span>
                        <div>
                            <button className={styles.actionButton} onClick={() => handleEdit(blog.id)}>Edit</button>
                            <button className={styles.actionButton} onClick={() => askForDeletionConfirmation(blog.id)}>Delete</button>
                            <button className={styles.actionButton} onClick={() => handleView(blog.slug)}>View</button>
                        </div>
                    </div>
                ))}
                <button className={styles.actionButton} onClick={handleAddBlogClick}>Add New Blog</button>
            </>
            {/*})}*/}
        </main>
    );
};

export default AdminListBlogsComponent;
