import React, { useState, useEffect } from 'react';
import styles from './AdminListOrders.module.css';
import axios from 'axios';
import { devLog, formatTimestamp } from '../../helpers/HelperFunctions';
import Helmet from 'react-helmet';

/**
 * Used by admin to list all the orders. 
 */
const AdminListOrdersComponent = () => {
    const [orders, setOrders] = useState([]);

    const BACKEND_URL = process.env.REACT_APP_BACKEND_URL || 'http://localhost:8080';

    useEffect(() => {
        const fetchOrders = async () => {
            try {
                const response = await axios.get(`${BACKEND_URL}/admin/orders`, { withCredentials: true })
                devLog('Retrieved from Server: ', response.data);
                setOrders(response.data);
            } catch (error) {
                console.error("Error fetching orders", error);
            }
        };

        fetchOrders();
    }, []);

    return (
        <main className={styles.container}>
            <Helmet>
                <title>Admin List Orders</title>
                <meta name="description" content="List of all orders" />
                <meta name="keywords" content="orders, list orders, admin list orders" />
            </Helmet>

            <h1 className={styles.title}>Admin Orders List</h1>

            <table className={styles.table}>
                <thead>
                    <tr>
                        <th>Order ID</th>
                        <th>User ID</th>
                        <th>Shop ID</th>
                        <th>Status</th>
                        <th>Total</th>
                        <th>Payment Source</th>
                        <th>Payment Order ID</th>
                        <th>Payment Payer ID</th>
                        <th>Payment ID</th>
                        <th>Created</th>
                        <th>Updated</th>
                        <th>Item ID</th>
                        <th>Product ID</th>
                        <th>Qty</th>
                        <th>Unit Price</th>
                        <th>Item Total</th>
                        <th>Title</th>
                        <th>Name</th>
                        <th>Email</th>
                        <th>House Number</th>
                        <th>Phone Number</th>
                        <th>Street</th>
                        <th>City</th>
                        <th>Postcode</th>
                        <th>County</th>
                        <th>Country</th>
                    </tr>
                </thead>
                <tbody>
                    {Array.isArray(orders) && orders.map((order, index) => (
                        <tr key={index}>
                            <td><span className={styles.uuid}>{order.id}</span></td>
                            <td><span className={styles.uuid}>{order.user_id}</span></td>
                            <td><span className={styles.uuid}>{order.shop_id}</span></td>
                            <td>{order.status}</td>
                            <td>{order.total_price}</td>
                            <td>{order.payment_provider_source}</td>
                            <td>{order.payment_provider_order_id}</td>
                            <td>{order.payment_provider_payer_id}</td>
                            <td>{order.payment_provider_payment_id}</td>
                            <td>{formatTimestamp(order.created)}</td>
                            <td>{formatTimestamp(order.updated)}</td>
                            <td><span className={styles.uuid}>{order.items[0].id}</span></td>
                            <td><span className={styles.uuid}>{order.items[0].product_id}</span></td>
                            <td>{order.items[0].quantity}</td>
                            <td>{order.items[0].price_per_unit}</td>
                            <td>{order.items[0].total_price}</td>
                            <td>{order.shipping_detail.recipient_title}</td>
                            <td>{order.shipping_detail.recipient_name}</td>
                            <td>{order.shipping_detail.recipient_email}</td>
                            <td>{order.shipping_detail.house_number}</td>
                            <td>{order.shipping_detail.phone_number}</td>
                            <td>{order.shipping_detail.street}</td>
                            <td>{order.shipping_detail.city}</td>
                            <td>{order.shipping_detail.postcode}</td>
                            <td>{order.shipping_detail.county}</td>
                            <td>{order.shipping_detail.country}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </main>
    );
};

export default AdminListOrdersComponent;
