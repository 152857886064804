const config = {
    enableDevFeatures: process.env.REACT_APP_ENABLE_DEV_FEATURES === "true",
    emailSubmitted: "mindNourish_EmailSubmitted",
    feedbackSubmitted: "mindNourish_FeedbackSubmitted",
    mindNourishCookieAccepted: "mindNourish_CookieAccepted", 
    newsletterModalNextShowDate: "mindNourish_NewsletterModalNextShowDate",
    reportRetryFrequency: 3000,
    reportRetryNoOfTimes: 30,
    questionsPerPage: 10,
    minimumNumberOfQuestions: 20,
    newsletterModalDelay: 40000,
    newsletterNotNowDelay: 1,
    game: {
      debugGraphicsMode: false,
      initialMushroomSpeed: 50,
      initialComtaminationSpeed: 20,
      serverUpdate: 0.5, // 1 == 1 minute
      controlButtonDelay: 3000,
      feeding: {
        healthIncrement: 10,
        scoreIncrement: 5,
        co2Increment: 0.02,
        satietyIncrement: 0.1,
      },
      watering: {
        healthIncrement: 10,
        scoreIncrement: 5,
        humidityIncrement: 0.05,
      },
      harvesting: {
        scoreIncrement: 30,
      },
      rain: {
        humidityIncrement: 0.5,
        healthIncrement: 50,
      },
      ventilate: {
        humidityDecrement: 0.9,
        co2Decrement: 0.9,
        scoreIncrement: 20,
        buttonDelay: 20000,
      },
      mushroom: {
        collisionHealthDecrement: 20,
      }
    }
  };
  
  export default config;
  