import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import styles from './ReportErrorResponse.module.css';
import { useUser } from '../../contexts/UserContext';
import { devLog } from '../../helpers/HelperFunctions';

function ReportErrorResponse() {
    const { user } = useUser();
    devLog(user)
    const location = useLocation();

    // Retrieve the message from the state
    if (location.state) {
        const state = location.state;
        return (
            <main className={styles.container}>
                <h1 className={styles.title}>Hi {user && user.name}, unfortunately there has been an error while generating your report!</h1>

                <p className={styles.summary}>{state}</p>
                <p className={styles.summary}>Please try again later and <Link className={styles.styledLink} to="/contact">Contact Us</Link> if you still have any trouble.</p>
            </main>
        );
    } else {
        return (
            <></>
        );
    }
}

export default ReportErrorResponse;
