import React, { useState } from 'react';
import styles from './CommentForm.module.css';

const CommentForm = ({ addComment }) => {
    const [commentText, setCommentText] = useState('');
    
    const handleSubmit = (event) => {
        event.preventDefault();
        addComment({ text: commentText });
        setCommentText('');
    };

    return (
        <form onSubmit={handleSubmit} className={styles.commentForm}>
            <textarea
                value={commentText}
                onChange={(e) => setCommentText(e.target.value)}
                placeholder="Write your comment..."
                required
                className={styles.commentTextarea}
            />
            <button type="submit" className={styles.commentButton}>Add Comment</button>
        </form>
    );
};

export default CommentForm;
