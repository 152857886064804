import React from 'react';
import styles from './VoteSnackbarContent.module.css';

/**
 * A customised snackbar that contains a thumbs up and thumbs down.
 * 
 * This is how its used:
 *
 const { enqueueSnackbar, closeSnackbar } = useSnackbar();

 enqueueSnackbar('Do you like this feature?', {
    content: (key, message) => (
        <VoteSnackbarContent
            id={key}
            message={message}
            onThumbUp={() => {
                devLog
                // handle thumbs up action here
                closeSnackbar(key);
            }}
            onThumbDown={() => {
                devLogown clicked');
                // handle thumbs down action here
                closeSnackbar(key);
            }}
        />
    )
 });
*/
const VoteSnackbarContent = React.forwardRef((props, ref) => {
    const { message, onThumbUp, onThumbDown } = props;

    return (
        <div className={styles.content} ref={ref}>
            <span className={styles.message}>{message}</span>
            <button className={styles.styledButton} onClick={onThumbUp} aria-label="thumbs up"><span role="img" aria-label="thumbs up">👍</span></button>
            <button className={styles.styledButton} onClick={onThumbDown} aria-label="thumbs down"><span role="img" aria-label="thumbs down">👎</span></button>
        </div>
    );
});

export default VoteSnackbarContent;
