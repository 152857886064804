import React, { useState, useEffect } from 'react';
import styles from './AdminListUsers.module.css';
import axios from 'axios';
import { devLog } from '../../helpers/HelperFunctions';
import Helmet from 'react-helmet';

/**
 * Used by admin to list all the users. 
 */
const AdminListUsersComponent = () => {
    const [users, setUsers] = useState([]);

    const BACKEND_URL = process.env.REACT_APP_BACKEND_URL || 'http://localhost:8080';

    useEffect(() => {
        const fetchUsers = async () => {
            try {
                const response = await axios.get(`${BACKEND_URL}/admin/users`, { withCredentials: true })
                devLog('Retrieved from Server: ', response.data);
                setUsers(response.data);
            } catch (error) {
                console.error("Error fetching users", error);
            }
        };

        fetchUsers();
    }, []);

    return (
        <main className={styles.container}>
            <Helmet>
                <title>Admin List Users</title>
                <meta name="description" content="List of all users" />
                <meta name="keywords" content="users, list users, admin list users" />
            </Helmet>

            <h1 className={styles.title}>Admin List Users</h1>

            <div className={styles.userHeader}>
                <span className={styles.userHeaderTitle}>Name</span>
                <span className={styles.userHeaderTitle}>Email</span>
                <span className={styles.userHeaderTitle}>Enabled</span>
                <span className={styles.userHeaderTitle}>Is Admin</span>
                <span className={styles.userHeaderTitle}>Created</span>
                <span className={styles.userHeaderTitle}>Updated</span>
            </div>
            {Array.isArray(users) && users.map(user => (
                <div className={styles.userItem} key={user.id}>
                    <span className={styles.userTitle}>{user.name}</span>
                    <span className={styles.userTitle}>{user.email}</span>
                    <span className={styles.userTitle}>{user.enabled ? 'Yes' : 'No'}</span>
                    <span className={styles.userTitle}>{user.is_admin ? 'Yes' : 'No'}</span>
                    <span className={styles.userTitle}>{user.created ? new Date(user.created).toLocaleDateString() : new Date(user.created).toLocaleDateString()}</span>
                    <span className={styles.userTitle}>{user.updated ? new Date(user.updated).toLocaleDateString() : null}</span>
                </div>
            ))}
        </main>
    );
};

export default AdminListUsersComponent;
