import React, { createContext, useContext, useState, useEffect } from 'react';
import axios from 'axios';
import { useSnackbar } from 'notistack';
import config from '../config.js'
import { devLog } from '../helpers/HelperFunctions';

const GameContext = createContext();

export const useGameContext = () => {
    return useContext(GameContext);
};

// The initial state of Mushroom
const initialState = {
    id: null,
    name: 'Mushroom',
    user_id: null,
    health: 100,
    mood: 'happy',
    score: 0,
    co2: 0.1,
    humidity: 0.1,
    satiety: 0.1,
    can_harvest: false,
    size: 'small',
    armour_class: 0,
    created: null,
    updated: null,
};

/**
 * Holds the state that both GameComponent (React) and PhaserGameWrapper (Phaser) use.
 * 
 * Note: This context has to wrap the game context so does not have access to a user. If any 
 * functionality here requires the user object, it will need to be passed in as a param. 
 */
export const GameProvider = ({ children }) => {
    const [gameState, setGameState] = useState(initialState);

    // Set to true when the game is being reset.
    const [resetGame, setResetGame] = useState(false);

    const [resumedGameData, setResumedGameData] = useState({
        gameState: null,
        shouldResume: false,
    });

    // Set to true when harvesting has started.
    const [harvestingInitiated, setHarvestingInitiated] = useState(false);

    const { enqueueSnackbar } = useSnackbar();

    const BACKEND_URL = process.env.REACT_APP_BACKEND_URL || 'http://localhost:8080';

    useEffect(() => {
        devLog("GameContext useEffect");
        //devLog('Reset Game ', resetGame);
    }, [resetGame])

    /**
     * This runs once when the game is mounted. If the user is logged in, it retrieves its 
     * game state from the server.
     */
    /*useEffect(() => {
        devLog('User object when useEffect runs:', user);

        if (user) {
            devLog('Retrieving game for logged in user ', user.id)
            // Replace with your actual endpoint and method to get user's game data.
            axios.get(`http://localhost:8080/game/${user.id}`, { withCredentials: true })
                .then(response => {
                    devLog('Retrieved game from server: ', response.data);
                    //setResetGame(true);
                    //setGameState(response.data);
                    //enqueueSnackbar('Game Loaded', { variant: 'success' });
                })
                .catch(error => {
                    console.error("Error fetching user's game data:", error);
                })
                .finally(() => {
                    setIsLoading(false);  // End the loading phase
                });
        } else {
            setIsLoading(false);
        }
    }, [user]);*/

    /**
     * If the user is logged in, call the backend to get the latest game from the database and set resumedGameData which will trigger a hook in the game wrapper.
     * If not, set the resetGame flag which will trigger a different hook in the game wrapper. 
     * 
     * If the user is logged in but the game isn't in the database, create a new one and retrieve that.
     * 
     * @param {user} user 
     */
    const handleResumeOrReset = (user) => {
        devLog('handleResumeOrReset: ', user);
        if (user && user.email) {
            devLog('handleResumeOrReset for logged in user. Resuming: ', user.email);
            devLog(initialState);
            try {

                axios.get(`${BACKEND_URL}/game/${user.id}`, { withCredentials: true })
                    .then(response => {
                        devLog('Retrieved game from server: ', response.data);
                        enqueueSnackbar('Game Resumed Successfully!', { variant: 'success' });
                        setResumedGameData({ gameState: response.data, shouldResume: true });
                    })
                    .catch(error => {
                        /*if (error.response && error.response.status === 400) {
                            devLog('Received bad request status: ', error.response);

                            // Call post to create game due to bad request. Is this correct? I don't think we should create a game here. TODO Remove this
                            return axios.post(`${BACKEND_URL}/game/createGame/${user.id}`, initialState, { withCredentials: true });

                        } else */if (error.response) {
                            console.error(error.response);
                        } else if (error.request) {
                            console.error(error.request);
                        } else {
                            console.error(error.message);
                        }
                    });
                    /*.then(secondResponse => {
                        // This will only execute if the first axios call was a bad request
                        if (secondResponse) {
                            devLog('Created new game ', secondResponse.data);
                            axios.get(`${BACKEND_URL}/game/${user.id}`, { withCredentials: true })
                                .then(response => {
                                    devLog('Retrieved game from server: ', response.data);
                                    enqueueSnackbar('Game Resumed Successfully!', { variant: 'success' });
                                    setResumedGameData({ gameState: response.data, shouldResume: true });
                                })
                                .catch(error => {
                                    if (error.response) {
                                        console.error(error.response);
                                    } else if (error.request) {
                                        console.error(error.request);
                                    } else {
                                        console.error(error.message);
                                    }
                                })

                        }
                    })
                    .catch(error => {
                        if (error.response) {
                            enqueueSnackbar(`Error: ${error.response.data.error || error.response.status}`, { variant: 'error' });
                        } else if (error.request) {
                            enqueueSnackbar('Error: No response from server.', { variant: 'error' });
                        } else {
                            enqueueSnackbar(`Error: ${error.message}`, { variant: 'error' });
                        }
                    });*/
            } catch (error) {
                console.error("Error processing new game", error);
            }
        } else {
            devLog('handleResumeOrReset for anon user. Resetting.');
            setResetGame(true);
        }

    };

    const handleWatering = () => {
        // Update the game state based on watering action
        //devLog('Watering: ', gameState);
        setGameState((prevState) => ({
            ...prevState,
            health: Math.min(100, prevState.health + config.game.watering.healthIncrement),
            score: prevState.score + config.game.watering.scoreIncrement,
            humidity: Math.min(1, prevState.humidity + config.game.watering.humidityIncrement),
        }));
    };

    /**
     * Updates the game state based on feeding action.
     */
    const handleFeeding = () => {
        setGameState((prevState) => {
            // Check if mood is either 'small' or 'medium'
            const shouldIncrementScore = ['small', 'medium'].includes(prevState.size);

            return {
                ...prevState,
                health: Math.min(100, prevState.health + config.game.feeding.healthIncrement),
                score: shouldIncrementScore ? prevState.score + config.game.feeding.scoreIncrement : prevState.score,
                co2: Math.min(1, prevState.co2 + config.game.feeding.co2Increment),
                satiety: prevState.satiety + config.game.feeding.satietyIncrement,
            };
        });
    };


    /**
     * Handles the game state based on ventilate action.
     */
    const handleVentilate = () => {
        // Update the game state based on ventilate action
        //devLog('Ventilate: ', gameState);
        setGameState((prevState) => ({
            ...prevState,
            score: prevState.score + config.game.ventilate.scoreIncrement,
            humidity: Math.max(0.1, prevState.humidity - config.game.ventilate.humidityDecrement),
            co2: Math.max(0.1, prevState.co2 - config.game.ventilate.co2Decrement),
        }));
    };

    /**
     * Updates the game state based on harvesting action. Sets
     * harvestingInitiated to true to signal to Phaser to harvest.
     */
    const handleHarvesting = () => {
        // Update the game state based on harvesting action
        //devLog('Harvesting: ', gameState);
        setGameState((prevState) => ({
            ...prevState,
            health: initialState.health,
            satiety: initialState.satiety,
            score: prevState.score + config.game.harvesting.scoreIncrement,
        }));
        setHarvestingInitiated(true);
    };


    const updateServer = async () => {
        // Only update game for a logged in user
        devLog(gameState.user_id !== null ? 'User logged in. Updating server' : 'User not logged in. No update necessary');
        if (gameState.user_id != null) {
            devLog('Updating game state to server. Sending: ', gameState)

            try {
                axios.put(`${BACKEND_URL}/game/updateGame`, gameState, { withCredentials: true })
                    .then(response => {
                        devLog('Game Updated Successfully!');
                    })
                    .catch(error => {
                        if (error.response) {
                            console.error(`Error: ${error.response.data.error || error.response.status}`);
                        } else if (error.request) {
                            console.error('Error: No response from server.');
                        } else {
                            console.error(`Error: ${error.message}`);
                        }
                    });
            } catch (error) {
                console.error("Error updating game", error);
            }
        }
    };

    /**
     * This creates a new game in the database. If the game already exists due to the player has died, it sets the game back to its initial state.
     */
    const createGame = async () => {

        if (gameState.user_id != null) {
            devLog('Creating new game for user: ', gameState.user_id);

            try {
                axios.post(`${BACKEND_URL}/game/createOrUpdateGame/${gameState.user_id}`, initialState, { withCredentials: true })
                    .then(response => {
                        enqueueSnackbar('Game Created Successfully!', { variant: 'success' });
                    })
                    .catch(error => {
                        if (error.response) {
                            enqueueSnackbar(`Error: ${error.response.data.error || error.response.status}`, { variant: 'error' });
                        } else if (error.request) {
                            enqueueSnackbar('Error: No response from server.', { variant: 'error' });
                        } else {
                            enqueueSnackbar(`Error: ${error.message}`, { variant: 'error' });
                        }
                    });
            } catch (error) {
                console.error("Error processing new game", error);
            }
        }
    }

    /*useEffect(() => {
        devLog('GameProvider re-rendered: ', gameState);
    });*/


    const values = {
        gameState,
        setGameState,
        handleWatering,
        handleFeeding,
        handleVentilate,
        handleHarvesting,
        updateServer,
        harvestingInitiated,
        setHarvestingInitiated,
        resumedGameData,
        setResumedGameData,
        handleResumeOrReset,
        resetGame,
        setResetGame,
        createGame
    };

    return (
        <GameContext.Provider value={values}>
            {children}
        </GameContext.Provider>
    );
};
