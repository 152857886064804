import React from 'react';
import styles from './CheckoutFailure.module.css';
import { useNavigate } from 'react-router-dom';

const CheckoutFailure = () => {
    const navigate = useNavigate();

    return (
        <main className={styles.container}>
            <h1 className={styles.title}>Checkout Failed</h1>
            <h2 className={styles.subtitle}>Something went wrong.</h2>
            <p className={styles.text}>Please try again or contact our support for assistance.</p>
            <button className={styles.button} onClick={() => navigate(-1)}>Back to Basket</button>
        </main>
    );
};

export default CheckoutFailure;
