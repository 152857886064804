import React, { useEffect, useState } from 'react';
import styles from './ReportCounter.module.css';

const ReportCounter = () => {
    const reportCount = 2765; 
    const [displayedReportCount, setDisplayedReportCount] = useState(0);

    useEffect(() => {
        const incrementInterval = 5; 
        const step = 10; 

        if (displayedReportCount < reportCount) {
            const interval = setInterval(() => {
                setDisplayedReportCount(prevCount => Math.min(prevCount + step, reportCount));
            }, incrementInterval);

            return () => clearInterval(interval);
        }
    }, [displayedReportCount, reportCount]);

    return (
        <div className={styles.counterContainer}>
            <span className={styles.reportCount}>{displayedReportCount}</span>
            <span className={styles.description}>Reports Generated</span>
        </div>
    );
}

export default ReportCounter;
