import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function TikTokIcon() {
    return (
        <FontAwesomeIcon icon={['fab', 'tiktok']} color="#FAFAFA" size="2x" />
        
    );
}

export default TikTokIcon;
