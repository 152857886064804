import React, { useEffect, useState, useContext } from 'react';
import styles from './Goals.module.css';
import axios from 'axios';
import { UserContext } from '../../contexts/UserContext';
import { devLog } from '../../helpers/HelperFunctions';
import { useSnackbar } from 'notistack';

const Goals = () => {
    const [goals, setGoals] = useState([]);
    const [newGoal, setNewGoal] = useState('');
    const [selectedDate, setSelectedDate] = useState("");
    const { user } = useContext(UserContext);
    const { enqueueSnackbar } = useSnackbar();
    const BACKEND_URL = process.env.REACT_APP_BACKEND_URL || 'http://localhost:8080';

    useEffect(() => {
        axios.get(`${BACKEND_URL}/goals/${user.id}`, { withCredentials: true })
            .then(response => {
                const goalsData = Array.isArray(response.data) ? response.data : [];
                setGoals(goalsData);
                devLog('Retrieved user goals from server: ', goalsData);
            })
            .catch(error => {
                devLog('Error fetching goals:', error);
                setGoals([]);
            });

    }, [user.id, BACKEND_URL]);

    const handleDateChange = (event) => {
        setSelectedDate(event.target.value);
    };

    const handleAdd = () => {
        const formattedDate = selectedDate ? `${selectedDate}T00:00:00Z` : null;
        const newGoalEntry = {
            userId: user.id,
            description: newGoal,
            targetDate: formattedDate
        };

        axios.post(`${BACKEND_URL}/addGoal`, newGoalEntry, { withCredentials: true })
            .then(response => {
                //devLog('Goal added: ', response.data);
                const newGoalData = response.data;
                setGoals([...goals, newGoalData]);
                setNewGoal('');
                setSelectedDate('');
                //devLog('Added new goal: ', newGoalData);
                enqueueSnackbar('New Goal Added!', { variant: 'success' });
            })
            .catch(error => {
                if (error.response && error.response.status === 400) {
                    devLog('Error adding goal (Bad Request):', error.response.data);
                    enqueueSnackbar(error.response.data.error, { variant: 'error' });
                } else {
                    devLog('Error adding goal:', error);
                    enqueueSnackbar(error, { variant: 'error' });
                }
            });
    };

    const handleDelete = (goalId) => {
        axios.delete(`${BACKEND_URL}/deleteGoal/${goalId}`, { withCredentials: true })
            .then(response => {
                //devLog('Goal deleted: ', response.data);
                const updatedGoals = goals.filter(a => a.id !== goalId);
                setGoals(updatedGoals);
                enqueueSnackbar('Goal Deleted.', { variant: 'success' });
            })
            .catch(error => {
                devLog('Error deleting goal:', error);
                enqueueSnackbar(error, { variant: 'error' });
            });
    };

    const handleGoalAchievement = (goalId, description, success) => {
        const updatedGoal = {
            id: goalId,
            userId: user.id,
            description: description,
            success: !success,
        };

        // Optimistically update the state to reflect the change in the UI immediately
        const updatedGoals = goals.map(goal => {
            if (goal.id === goalId) {
                return { ...goal, success: !success };
            }
            return goal;
        });
        setGoals(updatedGoals);

        axios.put(`${BACKEND_URL}/updateGoal/${goalId}`, updatedGoal, { withCredentials: true })
            .then(response => {
                devLog('Updated goal status: ', response.data);
                enqueueSnackbar('Goal Achieved. Well Done!', { variant: 'success' });
                // The optimistic update is already done, so no need to update state here
            })
            .catch(error => {
                if (error.response && error.response.status === 400) {
                    devLog('Error adding goal (Bad Request):', error.response.data);
                    enqueueSnackbar(error.response.data.error, { variant: 'error' });
                } else {
                    // Handle other errors
                    devLog('Error adding goal:', error);
                    enqueueSnackbar(error, { variant: 'error' });
                }

                setGoals(goals.map(goal => {
                    if (goal.id === goalId) {
                        return { ...goal, success: success }; // Revert to original success state
                    }
                    return goal;
                }));
            });
    };

    return (
        <section className={styles.container}>
            <h2 className={styles.title}>Personal Goals</h2>

            <div className={styles.headings}>
                <label className={styles.achievedLabel}>Goal Achieved?</label>
                <label className={styles.descriptionLabel}>Description</label>
                <label className={styles.dateLabel}>Date Set</label>
                <label className={styles.targetDateLabel}>Target Date</label>
            </div>

            <ul className={styles.list}>
                {goals.length === 0 && <li className={styles.noGoals}>No goals added</li>}
                {goals.map(goal => (
                    <li className={styles.listItem} key={goal.id}>
                        <input
                            type="checkbox"
                            checked={goal.success}
                            onChange={() => handleGoalAchievement(goal.id, goal.description, goal.success)}
                            className={styles.checkbox}
                            disabled={goal.success}
                        />
                        <span className={styles.goalDescription}>{goal.description}</span>
                        <span className={styles.goalDate}>{goal.updated ? new Date(goal.updated).toLocaleDateString() : new Date(goal.created).toLocaleDateString()}</span>
                        <span className={styles.targetDate}>{goal.targetDate ? new Date(goal.targetDate).toLocaleDateString() : 'None'}</span>
                        <button className={styles.actionButton} onClick={() => handleDelete(goal.id)}>Delete</button>
                    </li>
                ))}
            </ul>
            <div className={styles.addGoalHeadings}>
                <label className={styles.addDescriptionLabel}>&nbsp;</label>
                <label className={styles.addDateLabel}>Target Date</label>
            </div>
            <div className={styles.addGoalArea}>
                <input
                    type="text"
                    value={newGoal}
                    onChange={e => setNewGoal(e.target.value)}
                    className={styles.styledInput}
                    placeholder="Add new goal here"
                />
                <label className={styles.addMobileDateLabel}>Target Date:</label>
                <input
                    type="date"
                    value={selectedDate}
                    onChange={handleDateChange}
                    className={styles.styledDateInput}
                />
                <button className={styles.actionButton} onClick={handleAdd}>Add</button>
            </div>
        </section>
    );
};

export default Goals;
