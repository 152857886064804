import React from 'react';
import styles from './Journey.module.css';
import GetReportComponent from '../components/reports/GetReport';
import QuestionsComponent from '../components/reports/Questions';
import { useUser } from '../contexts/UserContext';
import { useNavigate } from 'react-router-dom';
import Helmet from 'react-helmet';
import config from '../config';
import { sendTelegramMessage, sendAuditLog } from '../helpers/HelperFunctions';
import { ACTION_TYPE_CLICK_BUTTON,  ENTITY_TYPE_JOURNEY, ENTITY_ID_SIGNUP } from '../helpers/Constants';

function Journey() {
    const navigate = useNavigate();
    const { user } = useUser();

    const handleSignupClick = () => {
        sendTelegramMessage('Journey - "Join Now" button clicked');

        const userID = user ? user.id : null;
        sendAuditLog(userID, ACTION_TYPE_CLICK_BUTTON, ENTITY_TYPE_JOURNEY, ENTITY_ID_SIGNUP, null);

        navigate('/signup');
    };

    return (
        <main className={styles.container}>
            <Helmet>
                <title>Start Your Personalised Lifestyle Journey | Mind Nourish Tech</title>
                <meta name="description" content="Dive into your personalised health journey with Mind Nourish Tech. Answer tailored questions and receive a detailed lifestyle report with actionable advice to guide you towards better health." />
                <meta name="keywords" content="journey, lifestyle journey, lifestyle report, health assessment, personalised health advice, personalised lifestyle journey, wellness journey, lifestyle questions, health improvement guide" />
            </Helmet>

            <div className={styles.journeyContainer}>
                <h1 className={styles.title}>Begin Your Personalised Health Journey <span role="img" aria-label="rocket">🚀</span></h1>
                {!user && <p className={styles.description}>
                    For a tailored experience, <span className={styles.highlight}>create an account</span> or <span className={styles.highlight}>log in</span>. Safe, secure, and all about you. <span role="img" aria-label="padlock">🔐</span>
                </p>}
                {!user && <button className={styles.signupButton} onClick={handleSignupClick}>Join Now</button>}
                {!user && <p className={styles.description}>
                    Here at <span className={styles.highlight}>Mind Nourish Tech</span>, we aim to understand and guide you. Log in, tap <span className={styles.highlight}>Get Started</span>, and let us know about your daily habits. The more you share, the clearer your health roadmap becomes.
                </p>}
                {user && <p className={styles.description}>Tap on <span className={styles.highlight}>Get Started</span> to share your daily lifestyle habits. Together, we&apos;ll carve out your health journey.
                </p>}
                {user && <p className={styles.description}>
                    Finished? Just hit <span className={styles.highlight}>Generate Report</span> <span role="img" aria-label="graph">📊</span> and uncover steps tailored to guide you towards a healthier, happier you.
                </p>}
                <p className={styles.description}>
                    Embrace this journey — it&apos;s your path to growth and self-understanding. <span role="img" aria-label="plant">🌱</span>
                </p>
                {user && <p className={styles.dscription}>
                    We have a series of simple questions for you. To tailor the best advice for you, we need answers to at least {config.minimumNumberOfQuestions} questions. But the more you share, the better your roadmap! Ready to embark?
                </p>
                }
            </div>


            <QuestionsComponent />
            <GetReportComponent />

        </main>
    )
}

export default Journey
