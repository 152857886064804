import React, { useState } from 'react';
import styles from './AdminTools.module.css';
import axios from 'axios';
import { useSnackbar } from 'notistack';
import { devLog } from '../../helpers/HelperFunctions';
import Helmet from 'react-helmet';

const AdminTools = () => {
    const [cacheDisplay, setCacheDisplay] = useState('');
    const [localStorageDisplay, setLocalStorageDisplay] = useState('');
    const { enqueueSnackbar } = useSnackbar();

    const BACKEND_URL = process.env.REACT_APP_BACKEND_URL || 'http://localhost:8080';

    const handleDisplayCache = async () => {
        try {
            const response = await axios.get(`${BACKEND_URL}/admin/cache/display`, { withCredentials: true });
            setCacheDisplay(JSON.stringify(response.data, null, 2));
        } catch (error) {
            enqueueSnackbar(`Error: ${error.message}`, { variant: 'error' });
        }
    };

    const handleFlushCache = async () => {
        try {
            await axios.post(`${BACKEND_URL}/admin/cache/flush`, {}, { withCredentials: true });
            setCacheDisplay('');
            enqueueSnackbar('Cache Flushed Successfully!', { variant: 'success' });
        } catch (error) {
            enqueueSnackbar(`Error: ${error.message}`, { variant: 'error' });
        }
    };

    const handleDeleteLocalStorage = async () => {
        try {
            for (let i = 0; i < localStorage.length; i++) {
                const key = localStorage.key(i);
                if (key.startsWith("mindNourish")) {
                    localStorage.removeItem(key);
                    i--;
                }
            }
            enqueueSnackbar('Local Storage Deleted Successfully!', { variant: 'success' });
        } catch (error) {
            enqueueSnackbar(`Error: ${error.message}`, { variant: 'error' });
        }
    };

    const handleDisplayLocalStorage = async () => {
        let formattedString = "\n";
        for (let i = 0; i < localStorage.length; i++) {
            const key = localStorage.key(i);
            if (key.startsWith("mindNourish_")) {
                const value = localStorage.getItem(key);
                devLog(`${key}: ${value}`);
                formattedString += `${key}: ${value}\n`;
            }
        }
        devLog("Formatted String:", formattedString);

        setLocalStorageDisplay(formattedString)
    }

    return (
        <main className={styles.container}>
            <Helmet>
                <title>Admin Tools</title>
                <meta name="description" content="List of admin tools" />
                <meta name="keywords" content="admin tools" />
            </Helmet>
            
            <h1 className={styles.title}>Admin Tools</h1>

            <div className={styles.toolAction}>
                <label>Display Local Storage:</label>
                <button className={styles.actionButton} onClick={handleDisplayLocalStorage}>Display Local Storage</button>
            </div>

            <div className={styles.toolAction}>
                <label>Delete Local Storage:</label>
                <button className={styles.actionButton} onClick={handleDeleteLocalStorage}>Delete Local Storage</button>
            </div>

            <div className={styles.toolAction}>
                <label>Display Cache:</label>
                <button className={styles.actionButton} onClick={handleDisplayCache}>Display Cache</button>
            </div>

            <div className={styles.toolAction}>
                <label>Flush Cache:</label>
                <button className={styles.actionButton} onClick={handleFlushCache}>Flush Cache</button>
                <p className={styles.note}>
                    Note: Flushing the cache will clear all entries. However, the admin user is immediately placed back
                    into the cache during authentication. Thus, there will always be at least one user, the admin, in the cache.
                </p>
            </div>

            {cacheDisplay && <pre className={styles.result}>{cacheDisplay}</pre>}
            {localStorageDisplay && <pre className={styles.result}>Local Storage: {localStorageDisplay}</pre>}
        </main>
    );
}

export default AdminTools;
