import React from 'react';
import styles from './ReportInstructions.module.css';
import UserFeedback from './common/UserFeedback';
import { Helmet } from 'react-helmet';
import ReactGA from 'react-ga4';
import { useNavigate } from 'react-router-dom';

function ReportInstructions() {
    const navigate = useNavigate();

    const handleGetReportClick = () => {
        ReactGA.event({
            name: 'clicked_get_your_lifestyle_report',
            params: {
                content_type: 'button'
            }
        });

        navigate('/journey');
    };

    return (
        <main className={styles.container}>
            <Helmet>
                <title>Lifestyle Report Instructions</title>
                <meta name="description" content="Describes how to get your lifestyle report" />
                <meta name="keywords" content="lifestyle report instructions, health report" />
            </Helmet>
            <h1 className={styles.title}>How Your Personalised Lifestyle Report is Created at Mind Nourish Tech <span role="img" aria-label="pink flower">🌸</span></h1>

            <h2 className={styles.sectionTitle}>Step 1: Join the Mind Nourish Family</h2>
            <p className={styles.paragraph}>
                First thing&apos;s first—create your account and log in to unlock your journey to better health. Just follow the simple sign-up and login prompts. And don&apos;t worry, logging out is a breeze when you need to.
            </p>

            <h2 className={styles.sectionTitle}>Step 2: Begin Your Wellness Journey</h2>
            <p className={styles.paragraph}>
                Once you&apos;re in, click on &quot;Get Your Lifestyle Report&quot; from our cosy home page. A tap on &quot;Get Started&quot; will bring up thoughtful questions designed just for you.
            </p>

            <h2 className={styles.sectionTitle}>Step 3: Share a Bit About Yourself</h2>
            <p className={styles.paragraph}>
                The first set of 10 questions will appear. Take your time, and answer at your own pace. Click &quot;Next&quot; for more questions or &quot;Generate Report&quot; whenever you&apos;re ready to see your personalised insights.
                If you change your mind, just hit &quot;Cancel&quot; and start over when you&apos;re ready.
            </p>

            <h2 className={styles.sectionTitle}>Step 4: Understanding Your Wellness Score</h2>
            <p className={styles.paragraph}>
                Curious about how we generate your wellness score? Here&apos;s the breakdown: Every question you answer contributes points to your personalised lifestyle score.
                Your answers are more than just answers to us—they&apos;re insights into your lifestyle! Depending on the nature of your response:
            </p>
            <ul>
                <li><strong>Ideal answers</strong> earn you a glowing 10 points.</li>
                <li><strong>Neutral responses</strong> bring in a steady 5 points.</li>
                <li><strong>Not-so-ideal ones</strong>? Don&apos;t fret, they still add a point to your score.</li>
            </ul>
            <p className={styles.paragraph}>
                So, let&apos;s say you&apos;ve answered 20 questions with not-so-ideal responses. You&apos;d still accumulate 20 points! The maximum score you can achieve is 500, symbolising optimal health and lifestyle habits.
                But here&apos;s the magic—your score isn&apos;t just about points. The more questions you tackle, the richer the insights you get. Think of it as painting a detailed portrait of your health.
                Every brushstroke, every color you add brings more depth, more understanding. So, dive deep, answer more questions, and let&apos;s create a masterpiece together!
            </p>


            <h2 className={styles.sectionTitle}>Step 5: Receive Your Full, Blossoming Report <span role="img" aria-label="yellow flower">🌼</span></h2>
            <p className={styles.paragraph}>
                Our system will keep checking for you to see when your full, blossoming report is ready to view. In the unlikely event that there&apos;s a hiccup, we&apos;ll let you know.
            </p>

            <p className={styles.paragraph}>
                And there you have it! A comprehensive lifestyle report curated just for you. Welcome to a more mindful and nourished life! <span role="img" aria-label="leaves">🌿</span><span role="img" aria-label="hearts">💕</span>
            </p>

            <button className={styles.startButton} onClick={handleGetReportClick}>Get Your Lifestyle Report <span role="img" aria-label="star">🌟</span></button>

            <UserFeedback pageId="report-instructions" />
        </main>
    );
};

export default ReportInstructions;
