import React, { useState, useEffect } from 'react';
import styles from './MushroomStats.module.css';
import MushroomIcon from './common/MushroomIcon';
//import mushroomHatImg from '../assets/Mushroom_hat1_500.png';
import { useUser } from '../contexts/UserContext';
import axios from 'axios';
import { capitaliseFirstLetter, daysSince, boolToString, formatTimestamp, countThousands } from '../helpers/HelperFunctions';
import { devLog } from '../helpers/HelperFunctions';
import { Helmet } from 'react-helmet';

function CollapsibleSection({ title, children }) {
    const [isOpen, setIsOpen] = useState(false);

    const toggleSection = () => {
        if (window.innerWidth <= 768) {
            setIsOpen(!isOpen);
        }
    };

    return (
        <div className={styles.section}>
            <div className={`${styles.sectionHeader} ${isOpen ? styles.open : styles.closed}`} onClick={toggleSection}>
                {title}
            </div>
            <div className={`${styles.sectionContent} ${isOpen ? styles.open : ''}`}>
                {children}
            </div>
        </div>
    );
}


function Accessories() {
    return (
        <>
            {/*<AccessoryImage src={mushroomHatImg} alt="Mushroom Hat" style={{ left: '28.5%', top: '7.4%' }} />*/}
        </>
    );
}

/*const skillsAbilities = [
    { name: 'Photosynthesis Boost', icon: mushroomImg, description: 'Increases CO2 absorption rate' },
    { name: 'Toughened Skin', icon: mushroomImg, description: 'Reduced damage taken from contamination' }
];*/

const skillsAbilities = [
    { name: 'Photosynthesis Boost', description: 'Increases CO2 absorption rate' },
    { name: 'Toughened Skin', description: 'Reduced damage taken from contamination' }
];

function SkillsList() {
    return (
        <div>
            {skillsAbilities.map((skill) => (
                <div className={styles.tooltipContainer} key={skill.name}>
                    <div className={styles.skill}>
                        <div className={styles.smallImageWrapper}><MushroomIcon variant="small" /></div>
                        {/*<img className={styles.skillIcon} src={skill.icon} alt={skill.name} />*/}
                        {skill.name}
                    </div>
                    <span className={styles.tooltip}>{skill.description}</span>
                </div>
            ))}
        </div>
    );
}


const initialState = {
    id: null,
    name: 'Mushroom',
    user_id: null,
    health: 100,
    mood: 'happy',
    score: 0,
    co2: 0.1,
    humidity: 0.1,
    satiety: 0.1,
    can_harvest: false,
    size: 'small',
    armour_class: 0,
    created: null,
    updated: null,
};

function MushroomStats() {

    const { user } = useUser();
    const [gameState, setGameState] = useState(initialState);

    const BACKEND_URL = process.env.REACT_APP_BACKEND_URL || 'http://localhost:8080';

    useEffect(() => {
        devLog('Looking up Mushroom');

        if (user && user.email) {
            devLog('Retrieving Mushroom for logged in user: ', user.email);

            axios.get(`${BACKEND_URL}/game/${user.id}`, { withCredentials: true })
                .then(response => {
                    devLog('Retrieved mushroom from server: ', response.data);
                    setGameState(response.data);
                })
                .catch(error => {
                    console.error("Error retrieving game", error);
                });
        }
    }, []);

    return (
        <main>
            <h1 className={styles.title}>Current Game Statistics</h1>
            <div className={styles.container}>
                <Helmet>
                    <title>Mushroom Game Statistics</title>
                    <meta name="description" content="Shows the statistics of your current mushroom game" />
                    <meta name="keywords" content="mushroom game stats" />
                </Helmet>

                <div className={styles.mushroomSection}>

                    <div className={styles.imageWrapper}>
                        <MushroomIcon variant="default" />
                    </div>

                    <Accessories />

                    <div className={`${styles.statBox} ${styles.topStat}`}><span className={styles.itemTitle}>Name:</span> {gameState.name} <span className={styles.itemTitle}>Lvl:</span> {countThousands(gameState.score)}</div>

                    <div className={`${styles.statBox} ${styles.leftStat}`} key='score' style={{ top: `${50 + (0 * 60)}px` }}><span className={styles.itemTitle}>Score:</span> {gameState.score}</div>
                    <div className={`${styles.statBox} ${styles.leftStat}`} key='humidity' style={{ top: `${50 + (1 * 60)}px` }}><span className={styles.itemTitle}>Humidity:</span> {gameState.humidity}</div>
                    <div className={`${styles.statBox} ${styles.leftStat}`} key='co2' style={{ top: `${50 + (2 * 60)}px` }}><span className={styles.itemTitle}>CO2:</span> {gameState.co2}</div>
                    <div className={`${styles.statBox} ${styles.leftStat}`} key='satiety' style={{ top: `${50 + (3 * 60)}px` }}><span className={styles.itemTitle}>Satiety:</span> {gameState.satiety}</div>
                    <div className={`${styles.statBox} ${styles.leftStat}`} key='mood' style={{ top: `${50 + (4 * 60)}px` }}><span className={styles.itemTitle}>Mood:</span> {capitaliseFirstLetter(gameState.mood)}</div>

                    <div className={`${styles.statBox} ${styles.rightStat}`} key='health' style={{ top: `${50 + (0 * 60)}px` }}><span className={styles.itemTitle}>Health:</span> {gameState.health}</div>
                    <div className={`${styles.statBox} ${styles.rightStat}`} key='age' style={{ top: `${50 + (1 * 60)}px` }}><span className={styles.itemTitle}>Age:</span> {daysSince(gameState.created)}</div>
                    <div className={`${styles.statBox} ${styles.rightStat}`} key='size' style={{ top: `${50 + (2 * 60)}px` }}><span className={styles.itemTitle}>Size:</span> {capitaliseFirstLetter(gameState.size)}</div>
                    <div className={`${styles.statBox} ${styles.rightStat}`} key='can_harvest' style={{ top: `${50 + (3 * 60)}px` }}><span className={styles.itemTitle}>Harvest?</span> {boolToString(gameState.can_harvest)}</div>
                    <div className={`${styles.statBox} ${styles.rightStat}`} key='armour_class' style={{ top: `${50 + (4 * 60)}px` }}><span className={styles.itemTitle}>Armour Class:</span> {gameState.armour_class}</div>

                    {/*{leftStats.map((stat, index) => (
                    <LeftStat key={stat} style={{ top: `${100 + (index * 60)}px` }}>
                        {stat}
                    </LeftStat>
                ))}*/}
                    {/*{rightStats.map((stat, index) => (
                    <RightStat key={stat} style={{ top: `${100 + (index * 60)}px` }}>
                        {stat}
                    </RightStat>
                ))}*/}
                </div>

                <CollapsibleSection title="Accessories">
                    <div className={styles.accessoriesSection}>
                        <div className={styles.tooltipContainer}>
                            <div className={styles.accessoryBox}><span className={styles.itemTitle}>Armour:</span> Basic Shorts</div>
                            <span className={styles.tooltip}>Standard shorts provides minimal protection</span>
                        </div>
                        <div className={styles.tooltipContainer}>
                            <div className={styles.accessoryBox}><span className={styles.itemTitle}>Helmet:</span> Standard Cap</div>
                            <span className={styles.tooltip}>Standard cap provides no AC bonus</span>
                        </div>
                        <div className={styles.tooltipContainer}>
                            <div className={styles.accessoryBox}><span className={styles.itemTitle}>Gloves:</span> None</div>
                        </div>
                        <div className={styles.tooltipContainer}>
                            <div className={styles.accessoryBox}><span className={styles.itemTitle}>Boots:</span> Basic Boots</div>
                            <span className={styles.tooltip}>Standard boots provides no AC bonus</span>
                        </div>
                    </div>
                </CollapsibleSection>
                <CollapsibleSection title="Skills List">
                    <div className={styles.skillsSection}>
                        <SkillsList />
                    </div>
                </CollapsibleSection>

                <div className={styles.updated}>
                    Last updated: {formatTimestamp(gameState.updated)}
                </div>
            </div>
        </main>
    );
};

export default MushroomStats;
